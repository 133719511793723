import { Flex } from '@mantine/core';
import { NavLinkItem } from './SideNav';
import { MobilLink } from './MobileLink';

export const MobileLinksBar = ({ links }: { links: NavLinkItem[] }) => {
  return (
    <Flex
      pos="fixed"
      bottom={0}
      bg="background.5"
      w="100vw"
      style={{ zIndex: 9999 }}
    >
      {links.map((link) => (
        <MobilLink key={link.path} link={link} />
      ))}
    </Flex>
  );
};
