import { useNavigate, useRouteError, Link, Navigate } from 'react-router-dom';
import { BackgroundImage, Card, Flex, Text } from '@mantine/core';
import PageNotFoundBackground from './assets/images/404-background.svg';
import { dashboardRC, getDefaultProjectKey, encodeProjectKey } from 'helpers';
import { useGLGOParams } from 'hooks';
import { useUserProvider } from 'store';
import { useAuth } from 'react-oidc-context';

const { DEV } = import.meta.env;

export const ErrorView = () => {
  const navigate = useNavigate();
  let { projectKey } = useGLGOParams();
  const { userProjects } = useUserProvider();
  const { data, statusText } = useRouteError() as {
    data: string;
    statusText: string;
    status: number;
    internal: boolean;
  };
  const { isAuthenticated = false, isLoading } = useAuth();

  // Wait for auth to load before redirecting
  if (isLoading) return null;
  // If unauthenticated, redirect to root WITHOUT "remembering" the requested URL
  // The user will be redirected to the dashboard after logging in)
  if (!isAuthenticated) {
    return <Navigate to={dashboardRC.PAGE_ROOT} />;
  }

  if (!userProjects.find((p) => p.project_key === projectKey)) {
    projectKey = getDefaultProjectKey(userProjects);
  }

  const redirectRoute = projectKey
    ? `/${encodeProjectKey(projectKey)}/${dashboardRC.PAGE_DASHBOARD}`
    : dashboardRC.PAGE_CREATE_PROJECT;

  const redirectLinkText = projectKey
    ? 'Go to Dashboard'
    : 'Go to Create Project';

  return (
    <BackgroundImage
      w="100%"
      h="100vh"
      px="1rem"
      style={{
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
      }}
      src={PageNotFoundBackground}
      data-testid="page-not-found"
    >
      <Flex w="100%" h="100%" align="center" justify="center">
        <Card
          w="100%"
          maw="48rem"
          py="3.5rem"
          style={{ borderRadius: '1.25rem' }}
        >
          <Flex
            direction="column"
            rowGap="2rem"
            align="center"
            justify="center"
          >
            <Text fz="2rem" fw={700}>
              Page not found
            </Text>
            <Text fz="1rem" ta="center">
              The page you were looking for does not exist. Sorry!
            </Text>
            {DEV && (
              <Text fz="1rem" ta="center">
                {data || statusText}
              </Text>
            )}

            <Text
              td="underline"
              fz="1rem"
              fw={700}
              c="text.8"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => navigate(-1)}
            >
              Go Back
            </Text>
            <Link to={redirectRoute}>
              <Text
                td="underline"
                fz="1rem"
                fw={700}
                c="text.8"
                style={{
                  cursor: 'pointer',
                }}
              >
                {redirectLinkText}
              </Text>
            </Link>
          </Flex>
        </Card>
      </Flex>
    </BackgroundImage>
  );
};
