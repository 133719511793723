import React, { useState } from 'react';
import {
  Box,
  Card,
  Container,
  Flex,
  Grid,
  ScrollArea,
  Text,
} from '@mantine/core';
import { MdCloudDownload } from 'react-icons/md';
import { BillingLoaderData, usePayInvoiceStore } from 'store';
import moment from 'moment/moment';
import { BasicHorizontalDivider, NoDataFoundPlaceHolder } from '../../Generic';
import { useMediaQuery } from '@mantine/hooks';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { useNavigation, useRouteLoaderData } from 'react-router-dom';
import classes from './BillingHistory.module.css';

const InvoiceDownloadIcon = ({
  invoiceFileId = '',
}: {
  invoiceFileId: string;
}) => {
  const { downloadInvoice } = usePayInvoiceStore();
  const { project } = useRouteLoaderData('billing') as BillingLoaderData;
  const project_key = project?.project_key || '';

  const hasGeneratedInvoiceFile = invoiceFileId.length > 0;

  return hasGeneratedInvoiceFile ? (
    <Flex
      w="100%"
      justify="center"
      style={{ cursor: 'pointer' }}
      onClick={() => downloadInvoice(invoiceFileId, project_key)}
    >
      <MdCloudDownload fontSize={22} />
    </Flex>
  ) : (
    <Flex w="100%" justify="center">
      Pending...
    </Flex>
  );
};

const BillingHistory = () => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const { billingHistory } = useRouteLoaderData('billing') as BillingLoaderData;
  const { state } = useNavigation();
  const loading = state !== 'idle';

  const [isMobileTableExpanded, setIsMobileTableExpanded] = useState(false);

  const MobileBillingHistorySection = () => (
    <Flex direction="column" pos="relative" w="100%">
      <Box bg="background2.5" w="100%" h={3} />
      <ScrollArea w="100%" h={670} type="always">
        <Box miw={isMobileTableExpanded ? 900 : 'none'}>
          <Grid
            display="flex"
            align="center"
            pos="sticky"
            top={0}
            m={0}
            py={5}
            px={10}
            bg="background2.5"
          >
            <Grid.Col span={5}>
              <Text fw={700} fz="1rem">
                DATE ISSUED
              </Text>
            </Grid.Col>
            <Grid.Col span={5}>
              <Text fw={700} fz="1rem">
                INVOICE ID
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex
                w="100%"
                justify="center"
                style={{ cursor: 'pointer' }}
                onClick={() => setIsMobileTableExpanded(true)}
              >
                <Text fw={700} fz="1rem">
                  ...
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>
          <Box pb={7}>
            {billingHistory.map(
              (
                { id: invoiceId = '', updatedTime = '', invoiceFile = {} },
                index
              ) => {
                const { id: invoiceFileId = '' } = invoiceFile || {};
                const dateIssued =
                  moment(updatedTime).format('MM/DD/YYYY') || '';
                const hiddenId =
                  invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
                const isLastIndex = index === billingHistory.length - 1;
                return (
                  <Box key={invoiceId} w={isMobileTableExpanded ? 900 : 'auto'}>
                    <Grid mx={10} my={10}>
                      <Grid.Col span={5}>
                        <Text fw={400} fz="1rem">
                          {dateIssued}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={5}>
                        <Text fw={400} fz="1rem">
                          #{hiddenId}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <InvoiceDownloadIcon invoiceFileId={invoiceFileId} />
                      </Grid.Col>
                    </Grid>
                    {!isLastIndex && (
                      <Container p={0} maw="100%" mx={20}>
                        <BasicHorizontalDivider mobileMargin="0 auto" />
                      </Container>
                    )}
                  </Box>
                );
              }
            )}
          </Box>
        </Box>
      </ScrollArea>
      <Box w="100%" h={3} />
    </Flex>
  );

  const BillingHistorySection = () => (
    <Flex direction="column" pos="relative" w="100%">
      <Box bg="background2.5" w="100%" h={3} />
      <ScrollArea pos="relative" w="100%" h={670} type="always">
        <Box miw={isMobileTableExpanded ? 900 : 'none'}>
          <Grid
            pos="sticky"
            top={0}
            display="flex"
            align="center"
            m={0}
            py={5}
            px={10}
            bg="background2.5"
            grow
            classNames={{
              root: classes.gridRoot,
              inner: classes.gridInner,
            }}
          >
            <Grid.Col span={2}>
              <Text fw={700} fz="1rem">
                DATE ISSUED
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Text fw={700} fz="1rem">
                SKU NAME
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Text fw={700} fz="1rem">
                INVOICE ID
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Text fw={700} fz="1rem">
                AMOUNT (USD)
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex justify="center" w="100%">
                <Text fw={700} fz="1rem">
                  INVOICE
                </Text>
              </Flex>
            </Grid.Col>
          </Grid>
          <Box px={10} pb={10}>
            {billingHistory.map(
              (
                {
                  id: invoiceId = '',
                  updatedTime = '',
                  capturedAmount = 0,
                  invoiceFiles = [],
                  item = {},
                },
                index
              ) => {
                const { id: invoiceFileId = '' } = invoiceFiles[0] || {};
                const { productDetails: { name: skuName = '' } = {} } =
                  item || {};
                const dateIssued =
                  moment(updatedTime).format('MM/DD/YYYY') || '';
                const hiddenId =
                  invoiceId.substring(0, 5) + 'XXXXX' || invoiceId || '';
                const amount = `$${capturedAmount.toFixed(2)}` || '';
                const isLastIndex = index === billingHistory.length - 1;
                return (
                  <Box key={invoiceId}>
                    <Grid
                      grow
                      classNames={{
                        root: classes.gridRoot,
                        inner: classes.gridInner,
                      }}
                      top={0}
                      display="flex"
                      m={0}
                      py={5}
                      px={10}
                    >
                      <Grid.Col span={2}>
                        <Text fw={400} fz="1rem">
                          {dateIssued}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text fw={400} fz="1rem">
                          {skuName}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text fw={400} fz="1rem">
                          #{hiddenId}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text fw={400} fz="1rem">
                          {amount}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <InvoiceDownloadIcon invoiceFileId={invoiceFileId} />
                      </Grid.Col>
                    </Grid>
                    {!isLastIndex && (
                      <Container p={0} maw="100%" mx={10}>
                        <BasicHorizontalDivider mobileMargin="0 auto" />
                      </Container>
                    )}
                  </Box>
                );
              }
            )}
          </Box>
        </Box>
      </ScrollArea>
      <Box w="100%" h={3} />
    </Flex>
  );

  return (
    <Container my={20} p={0} maw="100%">
      <Text fw={700} fz="1.5rem">
        Billing History
      </Text>
      <Card mt={20} p={loading ? 23 : 0} radius={0}>
        {loading ? (
          <LoadingSkeleton height={630} />
        ) : billingHistory.length === 0 ? (
          <NoDataFoundPlaceHolder height={676} />
        ) : (
          <>
            {isMobileSize && !isMobileTableExpanded && (
              <MobileBillingHistorySection />
            )}
            {(!isMobileSize || isMobileTableExpanded) && (
              <BillingHistorySection />
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

export default BillingHistory;
