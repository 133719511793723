import { Box } from '@mantine/core';
import { routes as accountRoutes, settings } from './routes';
import { RouterTabs } from 'ui';
import { useLoaderData } from 'react-router-dom';
import { AccountLoaderResponse } from 'store';

export const AccountPage = () => {
  const project_type = (useLoaderData() as AccountLoaderResponse)?.project_type;
  const isEnterprisePlan = project_type === 'GLGO-ENTERPRISE';

  let routes = [...accountRoutes];
  // if enterprise/custom sku, hide billing tab
  if (isEnterprisePlan) {
    routes = routes.filter((r) => r.id !== 'billing');
  }

  return (
    <Box pt={60}>
      <RouterTabs tabs={routes} settings={settings} />
    </Box>
  );
};
