import 'mantine-datatable/styles.css';
import { useNavigation } from 'react-router-dom';
import { Card, Flex, Text, Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DataTable } from 'mantine-datatable';
import { isInContextWindow } from 'helpers';
import { CustomTooltip } from '../../../Generic';
import { TiArrowUnsorted } from 'react-icons/ti';
import { InfoIcon } from '../../../../../StaticIcons';
import { NoTxRuleForm } from './NoTxRuleForm';
import { NoTranslateXRule } from './NoTxEditor';
import { DeleteNoTxRule } from './DeleteNoTxRule';
import { previewNoTxRule } from './contextFunctions';
import classes from './NoTxRulesTable.module.css';
import { useNoTxColors } from './useNoTxColors';

export type DataTableSortStatus = {
  columnAccessor: string;
  direction: 'asc' | 'desc';
};

type Props = {
  rules: NoTranslateXRule[];
  yyTarget: TranslationKey | undefined;
  selectRule?: (id: number) => void;
  sortStatus: DataTableSortStatus;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;
};

const isIncontext = isInContextWindow();

export const NoTxRulesTable = ({
  sortStatus,
  setSortStatus,
  rules,
  selectRule,
  yyTarget,
}: Props) => {
  const { state = '' } = useNavigation();
  const colors = useNoTxColors().table;
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const heightOffset = isMobileSize && !isIncontext ? 380 : 300;

  function handleRowClick(rule: NoTranslateXRule) {
    previewNoTxRule(rule.selector);
    selectRule && selectRule(rule.id);
  }

  return (
    <Card p={0}>
      <DataTable
        highlightOnHover={true}
        classNames={classes}
        columns={[
          {
            accessor: 'order',
            title: '',
            sortable: true,
            width: 40,
            textAlign: 'center',
          },
          {
            accessor: 'selector.X',
            title: (
              <Flex align="center" tt="uppercase">
                <Text>SELECTOR</Text>
                <CustomTooltip tooltipLabel="This supports any CSS selector.">
                  <Flex align={'center'}>
                    <InfoIcon fill="#2C49AC" size={15} />
                  </Flex>
                </CustomTooltip>
              </Flex>
            ),
            ellipsis: true,
            sortable: true,
            render: (row) => (
              <CustomTooltip tooltipLabel={row.selector.X}>
                <Box>{row.selector.X}</Box>
              </CustomTooltip>
            ),
          },
          {
            accessor: 'selector.comment',
            title: 'DESCRIPTION',
            ellipsis: true,
            sortable: true,
          },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'center',
            width: 60,
            render: (rule) => (
              <Flex justify="center" wrap="nowrap" gap={10} pr={20}>
                <NoTxRuleForm id={rule.id} rules={rules} yyTarget={yyTarget} />
                <DeleteNoTxRule id={rule.id} yyTarget={yyTarget} />
              </Flex>
            ),
          },
        ]}
        height={`calc(100vh - ${heightOffset}px)`}
        maxHeight={`${rules.length * 56 + 60}px`}
        minHeight={300}
        withTableBorder={false}
        verticalSpacing="1rem"
        sortIcons={{
          sorted: <TiArrowUnsorted color="#0071DB" size={14} />,
          unsorted: <TiArrowUnsorted color="#757575" size={14} />,
        }}
        sortStatus={{
          direction: sortStatus.direction,
          columnAccessor: sortStatus.columnAccessor,
        }}
        onSortStatusChange={setSortStatus}
        rowBorderColor="#CBD1F9"
        loaderColor="#0071DB"
        loaderSize={50}
        shadow="none"
        records={rules}
        fetching={state === 'loading'}
        onRowClick={
          isIncontext
            ? ({ record }) => handleRowClick(record as NoTranslateXRule)
            : undefined
        }
        rowStyle={({ isOnPage, isSelected }) => ({
          fontWeight: isOnPage ? 400 : 100,
          fontStyle: isOnPage ? 'normal' : 'italic',
          backgroundColor: isSelected
            ? colors.background.selected
            : colors.background.normal,
          color: isSelected ? colors.font.selected : colors.font.normal,
        })}
      />
    </Card>
  );
};
