import { Params } from 'react-router-dom';
import { getQuickQuote } from '../../quickQuote';
import { fetchSkusPromise } from '../../PaySkuStore';
import { xapisEnv } from '../../service-wrappers/xapisService';
import {
  calculateMonthlyPageViews,
  decodeProjectId,
  getRecommendedPlan,
  isSuccessStatus,
} from 'helpers';
import { ProjectResponse, Xapis } from '../../xapis-wrappers/xapis';
import { SkuWCreatedAndLastMod } from '../../types';

export async function selectPlanLoader({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  const data = {
    skus: [] as SkuWCreatedAndLastMod[],
    recommendedPlan: {} as Partial<SkuWCreatedAndLastMod>,
  };

  const { payKey } = xapisEnv.getHost;

  let skuItems: SkuWCreatedAndLastMod[] = [];
  try {
    const res = await fetchSkusPromise(payKey);
    if (isSuccessStatus(res.status)) {
      skuItems = res.data.skus.filter(
        (s: SkuWCreatedAndLastMod) => s.price !== 0
      );
      data.skus = res.data.skus.filter(
        (s: SkuWCreatedAndLastMod) => s.price !== 0
      );
    }
  } catch (error) {
    console.error('error getting skus:', error);
  }

  const url = new URL(request.url);
  const threshold = url.searchParams.get('threshold');

  if (threshold && Number(threshold)) {
    const idealPlan = skuItems.find(
      (s) => s.threshold_value === Number(threshold)
    );
    data.recommendedPlan = idealPlan
      ? {
          name: idealPlan.name,
          threshold_value: Number(threshold),
        }
      : getRecommendedPlan(Number(threshold), skuItems);

    return data;
  }

  const projectKey = decodeProjectId(projectId);
  const response: ProjectResponse = await Xapis.Project.get(projectKey);
  const domain = response.project?.origin_name || '';
  const estimatedWordsServed = (await getEstimatedWordsServed(domain)) || 0;

  const { name = '', threshold_value = 0 } = getRecommendedPlan(
    estimatedWordsServed,
    skuItems
  );

  data.recommendedPlan = { name, threshold_value };

  return data;
}

export type SelectPlanLoaderResponse = Awaited<
  ReturnType<typeof selectPlanLoader>
>;

type QuickQuoteData = {
  spider_job: { average_words_per_page: number };
  traffic: { results: { visits: string; pages_per_visit: string } };
};

const getEstimatedWordsServed = async (url: string) => {
  try {
    const res = await getQuickQuote(url);

    if (!res.data || res.status !== 200) return 0;

    const { spider_job, traffic } = res.data as QuickQuoteData;
    const average_words_per_page = spider_job?.average_words_per_page || 0;
    const results = traffic?.results || { visits: '0', pages_per_visit: '0' };

    const monthlyViews = calculateMonthlyPageViews(
      results.visits,
      results.pages_per_visit
    );

    return Math.round(average_words_per_page * monthlyViews * 0.1);
  } catch (error) {
    console.error(error);
  }
};
