import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Select,
  Text,
  TextInput,
  Image,
  Card,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { FaChevronDown } from 'react-icons/fa6';
import { useMediaQuery } from '@mantine/hooks';
import WelcomePage from './images/welcome-page.svg';
import BrowserWindow from './BrowserWindow';
import { handleQuickQuote, useUserProvider } from 'store';
import classes from './CreateProjectStep.module.css';
import { isValidDomain } from 'helpers';
import fathomEventTracker from '../../fathomAnalytics';

const trimUrl = (userInput: string = '') => {
  return userInput
    .replace(/^http[s]?:\/\//, '')
    .split('/')[0]
    .trim();
};

const onDomainChange = (
  event: React.FormEvent<HTMLInputElement>,
  setProjectDomain: React.Dispatch<React.SetStateAction<string>>,
  setIsNextButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const domain = trimUrl(event.currentTarget.value);
  const isValid = isValidDomain(domain);
  setProjectDomain(domain);
  setIsNextButtonDisabled(!isValid);
};

export const CreateProjectSideMenuContent = () => {
  const { email = '' } = useUserProvider().xapisUser;
  const {
    setActiveStep,
    isNextButtonDisabled,
    setIsNextButtonDisabled,
    projectDomain,
  } = useSetupStepsContext();

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Text fz="2rem" fw={700}>
        Create Project
      </Text>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="1.15rem" fw={500} ta="center">
        You are about to make your website multilingual!
      </Text>
      <Text fz="1.5rem" fw={700} ta="center">
        What is your project's website domain?
      </Text>
      <Button
        w={{ base: '100%', xs: '7rem' }}
        radius={30}
        className={classes.nextButton}
        disabled={isNextButtonDisabled}
        onClick={() => {
          handleQuickQuote(projectDomain, email);
          fathomEventTracker('Domain Entry - 2');
          setIsNextButtonDisabled(true);
          setActiveStep((prevStep) => prevStep + 0.5);
        }}
        fz="1rem"
      >
        Next
      </Button>
    </Flex>
  );
};

export const CreateProjectBodyContent = () => {
  const { projectDomain, setProjectDomain, setIsNextButtonDisabled } =
    useSetupStepsContext();

  const isDesktopSize = useMediaQuery('(min-width: 1200px)');
  const [value, setValue] = useState('https');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Box px="2rem">
      <BrowserWindow>
        <Flex align="center">
          <Select
            value={value}
            placeholder="https"
            data={['https', 'http']}
            size={isDesktopSize ? 'xl' : 'md'}
            rightSection={<FaChevronDown size="1rem" />}
            rightSectionWidth={40}
            withCheckIcon={false}
            onChange={(v) => v && setValue(v)}
            classNames={{
              root: classes.protocolSelectRoot,
              wrapper: classes.protocolSelectWrapper,
              input: classes.protocolSelectInput,
              option: classes.protocolSelectItem,
              dropdown: classes.protocolSelectDropdown,
            }}
            scrollAreaProps={{
              styles: {
                viewport: {
                  padding: 0,
                },
              },
            }}
          />
          <TextInput
            w="100%"
            ref={inputRef}
            size={isDesktopSize ? 'xl' : 'md'}
            required
            withAsterisk
            aria-label="domain"
            value={projectDomain}
            onInput={(event) =>
              onDomainChange(event, setProjectDomain, setIsNextButtonDisabled)
            }
            styles={{
              input: {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                boxShadow: '0px 5px 5px rgba(5, 53, 140, 0.10)',
              },
            }}
          />
        </Flex>
        <Image src={WelcomePage} alt="Welcome page text and design" />
      </BrowserWindow>
    </Box>
  );
};

export const MobileCreateProjectStep = () => {
  const { email = '' } = useUserProvider().xapisUser;
  const {
    setActiveStep,
    isNextButtonDisabled,
    setIsNextButtonDisabled,
    projectDomain,
    setProjectDomain,
  } = useSetupStepsContext();

  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex
        w="100%"
        h="100%"
        rowGap="1.5rem"
        direction="column"
        justify="center"
        align="center"
      >
        <Text fz={{ base: '1.5rem', sm: '2rem' }} fw={700}>
          Create Project
        </Text>
        <Divider w="100%" color="divider.0" size={2} />
        <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={500} ta="center">
          You are about to make your website multilingual!
        </Text>
        <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={700} ta="center">
          What is your project's website domain?
        </Text>
        <TextInput
          w="100%"
          size={'md'}
          required
          withAsterisk
          aria-label="domain"
          value={projectDomain}
          onInput={(event) =>
            onDomainChange(event, setProjectDomain, setIsNextButtonDisabled)
          }
          styles={{
            input: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              boxShadow: '0px 5px 5px rgba(5, 53, 140, 0.10)',
            },
          }}
        />
        <Button
          w={{ base: '100%', xs: '7rem' }}
          radius={30}
          className={classes.nextButton}
          disabled={isNextButtonDisabled}
          onClick={() => {
            handleQuickQuote(projectDomain, email);
            fathomEventTracker('Domain Entry - 2');
            setIsNextButtonDisabled(true);
            setActiveStep((prevStep) => prevStep + 0.5);
          }}
          fz="1rem"
        >
          Next
        </Button>
      </Flex>
    </Card>
  );
};
