import React from 'react';
import { Button, Divider, Flex, Modal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useProjectsStore, ProjectStore } from 'store';
import { useRevalidator } from 'react-router-dom';
type Props = {
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
  activeProjectKey: string;
};

const GoLiveModal = ({ opened, setOpened, activeProjectKey }: Props) => {
  const { updateProject } = useProjectsStore((state: ProjectStore) => state);
  const revalidator = useRevalidator();

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => setOpened(false)}
      title="You're ready to GO global!"
      size="lg"
      padding="3rem"
    >
      <Divider color="divider.1" m="0.5rem 0 1.5rem 0" size="xs" w="100%" />
      <Text ta="center">
        When you set your project to Live the selector will become publicly
        visible and visitors to your website will be able to see your translated
        website in the languages you've chosen.
      </Text>
      <Flex justify="center" pt="2rem" pb="1rem" w="100%">
        <Button
          h={40}
          w={160}
          tt="uppercase"
          onClick={() => {
            updateProject(activeProjectKey, { project_status: 'LIVE' });
            setOpened(false);
            notifications.show({ message: 'Your project is now live!' });
            revalidator.revalidate();
          }}
        >
          Go
        </Button>
      </Flex>
    </Modal>
  );
};

export default GoLiveModal;
