import { Flex, Loader } from '@mantine/core';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import {
  getDefaultProjectKey,
  encodeProjectKey,
  dashboardRC,
  saveSelectedProjectKey,
} from 'helpers';
import { useUserProvider } from 'store';
import { LoginOrSignUp } from 'pages';
import { useGLGOParams } from 'hooks';
import { useEffect } from 'react';
import { useGLGOAuth } from './useGLGOAuth';

export const Root = () => {
  const { pathname, search } = useLocation();
  const { projectId, projectKey } = useGLGOParams();
  const redirectUrl = `${pathname}${search}`;
  const auth = useGLGOAuth();
  const { isAuthenticated, isLoading: isAuthLoading } = auth;
  const { userProjects } = useUserProvider();
  const navigate = useNavigate();
  const { state } = useNavigation();
  const isRouteLoading = state !== 'idle';
  const isSwitchingProjects =
    isRouteLoading && pathname === dashboardRC.PAGE_ROOT;

  useEffect(() => {
    window.GlGoAPI &&
      window.GlGoAPI.onNavigate((path: string) => {
        navigate(path);
      });

    window.UserManager &&
      window.UserManager.onSSORedirect(() => {
        navigate(dashboardRC.PAGE_LOGIN_REDIRECT);
      });
  }, [navigate]);

  useEffect(() => {
    if (window?.fathom) {
      window?.fathom.trackPageview();
    }
  }, [pathname, search]);

  const isValidProject = userProjects.some((p) => p.project_key === projectKey);

  useEffect(() => {
    // Save the project key if user came here through URL
    if (isValidProject && projectKey) saveSelectedProjectKey(projectKey);
  }, [isValidProject, projectKey]);

  if (!isAuthenticated && !isAuthLoading) {
    return <LoginOrSignUp redirectUrl={redirectUrl} />;
  }

  if (isAuthLoading || isSwitchingProjects) {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ height: '100vh', width: '100vw' }}
      >
        <Loader color="background2.2" size="xl" />
      </Flex>
    );
  }

  const shouldNavigate =
    (isAuthenticated && !isAuthLoading && pathname === '/') ||
    (projectId && !isValidProject);

  const defaultProjectId = encodeProjectKey(getDefaultProjectKey(userProjects));
  const navigateTo = defaultProjectId
    ? `/${defaultProjectId}/${dashboardRC.PAGE_DASHBOARD}`
    : `/${dashboardRC.PAGE_CREATE_PROJECT}`;

  return shouldNavigate ? (
    <Navigate to={navigateTo} replace={true} />
  ) : (
    <Outlet />
  );
};
