import React from 'react';
import {
  Anchor,
  Box,
  Button,
  Flex,
  ScrollArea,
  Text,
  Image,
  useMantineTheme,
} from '@mantine/core';
import { xapisEnv } from 'store';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { dashboardRC } from 'helpers';
import GLGOLogoColor from 'images/logos/glgo-logo-color.svg';
import AccoladesWrapper from './AccoladesWrapper';

export const LoginOrSignUp = ({ redirectUrl }: { redirectUrl?: string }) => {
  const colors = useMantineTheme().colors;
  const auth = useAuth();

  const { glgoMarketingUrl } = xapisEnv.getHost;
  const termsOfServiceUrl = `${glgoMarketingUrl}/terms`;
  const privacyPolicyUrl = `${glgoMarketingUrl}/privacy`;

  return (
    <>
      <AccoladesWrapper>
        <Flex h="100vh" direction="column" justify="center">
          <ScrollArea style={{ overflowY: 'auto' }}>
            <Flex
              w="100%"
              mih={550}
              direction="column"
              justify="center"
              align="center"
              rowGap="3rem"
              px="1rem"
            >
              <Flex direction="column" align="center" rowGap="2rem">
                <Image
                  src={GLGOLogoColor}
                  width={280}
                  alt="GlobalLinkGO logo"
                  style={{
                    objectFit: 'cover',
                    width: '17.5rem',
                    height: ' auto',
                  }}
                />
                <Text ta="center" fw={400} fz="1rem" maw="25rem">
                  <Text fw={700} span>
                    Go multilingual
                  </Text>
                  &nbsp;in minutes with the&nbsp;
                  <Text fw={700} c="text.8" span>
                    global leader
                  </Text>
                  &nbsp; in localization services.
                </Text>
              </Flex>
              <Flex
                w="100%"
                maw="24rem"
                align="center"
                direction="column"
                rowGap="1.5rem"
              >
                <Button
                  h={55}
                  w="100%"
                  bg="transparent"
                  style={{
                    border: `2px solid ${colors.border2[8]}`,
                    borderRadius: 30,
                  }}
                  onClick={() =>
                    auth.signinRedirect({
                      state: {
                        path: redirectUrl || dashboardRC.PAGE_DASHBOARD,
                      },
                    })
                  }
                >
                  <Text fz="1rem" fw={600}>
                    Log In
                  </Text>
                </Button>
                <Flex w="100%" align="center">
                  <Box w="37.5%" h={3} bg="background2.7" />
                  <Box w="25%">
                    <Text fz="1.15rem" fw={600} ta="center">
                      or
                    </Text>
                  </Box>
                  <Box w="37.5%" h={3} bg="background2.7" />
                </Flex>
                <Flex
                  w="100%"
                  direction="column"
                  align="center"
                  rowGap="0.5rem"
                >
                  <Link
                    style={{ width: '100%' }}
                    to={dashboardRC.PAGE_CREATE_ACCOUNT}
                  >
                    <Button
                      h={55}
                      bg="background2.2"
                      w="100%"
                      style={{ borderRadius: 30 }}
                    >
                      <Text c="text.0" fz="1rem" fw={600}>
                        Sign Up
                      </Text>
                    </Button>
                  </Link>
                </Flex>
                <Text ta="center" fz=".75rem">
                  By signing up, you agree to the&nbsp;
                  <Anchor
                    fz=".75rem"
                    c="text.8"
                    href={termsOfServiceUrl}
                    target="_blank"
                  >
                    Terms of Service
                  </Anchor>
                  &nbsp;and&nbsp;
                  <Anchor
                    fz=".75rem"
                    c="text.8"
                    href={privacyPolicyUrl}
                    target="_blank"
                  >
                    Privacy Policy
                  </Anchor>
                  .
                </Text>
              </Flex>
            </Flex>
          </ScrollArea>
        </Flex>
      </AccoladesWrapper>
    </>
  );
};

export default LoginOrSignUp;
