import { Link } from 'react-router-dom';
import { Flex, Text, ThemeIcon } from '@mantine/core';
import { NavLinkItem } from './SideNav';
import { useDynamicLink } from './useDynamicLink';
import classes from './DesktopLink.module.css';

export const DesktopLink = ({
  link,
  isExpanded = false,
}: {
  link: NavLinkItem;
  isExpanded: boolean;
}) => {
  const { icon, label } = link;

  const { isActive, tab } = useDynamicLink({ link });

  return (
    <Link
      className={`${classes.linkAnchor} ${isActive ? classes.activeLink : ''}`}
      to={tab}
      style={{
        padding: isExpanded ? '1rem 1rem 1rem 1.25rem' : '1rem',
      }}
    >
      <Flex justify={isExpanded ? 'start' : 'center'} w="100%">
        <ThemeIcon
          className={classes.linkIcon}
          mr={isExpanded ? 20 : 0}
          aria-label={label}
        >
          {icon}
        </ThemeIcon>
        <Text
          fz="1em"
          style={{ display: isExpanded ? 'block' : 'none' }}
          truncate
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
};
