import { useState } from 'react';
import sortBy from 'lodash.sortby';

import { Flex } from '@mantine/core';
import { NoTxDashboard } from './NoTxDashboard';
import { NoTxRulesTable } from './NoTxRulesTable';

const sortNoTxRules = (
  rules: NoTranslateXRule[],
  sortStatus: {
    columnAccessor: string;
    direction: 'asc' | 'desc';
  }
) => {
  const sorted = sortBy(rules, sortStatus.columnAccessor);
  return sortStatus.direction === 'asc' ? sorted : sorted.reverse();
};

const filterRules = (rules: NoTranslateXRule[], filterBy: RulesFilter) => {
  const { text, showActive } = filterBy;
  if (!text && !showActive) return rules;
  const textFilter = text.toLowerCase();
  return rules.filter((rule) => {
    if (textFilter) {
      const ruleTexts = Object.values(rule.selector);
      if (!ruleTexts.some((t) => t.toLowerCase().includes(textFilter))) {
        return false;
      }
    }
    if (showActive && !rule.isOnPage) return false;
    return true;
  });
};

export type NoTranslateXRule = {
  selector: UX;
  order: number;
  id: number;
  isOnPage?: boolean;
  isSelected?: boolean;
};
export type RulesFilter = {
  text: string;
  showActive: boolean;
};

type Props = {
  rules: NoTranslateXRule[];
  target: TranslationKey | undefined;
  selectRule?: (id: number) => void;
};

export const NoTxEditor = ({ rules, target, selectRule }: Props) => {
  const [sortStatus, setSortStatus] = useState<{
    columnAccessor: string;
    direction: 'asc' | 'desc';
  }>({
    columnAccessor: 'order',
    direction: 'asc',
  });
  const [filterBy, setFilterBy] = useState<RulesFilter>({
    text: '',
    showActive: false,
  });

  const filteredRules = filterRules(rules, filterBy);
  const sortedRules = sortNoTxRules(filteredRules, sortStatus);

  return (
    <Flex direction="column" gap={20} mt="1.75rem">
      <NoTxDashboard
        target={target}
        rules={rules}
        setFilterBy={setFilterBy}
        selectRule={selectRule}
        filterBy={filterBy}
      />
      <NoTxRulesTable
        rules={sortedRules}
        yyTarget={target}
        sortStatus={sortStatus}
        setSortStatus={setSortStatus}
        selectRule={selectRule}
      />
    </Flex>
  );
};
