import ReactDOM from 'react-dom/client';
import './global.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
// import TestingApp from './TestingApp';
import AuthWrapper from './AuthWrapper';
import { queryClient, UserProvider, RecommendedThresholdProvider } from 'store';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { dashboardTheme } from 'helpers';
import C from './constants';
import TagManager from 'react-gtm-module';

import { routes } from './GLGOroutes';
import { initUser } from 'auth';
import { Notifications } from '@mantine/notifications';

const isGLGODesktop = import.meta.env.VITE_IS_GLGO_DESKTOP === true;
const { GTM_TAG_ID } = C;
const tagManagerParams = { gtmId: GTM_TAG_ID };

TagManager.initialize(tagManagerParams);

const createRouter = isGLGODesktop ? createHashRouter : createBrowserRouter;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Change this to true to show the React Query DevTools
const showRQDevTools = false && import.meta.env.DEV;

initUser().then((user) => {
  const router = createRouter(routes);
  root.render(
    <QueryClientProvider client={queryClient}>
      <AuthWrapper>
        <UserProvider user={user}>
          <MantineProvider theme={dashboardTheme || {}}>
            <RecommendedThresholdProvider>
              <Notifications />
              <RouterProvider router={router} />
            </RecommendedThresholdProvider>
          </MantineProvider>
        </UserProvider>
      </AuthWrapper>
      <ReactQueryDevtools initialIsOpen={showRQDevTools} />
    </QueryClientProvider>
  );
});
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
