import React, { useState } from 'react';
import {
  ActionIcon,
  Flex,
  Grid,
  Popover,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { defaultSubscription, Subscription } from 'store';
import { IoMdNotifications } from 'react-icons/io';
import classes from './MainHeaderNotificationsPopover.module.css';
import {
  getRemainingSubscriptionDays,
  getNotificationTimeslice,
} from 'helpers';
import { useAuth } from 'react-oidc-context';

export const MainHeaderNotificationsPopover = ({
  subscriptions,
}: {
  subscriptions: Subscription[];
}) => {
  const auth = useAuth();
  const { colors } = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [firstSubscription = defaultSubscription] = subscriptions;
  const { next_renewal_date: nextRenewalDate, price } = firstSubscription;

  const remainingDaysInSubscription =
    getRemainingSubscriptionDays(nextRenewalDate);

  const PopoverTemplate = (isNew: boolean, text: string, time: string) => {
    return (
      <React.Fragment key={text}>
        <Grid.Col span={3}>
          {isNew && (
            <Text fw={700} fz="1rem">
              NEW
            </Text>
          )}
        </Grid.Col>
        <Grid.Col span={6}>
          <Text fw={600} fz=".75rem">
            {text}
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex w="100%" direction="row" justify="flex-end">
            <Text fz=".625rem">{time}</Text>
          </Flex>
        </Grid.Col>
      </React.Fragment>
    );
  };

  const PopupNotifications = () => {
    const notifications = [];
    const encroachingDays = 7;

    if (price === 0 && remainingDaysInSubscription <= encroachingDays) {
      notifications.push({
        isNew: true,
        text: `Your Free Trial will run out in ${remainingDaysInSubscription} days.`,
        time: getNotificationTimeslice(auth),
      });
    }
    if (notifications.length === 0) {
      notifications.push({
        isNew: false,
        text: 'You have no new notifications. Check back soon!',
        time: 'now',
      });
    }

    return (
      <>
        {notifications.map(({ isNew, text, time }) =>
          PopoverTemplate(isNew, text, time)
        )}
      </>
    );
  };

  return (
    <Popover width="30rem" position="bottom-end" shadow="sm" opened={opened}>
      <Popover.Target>
        <ActionIcon bg="transparent" mx={12} onClick={() => setOpened(!opened)}>
          <IoMdNotifications className={classes.icon} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown
        bg="background2.5"
        style={{
          border: `1px solid ${colors.border2[7]}`,
        }}
      >
        <Grid align="center" justify="space-between">
          <PopupNotifications />
        </Grid>
      </Popover.Dropdown>
    </Popover>
  );
};

export default MainHeaderNotificationsPopover;
