import * as React from 'react';
import { useEffect, useState } from 'react';
import { Divider, Flex, UnstyledButton, AppShell, Image } from '@mantine/core';
import { AuthContextProps } from 'react-oidc-context';
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from 'react-icons/bs';
import { SideNavStore, useSideNavStore } from 'store';
import { DesktopLink } from './DesktopLink';
import { Link, useParams } from 'react-router-dom';
import { dashboardRC } from 'helpers';
import classes from './SideNav.module.css';
import GLGOLogoWhite from 'images/logos/glgo-logo-white.svg';
import GLGOIconWhite from 'images/logos/glgo-icon-white.svg';

export type NavLinkItem = {
  icon: JSX.Element;
  label: string;
  path: string;
  defaultTab?: string;
};

type Props = {
  auth?: AuthContextProps;
  navLinks: NavLinkItem[];
  quoteNavLink?: NavLinkItem;
  children?: React.ReactNode;
  isDesktopSize: boolean;
};

const isGLGODesktop = import.meta.env.VITE_IS_GLGO_DESKTOP === true;
const isDev = import.meta.env.DEV;

export const SideNav = ({
  navLinks,
  children,
  isDesktopSize,
  quoteNavLink,
}: Props) => {
  const { isNavExpanded, setIsNavExpanded: setIsExpanded } = useSideNavStore(
    (state: SideNavStore) => state
  );

  const isExpanded = isNavExpanded && isDesktopSize;
  const { projectId } = useParams();
  const [showQuoteLink, setShowQuoteLink] = useState(false);

  const links = navLinks.map((link) => (
    <DesktopLink link={link} isExpanded={isExpanded} key={link.path} />
  ));

  const getLogo = () => (
    <Link
      to={`${projectId}/${dashboardRC.PAGE_DASHBOARD}`}
      aria-label="side navigation logo"
      className={classes.logo}
      style={{ width: isExpanded ? 200 : 45 }}
    >
      {isExpanded ? (
        <Flex h={40}>
          <Image
            src={GLGOLogoWhite}
            width={200}
            alt="GlobalLinkGO logo"
            styles={{ root: { flex: 'auto' } }}
          />
        </Flex>
      ) : (
        <Flex h={40}>
          <Image
            src={GLGOIconWhite}
            width={45}
            height="auto"
            alt="GlobalLinkGO logo"
            styles={{ root: { flex: 'auto' } }}
          />
        </Flex>
      )}
    </Link>
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'q') {
        setShowQuoteLink(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <AppShell.Navbar
      className={classes.navbar}
      style={{ width: isExpanded ? 232 : 90 }}
      data-testid={isExpanded ? 'pw-expand-side-nav' : 'pw-collapse-side-nav'}
    >
      <Flex align="center" direction="column" mt={isGLGODesktop ? 50 : 0}>
        {getLogo()}
        <Divider color="white" w={isExpanded ? '85%' : '60%'} my={20} />
        {links}
        <Divider color="white" w={isExpanded ? '85%' : '60%'} my={20} />
        {isGLGODesktop && quoteNavLink && (showQuoteLink || isDev) && (
          <DesktopLink
            link={quoteNavLink}
            isExpanded={isExpanded}
            key={quoteNavLink.path}
          />
        )}
        {children || <></>}
      </Flex>
      {isDesktopSize && (
        <Flex
          justify={isExpanded ? 'flex-end' : 'center'}
          style={{ WebkitAppRegion: 'no-drag' }}
        >
          <UnstyledButton
            mx={isExpanded ? '2.5rem' : 'auto'}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-label="Expand navigation"
            style={{ WebkitAppRegion: 'no-drag' }}
          >
            {isExpanded ? (
              <BsArrowLeftSquareFill
                transform="scale(1.5)"
                data-testid="pw-expand-side-nav-button"
              />
            ) : (
              <BsArrowRightSquareFill
                transform="scale(1.5)"
                data-testid="pw-collapse-side-nav-button"
              />
            )}
          </UnstyledButton>
        </Flex>
      )}
    </AppShell.Navbar>
  );
};

export default SideNav;
