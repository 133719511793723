import React, { useEffect, useMemo } from 'react';
import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useMantineTheme,
} from '@mantine/core';
import {
  positionOptions,
  BOTTOM_LEFT_POSITION,
  DARK_THEME,
  LIGHT_THEME,
  getPresetCssPreferences,
} from './LanguageSelectorAppearances';
import LightLanguageSelector from './images/light-language-selector.svg';
import DarkLanguageSelector from './images/dark-language-selector.svg';
import { ProjectTranslationStore, useProjectTranslationStore } from 'store';
import { useSetupStepsContext } from './ProjectSetupProvider';
import { getLanguageSelectorImage } from './utils';
import { useMediaQuery } from '@mantine/hooks';
import classes from './LanguageSelectorStyle.module.css';

export const LanguageSelectorStyle = () => {
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');
  const {
    selectedTheme,
    setSelectedTheme,
    selectedPosition,
    setSelectedPosition,
    isCreatingProject,
  } = useSetupStepsContext();
  const { colors } = useMantineTheme();

  const isDarkTheme = selectedTheme === DARK_THEME;

  const yyTarget = useProjectTranslationStore(
    (state: ProjectTranslationStore) => state.yyTarget
  );

  const {
    theme: currentTheme = DARK_THEME,
    position: currentPosition = BOTTOM_LEFT_POSITION,
  } = useMemo(() => {
    const { customCSS: targetCustomCSS = '' } = yyTarget;
    return getPresetCssPreferences(targetCustomCSS);
  }, [yyTarget]);

  useEffect(() => {
    setSelectedTheme(currentTheme || DARK_THEME);
    setSelectedPosition(currentPosition || BOTTOM_LEFT_POSITION);
  }, [currentTheme, currentPosition, setSelectedTheme, setSelectedPosition]);

  if (isDesktopSize) {
    return (
      <Box w="100%">
        <Flex columnGap="1rem">
          <Box>
            <Text fw={500} fz="1rem">
              COLOR
            </Text>
            <Text fw={400} fz="0.9rem" c="text.9">
              Choose the color scheme of the language selector.
            </Text>
            <Flex direction="row" columnGap="1rem" mt="0.5rem">
              <Box miw={100} maw={120}>
                <Image
                  w="100%"
                  height="auto"
                  src={LightLanguageSelector}
                  className={classes.selectorOption}
                  style={{
                    border: !isDarkTheme
                      ? `2px solid ${colors.border[8]}`
                      : 'none',
                    boxShadow: !isDarkTheme
                      ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                      : 'none',
                  }}
                  onClick={() =>
                    !isCreatingProject && setSelectedTheme(LIGHT_THEME)
                  }
                  alt="Light theme language selector"
                />
              </Box>
              <Box miw={100} maw={120}>
                <Image
                  w="100%"
                  height="auto"
                  src={DarkLanguageSelector}
                  className={classes.selectorOption}
                  style={{
                    border: isDarkTheme
                      ? `2px solid ${colors.border[8]}`
                      : 'none',
                    boxShadow: isDarkTheme
                      ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                      : 'none',
                  }}
                  onClick={() =>
                    !isCreatingProject && setSelectedTheme(DARK_THEME)
                  }
                  alt="Dark theme language selector"
                />
              </Box>
            </Flex>
          </Box>
          <Image
            pt="2rem"
            width="100%"
            maw={300}
            height="auto"
            src={getLanguageSelectorImage(selectedTheme, selectedPosition)}
            alt="GlobalLinkGO Language Selector Appearance"
          />
        </Flex>
        <Text fw={500} fz="1rem">
          POSITION
        </Text>
        <Text fw={400} fz="0.95rem" c="text.9">
          Choose the location of the language selector.
        </Text>
        <Flex w="100%" mt="0.5rem" columnGap={20} rowGap={20}>
          {positionOptions.map(
            ({ position = BOTTOM_LEFT_POSITION, src, alt = '' }) => {
              const isPositionActive = position === selectedPosition;
              return (
                <Image
                  key={position}
                  src={src}
                  height="auto"
                  maw={100}
                  className={classes.selectorOption}
                  style={{
                    border: `2px solid ${colors.border[isPositionActive ? 8 : 7]}`,
                    boxShadow: isPositionActive
                      ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                      : 'none',
                  }}
                  onClick={() =>
                    !isCreatingProject && setSelectedPosition(position)
                  }
                  alt={alt}
                />
              );
            }
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <Flex w="100%" direction="column" rowGap="2rem">
      <Flex w="100%" direction="column" rowGap="1rem">
        <Box>
          <Text fw={500} fz="1rem">
            COLOR
          </Text>
          <Text fw={400} fz="0.9rem" c="text.9">
            Choose the color scheme of the language selector.
          </Text>
          <Flex direction="row" columnGap="1rem" mt="0.5rem">
            <Box miw={100} maw={120}>
              <Image
                w="100%"
                height="auto"
                src={LightLanguageSelector}
                className={classes.selectorOption}
                style={{
                  border: !isDarkTheme
                    ? `2px solid ${colors.border[8]}`
                    : 'none',
                  boxShadow: !isDarkTheme
                    ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                    : 'none',
                }}
                onClick={() =>
                  !isCreatingProject && setSelectedTheme(LIGHT_THEME)
                }
                alt="Light theme language selector"
              />
            </Box>
            <Box miw={100} maw={120}>
              <Image
                w="100%"
                height="auto"
                src={DarkLanguageSelector}
                className={classes.selectorOption}
                style={{
                  border: isDarkTheme
                    ? `2px solid ${colors.border[8]}`
                    : 'none',
                  boxShadow: isDarkTheme
                    ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                    : 'none',
                }}
                onClick={() =>
                  !isCreatingProject && setSelectedTheme(DARK_THEME)
                }
                alt="Dark theme language selector"
              />
            </Box>
          </Flex>
        </Box>
        <Box>
          <Text fw={500} fz="1rem">
            POSITION
          </Text>
          <Text fw={400} fz="0.95rem" c="text.9">
            Choose the location of the language selector.
          </Text>
          <Flex w="100%" mt="0.5rem" columnGap={20} rowGap={20} wrap="wrap">
            {positionOptions.map(
              ({ position = BOTTOM_LEFT_POSITION, src, alt = '' }) => {
                const isPositionActive = position === selectedPosition;
                return (
                  <Image
                    key={position}
                    src={src}
                    height="auto"
                    maw={100}
                    className={classes.selectorOption}
                    style={{
                      border: `2px solid ${colors.border[isPositionActive ? 8 : 7]}`,
                      boxShadow: isPositionActive
                        ? '0px 5px 5px rgba(5, 53, 140, 0.15)'
                        : 'none',
                    }}
                    onClick={() =>
                      !isCreatingProject && setSelectedPosition(position)
                    }
                    alt={alt}
                  />
                );
              }
            )}
          </Flex>
        </Box>
      </Flex>
      <Divider w="100%" color="divider.0" size={2} />
      <Image
        width="100%"
        maw={450}
        mx="auto"
        height="auto"
        src={getLanguageSelectorImage(selectedTheme, selectedPosition)}
        alt="GlobalLinkGO Language Selector Appearance"
      />
    </Flex>
  );
};
