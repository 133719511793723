import { rem } from '@mantine/core';

const constants = {
  DASHBOARD_NAME: 'GlobalLink Go',
  HEADER_HEIGHT: rem(60),
  SIDE_NAVBAR_WIDTH: 220,
  LOGO_DESKTOP_WIDTH: 200,
  LOGO_MOBILE_WIDTH: 150,
  GTM_TAG_ID: 'GTM-P2DG2MG6',
};

const C = constants;
export default C;
