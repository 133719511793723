import React, { useMemo } from 'react';
import { Container, Flex, Text, useMantineTheme } from '@mantine/core';
import { CustomTooltip, NoDataFoundPlaceHolder } from '../../Generic';
import { WordsServedResponse } from 'store';
import LoadingSkeleton from './LoadingSkeleton';
import { compactFormatter } from 'helpers';
import styles from './Dashboard.styles';
import { AccumulatedWordsServed } from './types';
import { WordsServedChart } from './WordsServedChart';
import { useGetNavigationStatus } from 'hooks';

type Props = {
  wordsServed: WordsServedResponse[];
  prevWordsServed: WordsServedResponse[];
};

const WordsServed = ({ wordsServed, prevWordsServed }: Props) => {
  const theme = useMantineTheme();
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const tooltipLabel =
    'This card shows the volume of Words Served for the language and time period specified. ' +
    'Words Served are the total number of translated words delivered to visitors to your site.';

  const accumulatedWordsServed: AccumulatedWordsServed[] = useMemo(() => {
    return wordsServed.reduce(
      (accum: AccumulatedWordsServed[], { count, timeslice }) => {
        const prevTimesliceFound = accum.find(
          (accumWordsServed) => accumWordsServed?.timeslice === timeslice
        );
        if (prevTimesliceFound) {
          const timesliceIndex = accum.findIndex(
            ({ timeslice: prevTimeslice }) => prevTimeslice === timeslice
          );
          if (timesliceIndex !== -1) {
            accum[timesliceIndex] = {
              ...prevTimesliceFound,
              count: prevTimesliceFound.count + count,
            };
          }
        } else accum.push({ count, timeslice });
        return accum;
      },
      []
    );
  }, [wordsServed]);

  const totalWordsServed = useMemo(() => {
    return accumulatedWordsServed.reduce(
      (accum, { count = 0 }) => accum + count,
      0
    );
  }, [accumulatedWordsServed]);

  const totalPrevWordsServed = useMemo(() => {
    return prevWordsServed.reduce((accum, { count = 0 }) => accum + count, 0);
  }, [prevWordsServed]);

  const wordsServedChange =
    (totalWordsServed &&
      totalPrevWordsServed &&
      Math.round(
        (Math.abs(totalWordsServed - totalPrevWordsServed) /
          totalPrevWordsServed) *
          100
      )) ||
    0;
  const hasNegativeChange = totalWordsServed - totalPrevWordsServed < 0;
  const noTrafficChange = !wordsServedChange || wordsServedChange === 0;

  const WordsServedChartWrapper = () => {
    if (loading) {
      return <LoadingSkeleton height={180} />;
    }

    if (wordsServed.length === 0) {
      return <NoDataFoundPlaceHolder height={180} />;
    }

    return (
      <Flex w="100%" columnGap={20} align="center">
        <div>
          <Text fw={700} fz={50}>
            {compactFormatter(totalWordsServed)}
          </Text>
          {!noTrafficChange && (
            <Text className={styles.percentageChange(theme, hasNegativeChange)}>
              {hasNegativeChange
                ? `-${wordsServedChange}%`
                : `+${wordsServedChange}%`}
            </Text>
          )}
        </div>
        {accumulatedWordsServed.length > 0 && (
          <WordsServedChart accumulatedWordsServed={accumulatedWordsServed} />
        )}
      </Flex>
    );
  };

  return (
    <>
      <CustomTooltip tooltipLabel={tooltipLabel}>
        <Text w={180} fz={14} fw={700} data-testid="pw-ms-words-served-title">
          WORDS SERVED
        </Text>
      </CustomTooltip>
      <Container p={0} mt={20} data-testid="pw-ms-words-served-container">
        <WordsServedChartWrapper />
      </Container>
    </>
  );
};

export default WordsServed;
