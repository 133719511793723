import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Menu,
  ScrollArea,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useUserProvider, saveDesktopProjectKey } from 'store';
import { MdArrowDropDown, MdSearch } from 'react-icons/md';
import { CircleAvatar } from './components/glgo/Generic';
import { useGLGOParams } from 'hooks';
import { saveSelectedProjectKey } from 'helpers';
import classes from './ProjectSelector.module.css';

export const ProjectSelector = () => {
  const { projectKey } = useGLGOParams();
  const navigate = useNavigate();

  // const projects = useUserProjects();
  const { userProjects } = useUserProvider();
  userProjects.sort((p1, p2) => p1.project_name.localeCompare(p2.project_name));
  const activeProject = userProjects?.find((p) => p.project_key === projectKey);
  // TODD: Handle the case where activeProject is undefined
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  const filteredProjects = userProjects?.filter((p) => {
    const name = p.project_name.toLowerCase();
    const origin = p.origin_name.toLowerCase();
    const search = searchText.toLowerCase();
    const isRevelantProject = !['PROXY', 'GLNOW'].includes(p.project_type);
    // Filter out the active project, proxy & glnow projects, and any project that doesn't match the search
    return (
      [name, origin].some((s) => s.includes(search)) &&
      p.project_key !== projectKey &&
      isRevelantProject
    );
  });

  const handleProjectSelect = (projectKey: string) => {
    saveSelectedProjectKey(projectKey);
    saveDesktopProjectKey(projectKey);
    setSearchText('');
    navigate('.');
  };

  if (activeProject && userProjects.length > 1) {
    const projectName = activeProject.project_name;
    return (
      <Menu
        onOpen={() => setTimeout(() => searchInputRef.current?.focus(), 10)}
        position="bottom-start"
        shadow="md"
        width={390}
        data-testid="pw-project-selector"
      >
        <Menu.Target>
          <Button
            className={classes.projectButton}
            maw={240}
            px={0}
            variant="subtle"
            style={{ WebkitAppRegion: 'no-drag' }}
            leftSection={<CircleAvatar name={projectName} />}
            rightSection={<MdArrowDropDown color="#071D49" size={24} />}
          >
            {projectName.length > 20 ? (
              <Tooltip zIndex={999} label={projectName}>
                <Text maw={155} fz="1rem" fw={600} truncate>
                  {projectName}
                </Text>
              </Tooltip>
            ) : (
              <Text maw={155} fz="1rem" fw={600} truncate>
                {projectName}
              </Text>
            )}
          </Button>
        </Menu.Target>
        <Menu.Dropdown className={classes.projectMenu}>
          <TextInput
            ref={searchInputRef}
            mb={10}
            value={searchText}
            placeholder="Search..."
            classNames={{ input: classes.searchInput }}
            styles={{
              section: {
                marginRight: 10,
              },
            }}
            rightSection={<MdSearch color="#071D49" size={20} />}
            onChange={(e) => {
              // setPrevSearchText(searchText);
              setSearchText(e?.target?.value || '');
            }}
          />
          <ScrollArea
            offsetScrollbars
            type="always"
            style={{ overflowX: 'hidden' }}
          >
            <Box mah={250}>
              {filteredProjects.map((project) => {
                const { project_key = '', project_name = '' } = project;
                return (
                  <Menu.Item
                    px={10}
                    py={5}
                    className={classes.projectItem}
                    key={project_key}
                    onClick={() => handleProjectSelect(project_key)}
                  >
                    <Text fz={14} w={335} truncate c="text.2" fw={400}>
                      {project_name}
                    </Text>
                  </Menu.Item>
                );
              })}
            </Box>
          </ScrollArea>
        </Menu.Dropdown>
      </Menu>
    );
  }

  if (activeProject && userProjects.length === 1) {
    const projectName = activeProject.project_name;
    return (
      <Flex align="center" gap={10} w={390}>
        <CircleAvatar name={projectName} />
        <Text fz="1rem" fw={600} truncate>
          {projectName}
        </Text>
      </Flex>
    );
  }
  return <div data-testid="empty" />;
};

export default ProjectSelector;
