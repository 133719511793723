import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Divider,
  Grid,
  Progress,
  ScrollArea,
  Text,
  Tooltip,
} from '@mantine/core';
import { WordsServedUrlsResponse } from 'store';
import { NoDataFoundPlaceHolder } from '../../Generic';
import { LoadingSkeleton } from '../godashboard/LoadingSkeleton';
import classes from './WordsServedUrls.module.css';
import { useGetNavigationStatus } from 'hooks';

const { Col } = Grid;

type WordsServedUrl = {
  content_url: string;
  wordsServed: number;
};
type Props = {
  urls: WordsServedUrlsResponse[];
};
const WordsServedUrls = ({ urls }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const accumulatedWordsServedUrls: WordsServedUrl[] = useMemo(() => {
    return urls.map((urlObj) => {
      const wordsServed = Object.entries(urlObj).reduce(
        (accum, [key, value]) => {
          if (
            key !== 'content_url' &&
            key !== 'content_url_hash' &&
            typeof value === 'number'
          )
            return accum + value;
          return accum;
        },
        0
      );

      const formatUrl = (url: string) => {
        if (url.startsWith('//')) {
          return url.slice(1);
        } else {
          return url;
        }
      };

      return {
        content_url: formatUrl(urlObj.content_url || '') || '',
        wordsServed,
      };
    });
  }, [urls]);

  const totalWordsServed: number = useMemo(() => {
    return accumulatedWordsServedUrls.reduce(
      (accum, { wordsServed }) => accum + wordsServed,
      0
    );
  }, [accumulatedWordsServedUrls]);

  const getWordsServedPercentage = useCallback(
    (wordsServed: number) => {
      return Math.round((wordsServed * 100) / totalWordsServed) || 0;
    },
    [totalWordsServed]
  );

  const filteredAndSortedWordsServedUrls: WordsServedUrl[] = useMemo(() => {
    return accumulatedWordsServedUrls
      .filter(({ wordsServed }) => getWordsServedPercentage(wordsServed) >= 1)
      .sort((url1, url2) =>
        url1.wordsServed < url2.wordsServed
          ? 1
          : url1.wordsServed > url2.wordsServed
            ? -1
            : 0
      );
  }, [accumulatedWordsServedUrls, getWordsServedPercentage]);

  const WordsServedUrlsRows = () => {
    if (loading) {
      return <LoadingSkeleton height={450} />;
    }
    if (urls.length === 0) {
      return <NoDataFoundPlaceHolder height={450} />;
    }

    return filteredAndSortedWordsServedUrls.map(
      ({ content_url, wordsServed }, index) => {
        const wordsServedPercentage = getWordsServedPercentage(wordsServed);
        return (
          <Box w="100%" key={content_url}>
            {index !== 0 &&
            index !== filteredAndSortedWordsServedUrls.length ? (
              <Divider
                w="100%"
                orientation="horizontal"
                size="xs"
                color="divider.2"
              />
            ) : (
              <></>
            )}
            <Grid
              align="center"
              classNames={{
                root: `${classes.gridRoot} ${classes.urlGridRoot}`,
                inner: classes.gridInner,
              }}
              h={60}
              m={-8}
            >
              <Grid.Col span={{ base: 7, sm: 6 }}>
                <Tooltip
                  position="top"
                  events={{ hover: true, touch: true, focus: false }}
                  label={content_url}
                >
                  <Text fw={400} fz="1rem" truncate>
                    {content_url}
                  </Text>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={{ base: 5, sm: 2 }}>
                <Tooltip
                  position="top"
                  events={{ hover: true, touch: true, focus: false }}
                  label={wordsServed.toLocaleString()}
                >
                  <Text fw={700} fz="1rem" truncate>
                    {wordsServed.toLocaleString()}
                  </Text>
                </Tooltip>
              </Grid.Col>
              <Grid.Col
                display={{ base: 'none', sm: 'block' }}
                span={{ base: 0, sm: 'auto' }}
              >
                <Tooltip
                  position="top"
                  events={{ hover: true, touch: true, focus: false }}
                  label="This is the percentage of total traffic to your site from this country over the specified time period."
                >
                  <Progress
                    className={classes.urlProgBar}
                    value={wordsServedPercentage}
                    w="100%"
                  />
                </Tooltip>
              </Grid.Col>
              <Grid.Col
                display={{ base: 'none', sm: 'block' }}
                span={{ base: 0, sm: 1 }}
              >
                <Tooltip
                  position="top"
                  events={{ hover: true, touch: true, focus: false }}
                  label="This is the percentage of total traffic to your site from this country over the specified time period."
                >
                  <Text fw={700} fz="1rem">
                    {wordsServedPercentage}%
                  </Text>
                </Tooltip>
              </Grid.Col>
            </Grid>
          </Box>
        );
      }
    );
  };

  return (
    <Box p={0} style={{ borderRadius: 0, borderColor: 'transparent' }}>
      <Grid
        w="100%"
        display="flex"
        align="center"
        m={0}
        py={10}
        bg="background2.5"
        classNames={{
          root: classes.gridRoot,
          inner: classes.gridInner,
        }}
      >
        <Col pl={30} span={{ base: 7, sm: 6 }}>
          <Text tt="uppercase" fw={700}>
            Pages
          </Text>
        </Col>
        <Col span={{ base: 5, sm: 6 }}>
          <Text tt="uppercase" fw={700}>
            Words Served
          </Text>
        </Col>
      </Grid>
      <ScrollArea
        w="100%"
        styles={{
          viewport: {
            '& > div': {
              display: 'block !important',
            },
          },
        }}
        type="always"
        offsetScrollbars
      >
        <Box h={450} w="100%" pl={30} pr={20}>
          <WordsServedUrlsRows />
        </Box>
      </ScrollArea>
    </Box>
  );
};

export default WordsServedUrls;
