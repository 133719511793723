import React, { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Select,
  Textarea,
  TextInput,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  useNotifyStore,
  NotifyStore,
  GlobalModalStore,
  useGlobalModalStore,
} from 'store';
import classes from './ContactUsModalForm.module.css';

type FormValues = {
  category: string;
  subject: string;
  description: string;
};

type Props = {
  userName: string;
  email: string;
  userKey: string;
  projectName: string;
  projectKey: string;
};

const ContactUsModalForm = ({
  userName,
  email,
  userKey,
  projectName,
  projectKey,
}: Props) => {
  const { setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const [formValues, setFormValues] = useState<FormValues>({
    category: '',
    subject: '',
    description: '',
  });

  const [categoryError, setCategoryError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const { sendFeedbackMessage } = useNotifyStore((state: NotifyStore) => state);

  const validateFormFields = () => {
    if (!formValues.category)
      setCategoryError('Please enter a value for this field.');
    if (!formValues.subject)
      setSubjectError('Please enter a value for this field.');
    if (!formValues.description)
      setDescriptionError('Please enter a value for this field.');
    return formValues.category && formValues.subject && formValues.description;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateFormFields()) return;

    sendFeedbackMessage(
      {
        level: 'info',
        userTitle: email,
        userName,
        userKey,
        projectKey,
        project: projectName,
        app: 'GlobalLinkGO',
        domain: 'Live',
        title: formValues.subject,
        message: formValues.description,
        issueType: formValues.category,
      },
      () => {
        notifications.show({ message: 'Successfully sent your message!' });
        setShowContactUsModal(false);
      }
    );
  };

  return (
    <>
      <form className={classes.formRoot} onSubmit={handleSubmit}>
        <Flex direction="column">
          <Select
            label="Issue Type"
            onChange={(e: string | null) => {
              if (e === null) return;

              setFormValues({ ...formValues, category: e });
              if (categoryError) setCategoryError('');
            }}
            placeholder="Select issue type"
            classNames={{
              wrapper: classes.inputWrapper,
            }}
            styles={{}}
            comboboxProps={{
              withinPortal: true,
              zIndex: 99999,
            }}
            data={['Layout Issue', 'Functionality Issue', 'Improvement']}
            value={formValues.category}
            withAsterisk
            withCheckIcon={false}
            style={{ label: classes.label, input: classes.input }}
          />
          {categoryError && (
            <Text c="#D93025" fz="0.75rem">
              {categoryError}
            </Text>
          )}
        </Flex>
        <Flex direction="column">
          <TextInput
            label="Subject"
            onChange={(e) => {
              setFormValues({ ...formValues, subject: e.currentTarget.value });
              if (subjectError) setSubjectError('');
            }}
            placeholder="Enter a subject"
            style={{ label: classes.label, input: classes.input }}
            withAsterisk
          />
          {subjectError && (
            <Text c="#D93025" fz="0.75rem">
              {subjectError}
            </Text>
          )}
        </Flex>
        <Flex direction="column">
          <Textarea
            label="Description"
            value={formValues.description}
            minRows={5}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                description: e.currentTarget.value,
              });
              if (descriptionError) setDescriptionError('');
            }}
            placeholder="Enter a description"
            style={{ label: classes.label, input: classes.input }}
            withAsterisk
          />
          {descriptionError && (
            <Text c="#D93025" fz="0.75rem">
              {descriptionError}
            </Text>
          )}
        </Flex>
        <Divider color="divider.1" my="2rem" />
        <Flex justify="end">
          <Button
            color="icon.0"
            disabled={
              !formValues.category ||
              !formValues.description ||
              !formValues.subject
            }
            type="submit"
            value="submit"
          >
            Submit
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default ContactUsModalForm;
