import React, { useCallback, useMemo } from 'react';
import { Card, Container, Flex, Group, Text } from '@mantine/core';
import { CardHeader } from '../../../Generic';
import { getTimeSliceOptions } from '../../utils';
import WordsServedUrls from '../WordsServedUrls';
import WordsServedTimeSeries from './WordsServedTimeSeries';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { UsageLoaderData } from 'store';
import { filterTargets, updateDateParam } from '../../gometrics/trafficUtils';
import DateFilter from '../../selectors-filters/DateFilter';

export const WordsServedUsageDetails = () => {
  const {
    targets = [],
    urls = [],
    timeSliceFrom,
  } = useRouteLoaderData('usage') as UsageLoaderData;

  const filteredTargets = useMemo(() => filterTargets(targets), [targets]);
  const filteredTranslationKeys = useMemo(
    () => filteredTargets.map(({ value }) => value),
    [filteredTargets]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedDate = useCallback(
    (value: string) => {
      updateDateParam(value, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Container maw="100%" p={0}>
      <Group w="100%" mt={10}>
        {' '}
        <CardHeader
          title={
            <Text fw={700} mb={10} size="1.5rem" span>
              Words Served Usage
            </Text>
          }
          tooltipLabel="This graph should give the user an at-a-glance view of the words served usage for their website split out by language.
              The default time span for the graph is the current billing period and the data is displayed daily, but the user has the ability to change both the time span and the slices displayed (i.e. monthly/daily).
              The user can also select a specific language that they would like to view over the given time series."
          flexItems={
            <Flex justify="flex-end">
              <DateFilter
                timeSliceOptions={getTimeSliceOptions()}
                timeSliceFrom={timeSliceFrom}
                onSelect={handleSelectedDate}
              />
            </Flex>
          }
          justifyItems="flex-end"
        />
      </Group>
      <Card p={0}>
        <WordsServedTimeSeries
          timeSliceFrom={timeSliceFrom}
          translationKeys={filteredTranslationKeys}
        />
      </Card>
      <Card p={0} mt={20}>
        <WordsServedUrls urls={urls} />
      </Card>
    </Container>
  );
};
