import React from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Box } from '@mantine/core';
import { useGLGOParams } from 'hooks';
import { glossaryQuery } from 'store';

import { xapisEnv } from 'store';

import { useUserProvider } from 'store';

import {
  SegmentEditor,
  SegmentEditorRef,
  SegmentEditorProvider,
  SegmentEditorConfig,
} from 'segment-editor';

type EditorProps = {
  target: TranslationKey;
  segmentEditorRef: React.RefObject<SegmentEditorRef> | null;
};

export function TranslationEditor({ segmentEditorRef, target }: EditorProps) {
  const token = xapisEnv.getToken;
  const user = useUserProvider().xapisUser;
  const { projectKey } = useGLGOParams();

  const { data: glossary } = useSuspenseQuery(glossaryQuery(projectKey || ''));

  if (!target) return <div>Target/Project not defined</div>;

  const targetGlossaryItems = glossary?.glossary_items?.length
    ? // Get glossary items for the current target and set empty legacy rules to 'translate_as'
      glossary.glossary_items
        .filter((g) => g.translation_key === target.translation_key)
        .map((g) => ({
          ...g,
          rule: g.rule || 'translate_as',
        }))
    : [];

  const { xapisUrl } = xapisEnv.getHost;

  const segmentEditorConfig: SegmentEditorConfig = {
    target,
    user,
    glossary: targetGlossaryItems,
    settings: {
      xapisHost: xapisUrl,
      contextMode: false,
      accessToken: token,
      disableTagEdit: false,
      viewOnly: false,
    },
  };

  return (
    <Box>
      <SegmentEditorProvider config={segmentEditorConfig}>
        <SegmentEditor
          key={`${target.translation_key}`}
          ref={segmentEditorRef}
          contextData={null}
        />
      </SegmentEditorProvider>
    </Box>
  );
}
