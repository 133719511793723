const { saveUser, saveProjectKey, getLastDTProjectKey } =
  window.UserManager || {};
// send IPC message to window with user data
export const saveDesktopUser = (user: User) => {
  if (typeof saveUser === 'function') {
    saveUser(user);
  }
};
export const saveDesktopProjectKey = (pKey: string) => {
  if (typeof saveProjectKey === 'function') {
    saveProjectKey(pKey);
  }
};
export const getLastProjectKey = async (): Promise<string> => {
  if (typeof saveProjectKey === 'function') {
    return getLastDTProjectKey();
  }
  return '';
};
