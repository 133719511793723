import React from 'react';
import { Flex, Text, UnstyledButton } from '@mantine/core';
import { MdArrowBack } from 'react-icons/md';
import { EverythingIcon } from '../../images/EverythingIcon';
import { BsExclude, BsIntersect } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useGLGOParams } from 'hooks';

const BackButtonSection = ({ scope }: { scope: string }) => {
  const navigate = useNavigate();
  const { projectId = '' } = useGLGOParams();

  return (
    <Flex
      w="100%"
      align="center"
      p="1rem 1.5rem 1rem 2rem"
      style={{ backgroundColor: '#F7EAE4' }}
    >
      <UnstyledButton
        h={24}
        onClick={() => navigate(`/${projectId}/settings/project-scope`)}
        w={24}
      >
        <MdArrowBack size={24} />
      </UnstyledButton>
      <Flex align="center" mx="auto">
        <Text ta="center">Your default scope is in</Text>
        <Text ml="0.5rem" mt="0.25rem">
          {scope === 'all' ? (
            <EverythingIcon width="20px" height="20px" />
          ) : scope === 'exclude' ? (
            <BsExclude fill="#5D6B89" width={20} height={20} />
          ) : (
            <BsIntersect fill="#167934" width={20} height={20} />
          )}
        </Text>
        <Text fw={700} mx="0.35rem" td="underline">
          {scope === 'all'
            ? 'Everything'
            : scope[0].toUpperCase() + scope.substring(1)}
        </Text>{' '}
        mode.
      </Flex>
    </Flex>
  );
};

export default BackButtonSection;
