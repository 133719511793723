import {
  NavLink,
  Outlet,
  RouteObject,
  useNavigation,
  useMatch,
  Navigate,
} from 'react-router-dom';
import { Box, Flex } from '@mantine/core';
import classes from './RouterTabs.module.css';

type Props = {
  tabs: RouteObject[];
  settings: { path: string; defaultTab: string | undefined };
};
export const RouterTabs = ({ tabs, settings }: Props) => {
  const { state } = useNavigation();
  const isLoading = state !== 'idle';

  if (!settings || !settings.path) {
    console.error('Page settings is missing. Unexpected behavior may occur.');
  }
  const match = useMatch(`/:projectId/${settings?.path || ''}`);

  if (match) {
    return <Navigate to={settings.defaultTab || tabs[0]?.path || ''} replace />;
  }

  if (!tabs?.length) return <Box p={40}>No routes given</Box>;

  return (
    <Box className={classes.container}>
      <Flex className={classes.tabsBar}>
        {tabs.map((tab) => (
          <Box key={tab.path} mb={6}>
            <NavLink
              to={tab.path || ''}
              className={({ isActive }) =>
                `${classes.tab} ${isActive ? classes.active : ''}`
              }
            >
              {tab.path?.replaceAll('-', ' ')}
            </NavLink>
          </Box>
        ))}
      </Flex>
      <Box opacity={isLoading ? 0.2 : 1}>
        <Outlet />
      </Box>
    </Box>
  );
};
