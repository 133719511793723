import React, { Suspense, useState } from 'react';
import { Box, Card, Divider, Flex, Text } from '@mantine/core';
import NewPaymentModal from './NewPaymentModal';
import { useDisclosure } from '@mantine/hooks';
import {
  BillingLoaderData,
  ProjectResponse,
  Subscription,
  sortSourcesByDefault,
} from 'store';
import { getPrettyDate } from 'helpers';
import { Await, useRevalidator, useRouteLoaderData } from 'react-router-dom';
import classes from './PlanInformation.module.css';
import RestartSubModal from './subs-action-modals/RestartSubModal';
import { useGLGOParams } from 'hooks';
import FreeTrialInformation from './FreeTrialInformation';
import PaidPlanInformation from './PaidPlanInformation';
import ManagePaymentMethods from './ManagePaymentMethods';
import EstimateInformation from './EstimateInformation';

export type PlanOption = Pick<
  Subscription,
  'threshold_value' | 'subscription_type' | 'sku_name' | 'sku' | 'price'
> & { is_canceled?: boolean };

const PlanInformation = () => {
  const revalidator = useRevalidator();
  const { skus, subscriptions, sources, default_source_id, quickQuotePromise } =
    useRouteLoaderData('billing') as BillingLoaderData;
  const subscription_status =
    (useRouteLoaderData('project') as ProjectResponse).project
      ?.subscription_status || 'expired';

  const project_key = useGLGOParams().projectKey || '';

  const sub =
    subscriptions.length > 0 ? subscriptions[0] : ({} as Subscription);

  const {
    sku = '',
    price = 0,
    next_renewal_date = '',
    words_served = 0,
    is_canceled = false,
  } = sub;
  const isExpired = subscription_status === 'expired';
  const isFreePlan = subscriptions.length > 0 && price === 0;
  const date = getPrettyDate(next_renewal_date);

  const sortedCards = sortSourcesByDefault(sources, default_source_id);
  const [cards, setCards] = useState(sortedCards);
  const [translatedTraffic, setTranslatedTraffic] = useState(10);

  const [paymentModalOpened, { open: openPayModal, close: closePayModal }] =
    useDisclosure(false);
  const [
    restartModalOpened,
    { open: openRestartModal, close: closeRestartModal },
  ] = useDisclosure(false);

  const restartParams = {
    project_key,
    sku,
    revalidator,
    closeModal: closeRestartModal,
  };

  const InfoBlock = () => {
    if (isFreePlan) {
      return (
        <Suspense
          fallback={
            <FreeTrialInformation
              nextRenewalDate={next_renewal_date}
              wordsServedUsed={words_served}
              skus={skus}
              translatedTraffic={translatedTraffic}
              loading={true}
              isExpired={isExpired}
            />
          }
        >
          <Await resolve={quickQuotePromise}>
            {({ data: { qmessage = '' } = {}, data = {} }) => {
              const isQuickQuoteCached = qmessage.includes('result was cached');

              return (
                <FreeTrialInformation
                  nextRenewalDate={next_renewal_date}
                  wordsServedUsed={words_served}
                  skus={skus}
                  translatedTraffic={translatedTraffic}
                  isQuickQuoteCached={isQuickQuoteCached}
                  loading={Object.keys(data).length === 0}
                  isExpired={isExpired}
                />
              );
            }}
          </Await>
        </Suspense>
      );
    }

    return (
      <PaidPlanInformation
        subscriptions={subscriptions}
        isExpired={isExpired}
        isCancelled={is_canceled}
        openRestartModal={openRestartModal}
      />
    );
  };

  return (
    <Flex w="100%" pb="2rem" direction="column" mt="2rem" rowGap="1rem">
      <Text fw={700} mb={10} size="1.5rem">
        Plan Information
      </Text>
      <Card
        display="flex"
        shadow="xs"
        p="2rem 1.5rem"
        radius="md"
        className={classes.planInfoContainer}
      >
        <RestartSubModal
          opened={restartModalOpened}
          onClose={closeRestartModal}
          renewalDate={date}
          restartParams={restartParams}
          isExpired={isExpired}
        />
        <NewPaymentModal
          opened={paymentModalOpened}
          onClose={closePayModal}
          cards={cards}
          setCards={setCards}
        />
        <Flex direction={{ base: 'column', lg: 'row' }} rowGap="1rem">
          <Box w={{ base: '100%', lg: '50%' }}>
            <InfoBlock />
          </Box>
          <Divider
            color="divider.0"
            orientation="vertical"
            size="sm"
            mx="2rem"
          />
          <Box w={{ base: '100%', lg: '50%' }}>
            {isFreePlan ? (
              <Suspense
                fallback={
                  <EstimateInformation
                    translatedTraffic={translatedTraffic}
                    setTranslatedTraffic={setTranslatedTraffic}
                    loading={true}
                  />
                }
              >
                <Await resolve={quickQuotePromise}>
                  {({ data: { qmessage = '' } = {}, data = {} }) => {
                    const isQuickQuoteCached =
                      qmessage.includes('result was cached');

                    return (
                      <EstimateInformation
                        translatedTraffic={translatedTraffic}
                        setTranslatedTraffic={setTranslatedTraffic}
                        loading={Object.keys(data).length === 0}
                        isQuickQuoteCached={isQuickQuoteCached}
                      />
                    );
                  }}
                </Await>
              </Suspense>
            ) : (
              <ManagePaymentMethods
                default_source_id={default_source_id}
                sources={sources}
                cards={cards}
                setCards={setCards}
                openPayModal={openPayModal}
              />
            )}
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PlanInformation;
