import React from 'react';
import { Menu, Text } from '@mantine/core';
import { useDeploymentContext } from './DeploymentContextProvider';
import classes from './ChangeMethodMenu.module.css';

const ChangeMethodMenu = ({ children }: { children: React.ReactNode }) => {
  const { setShowForm, setShowChangeMethodModal } = useDeploymentContext();

  return (
    <Menu
      shadow="md"
      position="right"
      zIndex={20}
      offset={{ mainAxis: 10, crossAxis: 5 }}
      trigger="hover"
      classNames={{
        dropdown: classes.dropdown,
        item: classes.item,
      }}
    >
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          onClick={() => {
            setShowChangeMethodModal(true);
            setShowForm(false);
          }}
        >
          <Text fz="1.1rem" fw={600}>
            Change method
          </Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ChangeMethodMenu;
