import React from 'react';
import { Modal } from '@mantine/core';
import { AddLanguages } from './AddLanguages';

type Props = {
  showAddLanguagesModal: boolean;
  setShowAddLanguagesModal: React.Dispatch<React.SetStateAction<boolean>>;
  showSpecifyModal: boolean;
  setShowSpecifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTranslations: Partial<LanguageCode>[];
  setSelectedTranslations: React.Dispatch<
    React.SetStateAction<Partial<LanguageCode>[]>
  >;
  availableTranslations: Partial<LanguageCode>[];
};

function AddLanguagesModal({
  showAddLanguagesModal,
  setShowAddLanguagesModal,
  availableTranslations,
  showSpecifyModal,
  setShowSpecifyModal,
  selectedTranslations,
  setSelectedTranslations,
}: Props) {
  return (
    <Modal
      size="lg"
      centered
      opened={showAddLanguagesModal}
      onClose={() => {
        setShowAddLanguagesModal(false);
      }}
      padding="3rem"
    >
      <AddLanguages
        showSpecifyModal={showSpecifyModal}
        setShowSpecifyModal={setShowSpecifyModal}
        setShowAddLanguagesModal={setShowAddLanguagesModal}
        availableTranslations={availableTranslations}
        selectedTranslations={selectedTranslations}
        setSelectedTranslations={setSelectedTranslations}
      />
    </Modal>
  );
}

export default AddLanguagesModal;
