import React, { useState } from 'react';
import { Box, Button, Divider, Flex, Loader, Modal, Text } from '@mantine/core';
import { RestartParams, restart } from '../planActions';

type Props = {
  opened: boolean;
  onClose: () => void;
  renewalDate: string;
  restartParams: RestartParams;
  isExpired: boolean;
};

const RestartSubModal = ({
  opened,
  onClose,
  renewalDate,
  restartParams,
  isExpired,
}: Props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      padding="2rem"
      size="md"
      radius={20}
    >
      <Box p="1rem" pb={0}>
        <Text ta="center">
          Your subscription {isExpired ? 'expired' : 'is set to expire'} on{' '}
          {renewalDate}. Click "Confirm" below to renew your subscription. You
          will be billed at your current subscription plus applicable taxes on
          the renewal date.
        </Text>
      </Box>
      <Divider w="95%" my="1.5rem" />
      <Flex
        align="center"
        justify="space-between"
        gap={{ base: 10, lg: 20 }}
        direction={{ base: 'column-reverse', sm: 'row' }}
      >
        <Button onClick={onClose} radius={32} variant="outline">
          Cancel
        </Button>
        <Button
          bg="text.8"
          mb={{ base: '0.5rem', sm: 0 }}
          onClick={() => {
            setLoading(true);
            restart({ ...restartParams });
          }}
          w={153}
        >
          {loading ? <Loader color="white" size="sm" /> : 'Confirm'}
        </Button>
      </Flex>
    </Modal>
  );
};

export default RestartSubModal;
