import React from 'react';
import { Flex } from '@mantine/core';

export const MetricsFilters = ({ children }: { children: React.ReactNode }) => (
  <Flex px={0} py={10} columnGap={10} align="center">
    {children}
  </Flex>
);

export default MetricsFilters;
