import { Button } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
};

const ManageSubButton = ({ children }: Props) => (
  <Link to="manage-subscription">
    <Button px={0} size="md" c="text.8" bg="transparent" variant="subtle">
      {children}
    </Button>
  </Link>
);

export default ManageSubButton;
