import React from 'react';
import {
  Box,
  Divider,
  Flex,
  Slider,
  Stack,
  StackProps,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { InfoIcon } from '../../../../../StaticIcons';
import { RiDivideLine, RiEqualLine } from 'react-icons/ri';
import { FaTimes } from 'react-icons/fa';
import { CustomTooltip } from '../../../Generic';

type Props = {
  monthlyPageViews: number;
  estimatedWordsServed: number;
  analyzedWords: string;
  analyzedPages: string;
  averageWordsPerPage: number;
  translatedTraffic: number;
  setTranslatedTraffic: React.Dispatch<React.SetStateAction<number>>;
  stackProps?: StackProps;
};

const EstimateCalculation = ({
  monthlyPageViews,
  estimatedWordsServed,
  analyzedWords,
  analyzedPages,
  averageWordsPerPage,
  translatedTraffic,
  setTranslatedTraffic,
  stackProps,
}: Props) => {
  const colors = useMantineTheme().colors;

  return (
    <Stack w="100%" py="3rem" px="1rem" gap="1.5rem" {...stackProps}>
      <Flex justify="space-between" columnGap="1rem">
        <Text fw={500}>Analyzed Words</Text>
        <Text fw={700}>{analyzedWords}</Text>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Text fw={500}>Analyzed Pages</Text>
        <Flex columnGap="0.75rem" align="center">
          <RiDivideLine
            color={colors.text[8]}
            style={{ minWidth: 22 }}
            size={22}
            strokeWidth={1.5}
          />
          <Text fw={700}>{analyzedPages}</Text>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" color={colors.divider[0]} />
      <Flex pl="2rem" justify="space-between" columnGap="2rem">
        <Text fw={400}>Average Words per Page</Text>
        <Flex columnGap="0.75rem" align="center">
          <RiEqualLine
            color={colors.text[8]}
            style={{ minWidth: 22 }}
            size={22}
            strokeWidth={1.5}
          />
          <Text fw={700}>{averageWordsPerPage.toLocaleString()}</Text>
        </Flex>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Text fw={500}>
          Monthly Pageviews&nbsp;
          <CustomTooltip
            tooltipLabel="Monthly page views are obtained from a third party web traffic data provider
                  and are not guaranteed to be accurate. You should compare against your own internal data to be sure."
          >
            <Box display="inline-block">
              <InfoIcon fill={colors.icon[0]} size={15} />
            </Box>
          </CustomTooltip>
        </Text>
        <Flex columnGap="0.75rem" align="center">
          <FaTimes color={colors.text[8]} style={{ minWidth: 18 }} size={18} />
          <Text fw={700}>{monthlyPageViews.toLocaleString()}</Text>
        </Flex>
      </Flex>
      <Flex justify="space-between" columnGap="2rem">
        <Text display="inline" fw={500}>
          Translated Traffic&nbsp;
          <CustomTooltip
            tooltipLabel="This number is an estimate and represents what percentage of your total web traffic would choose
                  a translated language if it were available. You can edit it up or down based on your own estimate."
          >
            <Box display="inline-block">
              <InfoIcon fill={colors.icon[0]} size={15} />
            </Box>
          </CustomTooltip>
        </Text>
        <Flex style={{ flex: 1 }} columnGap="1.25rem" align="center">
          <Flex w="100%" columnGap="0.5rem" align="center" justify="flex-end">
            <FaTimes
              color={colors.text[8]}
              style={{ minWidth: 18 }}
              size={18}
            />
            <Slider
              w="100%"
              maw="6.5rem"
              onChange={setTranslatedTraffic}
              value={translatedTraffic}
              label={(value: number) => `${value}%`}
            />
          </Flex>
          <Text fw={700}>{translatedTraffic}%</Text>
        </Flex>
      </Flex>
      <Divider orientation="horizontal" color={colors.divider[0]} />
      <Text
        fw={700}
        fz="1.75rem"
        lh="1.75rem"
        ta="center"
        style={{ textWrap: 'wrap' }}
      >
        {estimatedWordsServed.toLocaleString()} /&nbsp;
        <Text fz="0.9rem" fw={700} lh="0.9rem" ta="center" span>
          Monthly Words Served
        </Text>
      </Text>
    </Stack>
  );
};

export default EstimateCalculation;
