import { Card, Flex, Grid, Text } from '@mantine/core';
import { StatusCircleIcon, OpenContext } from 'ui';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { HiPencil } from 'react-icons/hi';
import { getMTEngine, desktopRC, encodeKey } from 'helpers';
import { useGLGOParams } from 'hooks';

type Props = {
  translation: TranslationKey;
  handleSelection: (tKey: string) => void;
};
export const TranslationCard = ({ translation, handleSelection }: Props) => {
  const {
    target_lang_code: targetLangCode = '',
    target_lang_name: targetLangName = '',
    source_lang_name: sourceLangName = '',
    is_live: isLive = false,
    num_segment_translations: numOfSegmentTranslations = 0,
    mt_api_code: mtApiCode = '',
    translation_key: translationKey = '',
  } = translation || {};

  const isValidTarget =
    targetLangCode !== 'YY' && targetLangName !== sourceLangName;
  const sourceLanguage =
    sourceLangName?.substring(0, sourceLangName.indexOf('(')).slice(0, -1) ||
    'English';
  const mtEngine = getMTEngine(mtApiCode);
  const { projectId } = useGLGOParams();

  return !targetLangName || isValidTarget ? (
    <Card p={0}>
      <Grid
        display="flex"
        mih={105}
        align="center"
        my="auto"
        px={{ base: 20, sm: 40 }}
        py={10}
      >
        <Grid.Col span={{ base: 12, sm: 6 }}>
          <Flex direction="row" align="center" columnGap={20}>
            <StatusCircleIcon status={isLive ? 'live' : 'private'} />
            <Text fw={500}>{sourceLanguage}</Text>
            <MdOutlineArrowForwardIos size={20} color="#000" />
            <Text fw={500}>{targetLangName}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text c="text2.5" fz="0.75rem" fw={700}>
            MT ENGINE
          </Text>
          <Text fz="1rem" fw={500}>
            {mtEngine || 'N/A'}
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 3, sm: 2 }}>
          <Text c="text2.5" fz="0.75rem" fw={700}>
            # OF SEGMENTS
          </Text>
          <Text c="text2.6" fz="1rem" fw={500}>
            {new Intl.NumberFormat().format(numOfSegmentTranslations)}
          </Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex align={'center'} justify={'center'} gap={8}>
            {numOfSegmentTranslations ? (
              <Flex
                onClick={() => {
                  handleSelection(translationKey);
                }}
              >
                <HiPencil className="cardActionIcon" />
              </Flex>
            ) : (
              <div style={{ width: 20 }} />
            )}
            <OpenContext
              path={`/${projectId}/${desktopRC.TRANSLATIONS}?t=${encodeKey(translationKey)}`}
            />
          </Flex>
        </Grid.Col>
      </Grid>
    </Card>
  ) : null;
};

export default TranslationCard;
