import React, { useMemo } from 'react';
import { Box, Container, Flex, Progress, Text } from '@mantine/core';
import { WorldTrafficResponse } from 'store';
import LoadingSkeleton from './LoadingSkeleton';
import { CustomTooltip, NoDataFoundPlaceHolder } from '../../Generic';
import { useGetNavigationStatus } from 'hooks';

type Props = {
  worldTraffic: WorldTrafficResponse[];
};
const WorldTraffic = ({ worldTraffic }: Props) => {
  const loading = useGetNavigationStatus().isNavigatingInPlace;
  const tooltipLabel =
    'This card shows traffic to your website from countries around the world and percentages indicating the share of total traffic from that country for the language and time period specified.\n' +
    '\n' +
    'Note that this card does not show traffic related to translations. Traffic from any country could be viewing your site in any of the languages available. E.g., ' +
    'not all traffic from the United States is necessarily viewing the English version of your website.';

  const totalTrafficCount = useMemo(() => {
    return worldTraffic.reduce((accum, { count = 0 }) => accum + count, 0);
  }, [worldTraffic]);

  const calculateTrafficPercentage = (count: number) => {
    if (totalTrafficCount > 0) {
      return Math.round((count / totalTrafficCount) * 100);
    }
    return 0;
  };

  const sortedWorldTraffic = useMemo(() => {
    return worldTraffic
      .filter(({ country_name = '' }) => country_name)
      .sort((a, b) => b.count - a.count)
      .slice(0, 7);
  }, [worldTraffic]);

  const WorldTrafficTable = () => {
    if (loading) {
      return <LoadingSkeleton height={180} />;
    }

    if (worldTraffic.length === 0) {
      return <NoDataFoundPlaceHolder height={180} />;
    }

    return (
      <Box mt={20} h={180}>
        {sortedWorldTraffic.map(
          ({ count = 0, label = 'Unknown', country_name = 'Unknown' }) => {
            const trafficPercentage = calculateTrafficPercentage(count);
            return trafficPercentage >= 1 ? (
              <Flex key={label} w="100%" columnGap={20} align="center">
                <Text w="30%" fw={500} fz={11}>
                  {country_name}
                </Text>
                <Progress
                  w="50%"
                  bg="background.0"
                  color="blue.5"
                  size="lg"
                  value={trafficPercentage}
                />
                <Flex w="20%" justify="flex-end">
                  <Text fz={16} fw={700}>
                    {trafficPercentage}%
                  </Text>
                </Flex>
              </Flex>
            ) : (
              <React.Fragment key={label} />
            );
          }
        )}
      </Box>
    );
  };

  return (
    <>
      <CustomTooltip tooltipLabel={tooltipLabel}>
        <Text
          w={120}
          fz={14}
          fw={700}
          mb={10}
          data-testid="pw-ms-world-traffic-title"
        >
          WORLD TRAFFIC
        </Text>
      </CustomTooltip>
      <Container p={0} mt={20} data-testid="pw-ms-world-traffic-container">
        <WorldTrafficTable />
      </Container>
    </>
  );
};

export default WorldTraffic;
