import React from 'react';
import { Button, Divider, Flex, Modal, Text } from '@mantine/core';
import classes from './DeactivateModal.module.css';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  removeActiveTranslation: (selectedTranslationKey: string) => void;
  selectedTranslation: TranslationKey | null;
  setSelectedTranslation: React.Dispatch<
    React.SetStateAction<TranslationKey | null>
  >;
};
export const DeactivateModal = ({
  isModalOpen,
  setIsModalOpen,
  removeActiveTranslation,
  selectedTranslation,
  setSelectedTranslation,
}: Props) => {
  return (
    <Modal
      size="lg"
      centered
      opened={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setSelectedTranslation(null);
      }}
    >
      <Flex direction="column" rowGap={20} px={40} pt={20} pb={40}>
        <Text fz="1.5rem" fw={700} ta="center">
          Are you sure you want to deactivate this language?
        </Text>
        <Divider orientation="horizontal" size="sm" color="border.1" />
        <Text fz="1.05rem" fw={400} ta="center">
          Deactivating a language means that your site will no longer offer it
          as an option and no further translations will be generated. The
          metrics history and your saved translations will be retained.
          <br />
          <br />
          You can always reactivate the language later!
        </Text>
        <Flex mt={20} w="100%" justify="center" columnGap={20}>
          <Button
            mih={40}
            w={160}
            fz="1rem"
            c="text.8"
            bg="background.0"
            className={classes.cancelButton}
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            mih={40}
            w={160}
            fz="1rem"
            bg="background2.2"
            className={classes.confirmButton}
            onClick={() => {
              if (selectedTranslation?.translation_key) {
                removeActiveTranslation(selectedTranslation?.translation_key);
                setIsModalOpen(false);
              }
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
