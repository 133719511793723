import React, { useState } from 'react';
import {
  Button,
  Card,
  CopyButton,
  Divider,
  Flex,
  Paper,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useGLGOParams } from 'hooks';
import { CardHeader } from '../../../Generic';
import { MdContentCopy, MdHelp } from 'react-icons/md';
import { ProjectResponse } from 'store';
import { Link, useRouteLoaderData } from 'react-router-dom';
import EditDomainForm from './EditDomainForm';

export const Injection = () => {
  const { colors } = useMantineTheme();
  const { projectKey } = useGLGOParams();
  const { project: { origin_name = '' } = {} } = useRouteLoaderData(
    'settings'
  ) as ProjectResponse;

  const [originName, setOriginName] = useState(origin_name);

  const previewDomain = originName
    ? `https://${originName}?glgo_preview=1`
    : '';

  const longCode = `
    <script referrerPolicy="no-referrer-when-downgrade"
    type="text/javascript" src="https://www.onelink-edge.com/moxie.min.js"
    data-oljs="P${projectKey}"></script>
  `;

  return (
    <>
      <Card mt={30} p={30} w="100%" maw="44rem">
        <CardHeader title="INJECTION DETAILS" itemsType="icon" />
        <Divider color={colors.divider[1]} my="1.5rem" size="xs" />
        <Paper withBorder w="100%" py="1rem" radius="lg" shadow="none">
          <EditDomainForm setOriginName={setOriginName} />
          <Divider w="100%" my="0.5rem" size="xs" />
          <Flex
            py="1rem"
            px={{ base: '1rem', sm: '2rem' }}
            direction="column"
            rowGap="1.5rem"
          >
            <Flex rowGap="1rem" direction="column">
              <Text fz="1rem" fw={700}>
                Snippet
              </Text>
              <Paper
                withBorder
                p="1.75rem"
                radius="lg"
                shadow="none"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Text
                  fw={600}
                  fz={13}
                  maw={410}
                  mr="1rem"
                  data-testid="pw-project-settings-snippet"
                  style={{ wordBreak: 'break-all' }}
                >
                  {longCode}
                </Text>
                <CopyButton value={longCode} timeout={2000}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? 'Copied!' : 'Copy'}>
                      <UnstyledButton onClick={copy}>
                        <MdContentCopy fill={colors.icon[0]} size={28} />
                      </UnstyledButton>
                    </Tooltip>
                  )}
                </CopyButton>
              </Paper>
            </Flex>
            <Flex
              align={{ base: 'start', xs: 'center' }}
              direction={{ base: 'column', xs: 'row' }}
              justify={{ base: 'start', xs: 'space-between' }}
            >
              <Text fz="1rem" fw={600}>
                Private Website Preview
              </Text>
              <Link
                style={{ alignSelf: 'flex-end' }}
                target="_blank"
                to="https://support.globallinkgo.com/article/141-what-is-private-mode"
              >
                <UnstyledButton
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Text
                    fz="0.9rem"
                    fw={500}
                    c="text.8"
                    mr="0.75rem"
                    td="underline"
                    span
                  >
                    Need Help?
                  </Text>
                  <MdHelp fill={colors.icon[0]} size={20} />
                </UnstyledButton>
              </Link>
            </Flex>
            <Text>
              Go to your private website preview. Remember, your translations
              will not be publicly visible until you’re ready. Go to your
              webpage using the link below and confirm that the language
              selector appears on your site.
            </Text>

            <Link
              target="_blank"
              to={previewDomain}
              style={{ alignSelf: 'center' }}
            >
              <Button
                w="9rem"
                mih="2.75rem"
                bg="background2.2"
                radius="xl"
                size="md"
              >
                Preview
              </Button>
            </Link>

            <Text>
              Once you see the selector, choose from your available languages
              and the page you’re on will be translated instantly. You can edit
              translations and adjust other settings from your dashboard.
              <Text span fw={700}>
                &nbsp;NOTE: You can add /?glgo_preview=1 to the end of any url
                to enable preview mode.
              </Text>
            </Text>
          </Flex>
        </Paper>
      </Card>
    </>
  );
};

export default Injection;
