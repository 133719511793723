import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Card,
  Divider,
  Grid,
  Progress,
  ScrollArea,
  Text,
  Tooltip,
} from '@mantine/core';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { NoDataFoundPlaceHolder } from '../../Generic';

type Props = {
  worldTraffic: Array<{
    count: number;
    country_name: string;
    label: string;
  }>;
  loading: boolean;
  timeSlice: string;
};

export const CountryTable = ({ worldTraffic, loading, timeSlice }: Props) => {
  const totalTrafficCount = worldTraffic.reduce(
    (accum, { count = 0 }) => accum + count,
    0
  );

  const calculateTrafficPercentage = useCallback(
    (count: number) => {
      if (totalTrafficCount > 0) {
        return Math.round((count / totalTrafficCount) * 100);
      }
      return 0;
    },
    [totalTrafficCount]
  );

  const calculateTimeString = () => {
    switch (timeSlice) {
      case '1 day': {
        return 'day';
      }
      case '1 month': {
        return 'month';
      }
      case '1 year': {
        return 'year';
      }
      default: {
        return timeSlice + 's';
      }
    }
  };

  const tableData = useMemo(() => {
    return worldTraffic
      .filter(({ country_name }) => country_name)
      .map(({ country_name, count }) => ({
        country: country_name,
        visits: count,
        percent: calculateTrafficPercentage(count),
      }))
      .sort((a, b) => b.visits - a.visits);
  }, [calculateTrafficPercentage, worldTraffic]);

  const CountryTableRows = () => {
    if (loading) {
      return <LoadingSkeleton height={450} />;
    }

    if (tableData.length === 0) {
      return <NoDataFoundPlaceHolder height={450} />;
    }

    return (
      <>
        {tableData.map((element, index) =>
          element.percent >= 1 ? (
            <Box w="100%" key={element.country || index}>
              {index !== 0 && index !== tableData.length ? (
                <Divider
                  w="100%"
                  orientation="horizontal"
                  size="xs"
                  color="divider.2"
                />
              ) : (
                <React.Fragment key={index} />
              )}
              <Grid align="center" display="flex" py="0.7rem">
                <Grid.Col span={{ base: 7, sm: 6 }}>
                  <Tooltip
                    events={{ hover: false, touch: true, focus: false }}
                    label={element.country}
                  >
                    <Text fw={400} fz="1rem" truncate>
                      {element.country}
                    </Text>
                  </Tooltip>
                </Grid.Col>
                <Grid.Col span={{ base: 5, sm: 2 }}>
                  <Tooltip
                    events={{ hover: true, touch: true, focus: false }}
                    label={element.visits.toLocaleString()}
                  >
                    <Text fw={700} fz="1rem" truncate>
                      {element.visits.toLocaleString()}
                    </Text>
                  </Tooltip>
                </Grid.Col>
                <Grid.Col
                  display={{ base: 'none', sm: 'block' }}
                  span={{ base: 0, sm: 'auto' }}
                >
                  <Tooltip
                    position="top"
                    events={{ hover: true, touch: true, focus: false }}
                    label={`This is the percentage of total traffic to your site from ${element.country} over the past ${calculateTimeString()}.`}
                  >
                    <Progress value={element.percent} w="100%" />
                  </Tooltip>
                </Grid.Col>
                <Grid.Col
                  display={{ base: 'none', sm: 'block' }}
                  span={{ base: 0, sm: 1 }}
                >
                  <Tooltip
                    position="top"
                    events={{ hover: true, touch: true, focus: false }}
                    label={`This is the percentage of total traffic to your site from ${element.country} over the past ${calculateTimeString()}.`}
                  >
                    <Text fw={700} fz="1rem">
                      {element.percent}%
                    </Text>
                  </Tooltip>
                </Grid.Col>
              </Grid>
            </Box>
          ) : (
            <React.Fragment key={index} />
          )
        )}
      </>
    );
  };

  return (
    <Card p={0}>
      <Grid w="100%" m={0} py={10} bg="background2.5">
        <Grid.Col pl={30} span={{ base: 7, sm: 6 }}>
          <Text fw={700} fz="1rem" p={8}>
            COUNTRY
          </Text>
        </Grid.Col>
        <Grid.Col span={{ base: 5, sm: 6 }}>
          <Text fw={700} fz="1rem" py={8}>
            VISITS
          </Text>
        </Grid.Col>
      </Grid>
      <ScrollArea
        w="100%"
        mt={5}
        styles={{
          viewport: {
            '& > div': {
              display: 'block !important',
            },
          },
        }}
        type="always"
        offsetScrollbars
      >
        <Box
          h={450}
          w="100%"
          pl={30}
          pr={20}
          data-testid="pw-metrics-world-traffic-card-2"
        >
          <CountryTableRows />
        </Box>
      </ScrollArea>
    </Card>
  );
};

export default CountryTable;
