import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { Xapis, ProjectResponse, getUserKey } from '../xapis-wrappers/xapis';
import {
  createTranslationMap,
  decodeProjectId,
  isValidKey,
  filterTranslations,
  getYYTarget,
  isSuccessStatus,
} from 'helpers';
import { useGLGOParams } from 'hooks';
import { xapisEnv } from '../service-wrappers/xapisService';
import {
  createProjectUserPermissionMap,
  UserRole,
} from 'helpers/utils/projectPermissions';
import {
  Subscription,
  fetchSubscriptionsPromise,
} from '../PaySubscriptionStore';

export const projectQuery = (key: string) => {
  const projectKey = isValidKey(key) ? key : decodeProjectId(key);

  return queryOptions({
    queryKey: ['project', projectKey],
    staleTime: 1000 * 60, // shorter stale time for actively used projects
    queryFn: () => getProjectData(projectKey),
  });
};

async function getProjectData(projectKey: string) {
  if (!projectKey) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const response: ProjectResponse = await Xapis.Project.get(projectKey, true);

  if (!response || !isSuccessStatus(response.status) || !response.project!) {
    throw new Error('Failed to fetch project data');
  }
  const { payKey } = xapisEnv.getHost;
  const { project } = response;

  const projectTranslations = filterTranslations(project?.translations || []);
  const yyTranslation = getYYTarget(project?.translations || []);

  const projectTranslationMap = createTranslationMap(project);
  const projectUserPermissionMap = createProjectUserPermissionMap(
    project,
    projectTranslationMap
  );

  const isUserRole = (userRole: UserRole): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].rolePermissionSet.has(userRole);
  const isTranslationRole = (translationKey: string): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].languagePermissionSet.has(
      translationKey
    );

  const data = {
    isTranslationRole,
    isUserRole,
    projectTranslationMap,
    projectUserPermissionMap,
    subscriptions: [] as Subscription[],
    translations: projectTranslations,
    yyTranslation,
    project,
  };

  try {
    const subscriptionsRes = await fetchSubscriptionsPromise(
      payKey,
      projectKey
    );
    const { subscriptions = [] } = subscriptionsRes?.data || {};
    data.subscriptions = subscriptions;
  } catch (error) {
    console.error('subs fetch error inside projectLoader', error);
  }

  return data;
}

/**
 * A custom hook that fetches and returns the current project data based on the project key from the route.
 *
 * @throws {Error} If the projectKey is not present in the route params.
 * @returns {Awaited<ReturnType<typeof getProjectData>>} The current project data.
 */
export function useCurrentProjectData() {
  const { projectKey } = useGLGOParams();

  if (!projectKey) {
    throw new Error('projectKey is missing from route params');
  }

  const { data: projectData } = useSuspenseQuery(projectQuery(projectKey));
  return projectData;
}
