import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { notifications } from '@mantine/notifications';
import { xapisEnv } from 'store';

const isGLGODesktop = import.meta.env.VITE_IS_GLGO_DESKTOP === true;

export const useGLGOAuth = () => {
  const auth = useAuth();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<{
    access_token: string;
    profile: { email: string };
    state?: { path: string };
  } | null>(null);

  function processTokenStatus(tokenStatus: string) {
    // console.log('tokenStatus', tokenStatus.substring(0, 45) + '...');
    sessionStorage.setItem('TOKEN_STATUS', tokenStatus);
    setIsLoading(tokenStatus === 'checking');
    if (
      tokenStatus === 'invalid' &&
      !auth.isAuthenticated &&
      !auth.activeNavigator
    ) {
      setIsAuthenticated(false);
      auth.signinRedirect().catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error.toString());
      });
    } else if (tokenStatus.startsWith('valid')) {
      setIsAuthenticated(true);
      xapisEnv.setToken(tokenStatus.split(' ')[2]);
      setUser({
        access_token: tokenStatus.split(' ')[2],
        profile: {
          email: tokenStatus.split(' ')[1],
        },
      });
    }
  }

  useEffect(() => {
    if (isGLGODesktop) {
      if (!window.UserManager) {
        throw Error(
          'running inside GLGO Desktop but missing window.UserManager'
        );
      }

      const { onTokenStatus } = window.UserManager;
      onTokenStatus(processTokenStatus);
      const sessionTokenStatus = sessionStorage.getItem('TOKEN_STATUS');
      if (sessionTokenStatus) processTokenStatus(sessionTokenStatus);
    } else {
      /* addAccessTokenExpiring() returns a callback:
       ** Return the event and its callback / cleanup function
       * Documentation: https://github.com/authts/react-oidc-context#documentation
       */
      return auth.events.addAccessTokenExpiring(() => {
        auth
          .signinSilent()
          .then((userData) => {
            if (userData === null) {
              auth.signoutRedirect().finally(() => {
                notifications.show({
                  message: 'Unable to renew user authentication at this time.',
                  color: 'red',
                });
              });
              return;
            }
            xapisEnv.setToken(userData.access_token || 'InvalidToken');
          })
          .catch((error) => {
            auth.signoutRedirect().finally(() => {
              console.error('Auth renewal error:', error);
              notifications.show({
                message: 'Unable to renew user authentication at this time.',
                color: 'red',
              });
            });
          });
      });
    }
  }, []);

  return isGLGODesktop
    ? {
        isAuthenticated,
        isLoading,
        error: null,
        user,
        signinRedirect: async () => {},
        signoutRedirect: async () => {},
        signinSilent: async () => {},
      }
    : auth;
};
