import React from 'react';
import { Box, Divider, Flex, Paper, Text, UnstyledButton } from '@mantine/core';
import { FaChevronLeft } from 'react-icons/fa6';
import classes from './MobileNotificationsPaper.module.css';

type Props = {
  notifications: Array<{ isNew: boolean; text: string; time: string }>;
  setShowNotificationsMenu: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void;
};

const MobileNotificationsPaper = ({
  notifications,
  setShowNotificationsMenu,
}: Props) => {
  return (
    <>
      <Box h={5} bg="background.5" />
      <Paper
        bg="white"
        c="background.5"
        mt="0.7rem"
        pt="0.95rem"
        radius={0}
        w="100%"
      >
        <Flex direction="column">
          <Flex
            align="center"
            gap={10}
            onClick={() => setShowNotificationsMenu(false)}
            p="0rem 1rem 0.5rem 1rem"
            style={{ cursor: 'pointer' }}
          >
            <UnstyledButton>
              <FaChevronLeft color="background.5" size={20} />
            </UnstyledButton>
            <Text fw={700} fz="1.35rem" mb="0.25rem">
              Notifications
            </Text>
          </Flex>
          <Paper
            shadow="xs"
            mah="70vh"
            radius="lg"
            style={{
              borderBottom: '0.0625rem solid #dee2e6',
              overflow: 'scroll',
            }}
          >
            {notifications.map(({ isNew, text, time }, index) => (
              <Box className={classes.notifBox} key={text}>
                <Flex
                  gap={15}
                  p="1rem"
                  style={{
                    borderTopLeftRadius: index === 0 ? '1rem' : 0,
                    borderTopRightRadius: index === 0 ? '1rem' : 0,
                  }}
                >
                  <Box w="20%">
                    <Text fw={700} fz="0.75rem">
                      {isNew && 'NEW'}
                    </Text>
                  </Box>
                  <Box w="60%">
                    <Text
                      fw={600}
                      fz=".75rem"
                      pr={{ base: '3rem', sm: '2.5rem' }}
                    >
                      {text}
                    </Text>
                  </Box>
                  <Box w="20%">
                    <Text fw={700} fz=".625rem">
                      {time}
                    </Text>
                  </Box>
                </Flex>
                {index !== notifications.length - 1 && (
                  <Divider color="#EEEEEE" />
                )}
              </Box>
            ))}
          </Paper>
        </Flex>
      </Paper>
    </>
  );
};

export default MobileNotificationsPaper;
