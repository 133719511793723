import React, { useState } from 'react';
import {
  Anchor,
  Flex,
  Text,
  UnstyledButton,
  ScrollArea,
  useMantineTheme,
} from '@mantine/core';
import { IoMdAdd } from 'react-icons/io';
import PaymentMethods from './PaymentMethods';
import { dashboardRC } from 'helpers';
import { handleAddCardButtonClick } from './planActions';
import { PaymentSource, useUserProvider } from 'store';

type Props = {
  default_source_id: string;
  sources: PaymentSource[];
  cards: PaymentSource[];
  setCards: React.Dispatch<React.SetStateAction<PaymentSource[]>>;
  openPayModal: () => void;
};

const ManagePaymentMethods = ({
  default_source_id,
  sources,
  cards,
  setCards,
  openPayModal,
}: Props) => {
  const { colors } = useMantineTheme();

  const { phone } = useUserProvider().xapisUser;

  const [defaultSourceId, setDefaultSourceId] = useState(default_source_id);

  return (
    <>
      <Flex justify="space-between" align="center" columnGap="1rem" mb="1.5rem">
        <Text tt="uppercase" fw={700} size="1.25rem" truncate>
          Payment Method
        </Text>
        {sources.length > 0 && (
          <UnstyledButton
            variant="subtle"
            onClick={() => handleAddCardButtonClick(phone || '', openPayModal)}
          >
            <IoMdAdd color={colors.text[8]} size="1.75rem" />
          </UnstyledButton>
        )}
      </Flex>
      <ScrollArea type="auto" offsetScrollbars h={200}>
        <PaymentMethods
          cards={cards}
          setCards={setCards}
          defaultSourceId={defaultSourceId}
          setDefaultSourceId={setDefaultSourceId}
        />
      </ScrollArea>
      <Text size="0.85rem" ta="center" mt="0.75rem" lh="1.35rem">
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mr="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayDRAboutDigitalRiverPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Digital River Inc.
        </Anchor>
        is the authorized reseller and merchant of the products and services
        offered within this store. <br />
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mx="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayDRPrivacyPolicyPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Privacy Policy
        </Anchor>{' '}
        |
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mx="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayDRTermsAndConditionsPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Terms of Sale
        </Anchor>{' '}
        |
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mx="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayCCPAPage`}
        >
          Your California Policy Rights
        </Anchor>{' '}
        |
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mx="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayDRCookiesPolicyPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Cookies
        </Anchor>{' '}
        |
        <Anchor
          c="text.2"
          fw={600}
          td="underline"
          mx="0.2rem"
          target="_blank"
          href={`${dashboardRC.DR_STORE}/DisplayDRContactInformationPage/eCommerceProvider.Digital%20River%20Inc.`}
        >
          Legal Notice
        </Anchor>
      </Text>
    </>
  );
};

export default ManagePaymentMethods;
