import React from 'react';
import { Box, Card, Flex, ScrollArea } from '@mantine/core';
import TranslationStatusHeader from './TranslationStatusHeader';
import InactiveTranslationItem from './InactiveTranslationItem';

type Props = {
  targets: TranslationKey[];
  inactiveTranslations: Partial<TranslationKey>[];
  addInactiveTranslation: (targetLangCode: string) => void;
};
export const InactiveTranslations = ({
  targets,
  inactiveTranslations,
  addInactiveTranslation,
}: Props) => {
  return (
    <>
      <TranslationStatusHeader
        statusTitle={`Inactive (${inactiveTranslations.length.toLocaleString()})`}
        status="inactive"
      />
      <Card
        mt={20}
        p={0}
        pt={2}
        data-testid="pw-project-settings-manage-languages-table-inactive"
      >
        <ScrollArea type="always" offsetScrollbars>
          <Flex mah={180} py={5} px={15} direction="column">
            {inactiveTranslations.map((translation, index) => (
              <Box
                pb={index === inactiveTranslations.length - 1 ? 10 : 0}
                key={index}
              >
                <InactiveTranslationItem
                  translation={translation}
                  sourceLangName={targets[0]?.source_lang_name || ''}
                  addInactiveTranslation={addInactiveTranslation}
                  isLastItem={index === inactiveTranslations.length - 1}
                />
              </Box>
            ))}
          </Flex>
        </ScrollArea>
      </Card>
    </>
  );
};

export default InactiveTranslations;
