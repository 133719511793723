import React, { useMemo } from 'react';
import { Container, Flex, Text, useMantineTheme } from '@mantine/core';
import LoadingSkeleton from './LoadingSkeleton';
import { TranslatedTrafficTimeslice } from 'store';
import { compactFormatter } from 'helpers';
import { CustomTooltip, NoDataFoundPlaceHolder } from '../../Generic';
import styles from './Dashboard.styles';
import { AccumulatedTranslatedTraffic } from './types';
import { TranslatedTrafficChart } from './TranslatedTrafficChart';
import { useGetNavigationStatus } from 'hooks';

type Props = {
  prevTimeSlices: TranslatedTrafficTimeslice[];
  timeSlices: TranslatedTrafficTimeslice[];
};

const TranslatedTraffic = ({ prevTimeSlices, timeSlices }: Props) => {
  const theme = useMantineTheme();
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const tooltipLabel =
    'Translated traffic represents the number of page views to one of your translated pages for the language and time period specified. ' +
    'Every time someone loads a page in a translated language, that counts as one page view.';

  const accumulatedTranslatedTraffic: AccumulatedTranslatedTraffic[] =
    useMemo(() => {
      return timeSlices.reduce(
        (
          accum: AccumulatedTranslatedTraffic[],
          { num_requests, timeslice }
        ) => {
          const prevTimesliceFound = accum.find(
            (accumTranslatedTraffic) =>
              accumTranslatedTraffic.timeslice === timeslice
          );
          if (prevTimesliceFound) {
            const timesliceIndex = accum.findIndex(
              ({ timeslice: prevTimeslice }) => prevTimeslice === timeslice
            );
            if (timesliceIndex !== -1) {
              accum[timesliceIndex] = {
                ...prevTimesliceFound,
                numRequests: prevTimesliceFound.numRequests + num_requests,
              };
            }
          } else accum.push({ numRequests: num_requests, timeslice });
          return accum;
        },
        []
      );
    }, [timeSlices]);

  const convertedTranslatedTraffic = useMemo(() => {
    return accumulatedTranslatedTraffic.map(
      ({ timeslice = '', numRequests = '' }) => {
        return { timeslice, numRequests: Number(numRequests) };
      }
    );
  }, [accumulatedTranslatedTraffic]);

  const convertedPrevTranslatedTraffic = useMemo(() => {
    return prevTimeSlices.map(({ timeslice = '', num_requests = '' }) => {
      return { timeslice, numRequests: Number(num_requests) };
    });
  }, [prevTimeSlices]);

  const totalTranslatedTraffic = useMemo(() => {
    return (
      Math.round(
        convertedTranslatedTraffic.reduce(
          (accum, { numRequests = 0 }) => accum + numRequests,
          0
        ) as number
      ) || 0
    );
  }, [convertedTranslatedTraffic]);

  const totalPrevTranslatedTraffic = useMemo(() => {
    return (
      Math.round(
        convertedPrevTranslatedTraffic.reduce(
          (accum, { numRequests = 0 }) => accum + numRequests,
          0
        ) as number
      ) || 0
    );
  }, [convertedPrevTranslatedTraffic]);

  const translatedTrafficChange =
    (totalTranslatedTraffic &&
      totalPrevTranslatedTraffic &&
      Math.round(
        (Math.abs(totalTranslatedTraffic - totalPrevTranslatedTraffic) /
          totalPrevTranslatedTraffic) *
          100
      )) ||
    0;
  const hasNegativeChange =
    totalTranslatedTraffic - totalPrevTranslatedTraffic < 0;
  const noTrafficChange =
    !translatedTrafficChange || translatedTrafficChange === 0;

  const TranslatedTrafficChartWrapper = () => {
    if (loading) {
      return <LoadingSkeleton height={180} />;
    }

    if (timeSlices.length === 0) {
      return <NoDataFoundPlaceHolder height={180} />;
    }

    return (
      <Flex w="100%" columnGap={20} align="center">
        <div>
          <Text fw={700} fz={50}>
            {compactFormatter(totalTranslatedTraffic)}
          </Text>
          {!noTrafficChange && (
            <Text className={styles.percentageChange(theme, hasNegativeChange)}>
              {hasNegativeChange
                ? `-${translatedTrafficChange}%`
                : `+${translatedTrafficChange}%`}
            </Text>
          )}
        </div>
        {accumulatedTranslatedTraffic.length > 0 && (
          <TranslatedTrafficChart
            convertedTranslatedTraffic={convertedTranslatedTraffic}
          />
        )}
      </Flex>
    );
  };

  return (
    <>
      <CustomTooltip tooltipLabel={tooltipLabel}>
        <Text
          w={180}
          fz={14}
          fw={700}
          data-testid="pw-ms-translated-traffic-title"
        >
          TRANSLATED TRAFFIC
        </Text>
      </CustomTooltip>
      <Container p={0} mt={20} data-testid="pw-ms-translated-traffic-container">
        <TranslatedTrafficChartWrapper />
      </Container>
    </>
  );
};

export default TranslatedTraffic;
