import * as React from 'react';
import classes from './Generic.module.css';
import {
  Badge,
  Box,
  Button,
  Container,
  CloseButton,
  Divider,
  Flex,
  Input,
  Tooltip,
  useMantineTheme,
  Text,
  Avatar,
  TooltipProps,
  PolymorphicComponentProps,
  FlexProps,
} from '@mantine/core';
import { InfoIcon } from '../../StaticIcons';
import { useMediaQuery } from '@mantine/hooks';
import { NoDataFoundIcon } from 'images';
import { MdSearch } from 'react-icons/md';
import { formatAvatarInitials } from './dashboard/goheader/utils';

export const ActionButton = ({ title }: { title: React.ReactNode }) => (
  <Button style={{ display: 'flex', margin: '20px auto 0 auto' }}>
    {title}
  </Button>
);

// Replaces Mantine's Card component to improve responsiveness
// Accepts any Flex component props
export const FlexCard = (
  props: PolymorphicComponentProps<'div', FlexProps>
) => {
  const { children, className, ...others } = props;
  const classNames = className
    ? `${classes.flexCard} ${className}`
    : classes.flexCard;
  return (
    <Flex className={classNames} {...others}>
      {children}
    </Flex>
  );
};

export const NoDataFoundPlaceHolder = ({
  height = 0,
}: {
  height?: number | string;
}) => (
  <Flex h={height} align="center" justify="center">
    <NoDataFoundIcon name="no-data" />
  </Flex>
);

type CustomTooltipProps = {
  tooltipLabel: React.ReactNode;
  children: React.ReactNode;
  noArrow?: boolean;
  offset?: TooltipProps['offset'];
  position?: TooltipProps['position'];
  events?: {
    hover: boolean;
    touch: boolean;
    focus: boolean;
  };
  disabled?: boolean;
};

export const CustomTooltip = ({
  tooltipLabel,
  children,
  noArrow,
  offset,
  position,
  events = { hover: true, touch: true, focus: true },
  disabled,
}: CustomTooltipProps) => (
  <Tooltip
    disabled={disabled}
    maw={250}
    withinPortal
    offset={offset || 0}
    arrowSize={8}
    events={events}
    position={position || 'bottom'}
    withArrow={!noArrow}
    label={
      typeof tooltipLabel === 'string' ? (
        <p style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
          {tooltipLabel}
        </p>
      ) : (
        tooltipLabel
      )
    }
  >
    {children}
  </Tooltip>
);

export const BasicHorizontalDivider = ({
  mobileMargin,
  borderWidth,
}: {
  mobileMargin?: string | number;
  borderWidth?: number;
}) => (
  <Divider
    orientation="horizontal"
    className={classes.divider}
    m={{ base: mobileMargin ?? '0 20px', sm: 'auto' }}
    style={{ borderWidth: borderWidth ?? 1 }}
  />
);

export type CardHeaderType = {
  title: React.ReactNode;
  tooltipLabel?: string;
  flexItems?: React.ReactElement | React.ReactNode;
  itemsType?: 'filter' | 'icon' | 'button';
  justifyItems?: 'flex-start' | 'flex-end' | 'center';
  blur?: boolean;
};

export const CardHeader = ({
  title,
  tooltipLabel,
  flexItems,
  itemsType,
  justifyItems,
  blur,
}: CardHeaderType) => {
  const isMobile = useMediaQuery('(max-width: 575px)');
  const getWidth =
    (itemsType === 'filter' && isMobile) || (isMobile && !flexItems)
      ? '100%'
      : '50%';
  const wrapItems = getWidth === '100%' ? 'wrap' : 'nowrap';

  if (tooltipLabel !== undefined) {
    return (
      <Flex
        style={{ filter: blur ? 'blur(1.25px)' : 'none' }}
        mih={56}
        wrap={wrapItems}
        w="100%"
        align="center"
      >
        <Flex w={getWidth} gap={5}>
          {typeof title === 'string' ? (
            <Text lineClamp={2} fw={700} style={{ wordBreak: 'break-word' }}>
              {title}&nbsp;
            </Text>
          ) : (
            title
          )}
          <CustomTooltip disabled={blur} tooltipLabel={tooltipLabel}>
            <Box display="inline-block">
              <InfoIcon fill="#2C49AC" size={15} />
            </Box>
          </CustomTooltip>
        </Flex>
        <Flex
          w={getWidth}
          justify={isMobile && justifyItems ? justifyItems : 'end'}
        >
          {flexItems !== undefined && flexItems}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex
      style={{ filter: blur ? 'blur(1.25px)' : 'none' }}
      wrap={wrapItems}
      w="100%"
      align="center"
    >
      <Box w={getWidth}>
        {typeof title === 'string' ? <Text fw={700}>{title}</Text> : title}
      </Box>
      <Flex
        w={getWidth}
        justify={isMobile && justifyItems ? justifyItems : 'flex-end'}
      >
        {flexItems !== undefined && flexItems}
      </Flex>
    </Flex>
  );
};

export const DashboardCard = ({
  noPadding,
  children,
}: {
  noPadding?: string;
  children: React.ReactNode;
}) => {
  const getPadding = () => {
    let padding;
    switch (true) {
      case noPadding === 'left':
        padding = '20px 20px 20px 0';
        break;
      case noPadding === 'right':
        padding = '20px 0 20px 20px';
        break;
      default:
        padding = '20px 0 20px 0';
        break;
    }
    return padding;
  };

  return (
    <Box maw="100%" w="100%" p={{ base: '20px 0', lg: getPadding() }}>
      {children}
    </Box>
  );
};

export const BlueCardHeader = ({ children }: { children: React.ReactNode }) => (
  <Container maw="100%" py={20} px={20} bg="background2.5" w="100%">
    {children}
  </Container>
);

export const StatusCircleIcon = ({
  status,
  size,
  borderSize,
  float,
}: {
  status: 'live' | 'active' | 'inactive' | 'private';
  size?: string | number;
  borderSize?: number;
  float?: 'right';
}) => {
  const theme = useMantineTheme();

  const getColor = (property: string) => {
    let color;
    if (property === 'backgroundColor') {
      switch (true) {
        case status === 'live':
          color = theme.colors.background2[1];
          break;
        case status === 'active':
          color = theme.colors.background2[2];
          break;
        case status === 'inactive':
          color = theme.colors.background2[3];
          break;
        case status === 'private':
        default:
          color = theme.colors.background2[5];
          break;
      }
      return color || '#707070';
    }
    switch (true) {
      case status === 'live':
        color = theme.colors.border[3];
        break;
      case status === 'active':
        color = theme.colors.border2[1];
        break;
      case status === 'inactive':
        color = theme.colors.border2[2];
        break;
      case status === 'private':
      default:
        color = theme.colors.border[2];
        break;
    }
    return color || '#C8DFF8';
  };

  return (
    <Box w={size ? Number(size) : 12}>
      <Box
        style={{
          width: size || 12,
          height: size ? Number(size) : 12,
          backgroundColor: getColor('backgroundColor'),
          border: `${borderSize || 2}px solid ${getColor('border')}`,
          borderRadius: 50,
          float: float || 'none',
        }}
      />
    </Box>
  );
};

export const StatusBadge = ({ isLive }: { isLive: boolean }) => (
  <Badge
    w={80}
    bg={isLive ? 'badge.2' : 'badge.8'}
    c={isLive ? 'text.5' : 'text2.7'}
    py={10}
  >
    {isLive ? 'Live' : 'Private'}
  </Badge>
);

export const LanguageBadge = ({
  targetLangCode,
  isLive,
}: {
  targetLangCode: string;
  isLive: boolean;
}) => {
  const { colors } = useMantineTheme();

  return (
    <Tooltip label={targetLangCode.length > 5 && targetLangCode}>
      <Badge
        variant="outline"
        radius="sm"
        className={classes.languageBadge}
        style={{ color: isLive ? colors?.badge[3] : colors?.badge[1] }}
      >
        {targetLangCode}
      </Badge>
    </Tooltip>
  );
};

export const SearchBar = ({
  maxWidth,
  searchText,
  setSearchText,
  setPrevSearchText = () => {},
}: {
  maxWidth: string | number;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setPrevSearchText?: React.Dispatch<React.SetStateAction<string>>;
}) => (
  <Input
    w="100%"
    maw={maxWidth}
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={<MdSearch color="#00328D" size={20} />}
    placeholder="Search..."
    onChange={(e) => {
      setPrevSearchText(searchText);
      setSearchText(e?.target?.value || '');
    }}
  />
);

export const SearchInput = ({
  maxWidth,
  searchText,
  setSearchText,
}: {
  maxWidth: string | number;
  searchText: string;
  setSearchText: (value: string) => void;
}) => (
  <Input
    w="100%"
    maw={maxWidth}
    value={searchText}
    classNames={{
      input: classes.searchBarInput,
      section: classes.searchBarRightSection,
    }}
    rightSection={
      searchText ? (
        <CloseButton onClick={() => setSearchText('')} />
      ) : (
        <MdSearch color="#00328D" size={20} />
      )
    }
    rightSectionPointerEvents="all"
    placeholder="Search..."
    onChange={(e) => {
      setSearchText(e?.target?.value || '');
    }}
  />
);

export const StatusBanner = ({
  statusBody,
  statusType,
}: {
  statusBody: React.ReactNode;
  statusType: 'translations' | 'subscription';
}) => (
  <Flex
    w="100%"
    mt={{ base: '0.6rem', lg: '0.5rem' }}
    h="4rem"
    align="center"
    justify="center"
    bg={statusType === 'translations' ? '#FDF5B2' : '#F5DDDE'}
    py="1rem"
    px={{ base: '1rem', sm: '2rem' }}
    pos="sticky"
  >
    <Box fw={500} ta="center">
      {statusBody}
    </Box>
  </Flex>
);

export const CircleAvatar = ({
  name,
  format = true,
  circleSize,
  backgroundColor,
  textColor,
  fontSize,
}: {
  name: string | undefined;
  format?: boolean;
  circleSize?: number | string;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: number | string;
}) => (
  <Avatar
    variant="filled"
    size={circleSize || 32}
    color={backgroundColor || 'background.5'}
    radius="50%"
  >
    <Text fw={700} c={textColor || 'text.0'} fz={fontSize || '0.85rem'}>
      {name ? (format ? formatAvatarInitials(name || 'P') : name) : 'P'}
    </Text>
  </Avatar>
);

export const CloseIcon = <
  FnType extends React.MouseEventHandler<HTMLButtonElement>,
>({
  onClick,
  size,
  color,
}: {
  onClick: FnType;
  size?: string | number;
  color?: string;
}) => (
  <CloseButton
    onClick={onClick}
    c={color || 'text.0'}
    bg="transparent"
    size={size || 35}
  />
);
