import React, { useCallback, useMemo } from 'react';
import { Box, Text } from '@mantine/core';
import { CardHeader } from '../../Generic';
import { MetricsFilters } from '../selectors-filters/MetricsFilters';
import { getTimeSliceOptions } from '../utils';
import TranslatedTrafficTable from './TranslatedTrafficTable';
import TranslatedTrafficChart from './TranslatedTrafficChart';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { filterTargets, updateDateParam } from './trafficUtils';
import {
  ProjectResponse,
  TranslatedTrafficTimeslice,
  TranslatedTrafficUrl,
} from 'store';
import { QueryMultiSelector } from '../selectors-filters/QueryMultiSelector';
import DateFilter from '../selectors-filters/DateFilter';
import { useGetNavigationStatus } from 'hooks';

export type MetricsLoaderData = ProjectResponse & {
  targets: TranslationKey[];
  translationKeys: string[];
  timeSlices: TranslatedTrafficTimeslice[];
  urls: TranslatedTrafficUrl[];
  timeSliceFrom: string;
  worldTraffic: Array<{
    count: number;
    country_name: string;
    label: string;
  }>;
};

export const TranslatedTraffic = () => {
  const { targets, timeSlices, urls, timeSliceFrom } = useRouteLoaderData(
    'metrics'
  ) as MetricsLoaderData;
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const filteredTargets = useMemo(() => filterTargets(targets), [targets]);
  const filteredTranslationKeys = useMemo(
    () => filteredTargets.map(({ value }) => value),
    [filteredTargets]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedDate = useCallback(
    (value: string) => {
      updateDateParam(value, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box w="100%" mt={30}>
      <CardHeader
        title={
          <Text className="headerTitle" span>
            Translated Traffic
          </Text>
        }
        tooltipLabel="The chart and table below show details about translated traffic - i.e., visits to your website in one of your translation languages."
        flexItems={
          <MetricsFilters>
            <QueryMultiSelector
              encodeKeys
              selectAll
              shouldRevalidate
              options={filteredTargets}
            />
            <DateFilter
              timeSliceOptions={getTimeSliceOptions()}
              timeSliceFrom={timeSliceFrom}
              onSelect={handleSelectedDate}
            />
          </MetricsFilters>
        }
        itemsType="filter"
        justifyItems="flex-start"
      />
      <div data-testid="pw-translated-traffic-card-1">
        <TranslatedTrafficChart
          timeSliceFrom={timeSliceFrom}
          timeSlices={timeSlices}
          loading={loading}
        />
      </div>
      <TranslatedTrafficTable
        translationKeys={filteredTranslationKeys}
        urls={urls}
        loading={loading}
      />
    </Box>
  );
};

export default TranslatedTraffic;
