import { Xapis } from '../../xapis-wrappers/xapis';
import { Params } from 'react-router-dom';
import { decodeProjectId } from 'helpers';

export async function accountLoader({ params }: { params: Params<string> }) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const data = await Xapis.Project.get(projectKey);

  return data.project;
}

export type AccountLoaderResponse = Awaited<ReturnType<typeof accountLoader>>;
