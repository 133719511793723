import { RouteObject } from 'react-router-dom';
import { billingLoader, selectPlanLoader, usageLoader } from 'store';
import {
  BillingInformation,
  CancelPlan,
  ErrorBoundary,
  MyProfile,
  SelectPlan,
  WordsServedUsageDetails,
  editProfileAction,
} from 'ui';
import { dashboardRC } from 'helpers';

// type RouteObjectWithRequiredPath = Omit<RouteObject, 'path'> & { path: string };

export const routes: RouteObject[] = [
  {
    path: 'my-profile',
    element: <MyProfile />,
    errorElement: <ErrorBoundary />,
    action: editProfileAction,
  },
  {
    path: 'words-served-usage-details',
    id: 'usage',
    element: <WordsServedUsageDetails />,
    loader: usageLoader,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'billing-information',
    errorElement: <ErrorBoundary />,
    id: 'billing',
    loader: billingLoader,
    children: [
      {
        index: true,
        element: <BillingInformation />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'manage-subscription',
        children: [
          {
            index: true,
            element: <SelectPlan />,
            loader: selectPlanLoader,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'cancel',
            element: <CancelPlan />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },
    ],
  },
];

export const settings = {
  path: dashboardRC.PAGE_ACCOUNT_INFO,
  defaultTab: routes[0].path,
};
