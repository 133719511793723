import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import { Box, Button, Grid, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useGLGOParams } from 'hooks';
import { LanguageCodeResponse, ProjectLoaderResponse, Xapis } from 'store';
import { ActiveTranslations } from './ActiveTranslations';
import { InactiveTranslations } from './InactiveTranslations';
import { CardHeader } from '../../../Generic';
import AddLanguagesModal from './AddLanguagesModal';
import { SpecifyModal } from '../SpecifyModal';
import { IoMdAdd } from 'react-icons/io';

export const ManageLanguages = () => {
  const { projectKey = '' } = useGLGOParams();
  const { language_codes: languageCodes = [] } =
    useLoaderData() as LanguageCodeResponse;
  const { translations = [] } = useRouteLoaderData(
    'settings'
  ) as ProjectLoaderResponse;

  const [targets, setTargets] = useState(translations);
  const [showAddLanguagesModal, setShowAddLanguagesModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showSpecifyModal, setShowSpecifyModal] = useState(false);
  const [selectedTranslations, setSelectedTranslations] = useState<
    Partial<LanguageCode>[]
  >([]);

  useEffect(() => {
    setTargets(translations);
  }, [translations]);

  const updateTargets = useCallback(
    (updatedTarget: TranslationKey, selectedTKey: string) => {
      setTargets((prevTargets) => {
        return prevTargets.map((prevTarget) => {
          if (prevTarget.translation_key === selectedTKey) {
            return updatedTarget;
          }
          return prevTarget;
        });
      });
    },
    []
  );

  const sortedTargets = useMemo(() => {
    return targets.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [targets]);

  const availableTranslations = useMemo(() => {
    const targetLanguageNames = targets.map(
      ({ target_lang_name = '' }) => target_lang_name
    );
    return languageCodes.filter(
      ({ langcode_name = '' }) => !targetLanguageNames.includes(langcode_name)
    );
  }, [languageCodes, targets]);

  const inactiveTranslations = useMemo(() => {
    const translations = targets.filter(({ is_active = false }) => !is_active);

    return translations.sort((t1, t2) => {
      if (t1.target_lang_name < t2.target_lang_name) return -1;
      if (t1.target_lang_name > t2.target_lang_name) return 1;
      return 0;
    });
  }, [targets]);

  const addInactiveTranslation = useCallback(
    (selectedTranslationKey: string) => {
      const updateTargetPayload = {
        is_active: true,
      };
      Xapis.ProjectTranslation.put(
        projectKey,
        selectedTranslationKey,
        updateTargetPayload
      )
        .then(({ data: updatedTarget = {} }) => {
          if ('is_active' in updatedTarget) {
            updateTargets(updatedTarget, selectedTranslationKey);
            notifications.show({
              message: 'You have successfully added a translation.',
            });
          }
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to add a translation at this time.',
          });
        });
    },
    [projectKey, updateTargets]
  );

  const removeActiveTranslation = (selectedTranslationKey: string) => {
    if (projectKey && selectedTranslationKey) {
      Xapis.ProjectTranslation.put(projectKey, selectedTranslationKey, {
        is_active: false,
      })
        .then(({ data: updatedTarget = {} }) => {
          if ('is_active' in updatedTarget) {
            updateTargets(updatedTarget, selectedTranslationKey);
            notifications.show({
              message: 'You have successfully removed a translation.',
            });
          }
        })
        .catch(() => {
          notifications.show({
            message: 'Unable to remove a translation at this time.',
          });
        });
    } else {
      notifications.show({
        message: 'Unable to remove a translation at this time.',
      });
    }
  };

  const updateTranslationStatus = (
    selectedTranslationKey: string,
    isLive: boolean
  ) => {
    Xapis.ProjectTranslation.put(projectKey, selectedTranslationKey, {
      is_live: !isLive,
    })
      .then(({ data: updatedTarget = {} }) => {
        if ('is_active' in updatedTarget) {
          updateTargets(updatedTarget, selectedTranslationKey);
          notifications.show({
            message: 'You have successfully updated a translation.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Unable to update translation status at this time.',
        });
      });
  };

  return (
    <>
      {showSpecifyModal && (
        <SpecifyModal
          showSpecifyModal={showSpecifyModal}
          setShowSpecifyModal={setShowSpecifyModal}
          selectedTranslations={selectedTranslations}
          setSelectedTranslations={setSelectedTranslations}
        />
      )}
      {showAddLanguagesModal && (
        <AddLanguagesModal
          showSpecifyModal={showSpecifyModal}
          setShowSpecifyModal={setShowSpecifyModal}
          setShowAddLanguagesModal={setShowAddLanguagesModal}
          showAddLanguagesModal={showAddLanguagesModal}
          availableTranslations={availableTranslations}
          selectedTranslations={selectedTranslations}
          setSelectedTranslations={setSelectedTranslations}
        />
      )}
      <Box maw={{ base: '100%', md: '90%', lg: '75%', xl: '55%' }} mt={20}>
        <Grid w="100%">
          <Grid.Col>
            <CardHeader
              title={
                <Text
                  data-testid="pw-manage-languages-header"
                  className="headerTitle"
                  span
                >
                  My Languages
                </Text>
              }
              flexItems={
                <Button
                  data-testid="pw-language-add-language-button"
                  h={34}
                  fw={700}
                  bg="transparent"
                  c="text.8"
                  variant="subtle"
                  rightSection={
                    <Box onClick={() => setShowAddLanguagesModal(true)}>
                      <IoMdAdd size={20} />
                    </Box>
                  }
                  onClick={() => setShowAddLanguagesModal(true)}
                >
                  Add Language
                </Button>
              }
              itemsType="button"
              justifyItems="flex-end"
            />
            {targets.length > 0 ? (
              <ActiveTranslations
                targets={sortedTargets}
                removeActiveTranslation={removeActiveTranslation}
                updateTranslationStatus={updateTranslationStatus}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            ) : (
              <></>
            )}
            {inactiveTranslations.length > 0 ? (
              <InactiveTranslations
                targets={sortedTargets}
                inactiveTranslations={inactiveTranslations}
                addInactiveTranslation={addInactiveTranslation}
              />
            ) : (
              <></>
            )}
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};
