import React from 'react';
import { useAuth } from 'react-oidc-context';

const SilentRenew = () => {
  const auth = useAuth();

  if (auth.error) {
    console.error('auth.error: ', auth.error);
    return <div>Silent Renew: Oops... {auth.error.message}</div>;
  }
  return <div />;
};
export default SilentRenew;
