import { getDTDefaultEnvironment } from 'store';
const { ssoUrl } = getDTDefaultEnvironment();

export const desktopOidcConfig = {
  client_id: 'okXe98gEgCggcvOngSnIcl8SCzcl0P132',
  redirect_uri: `glwebstudio://post_login/`,
  response_type: 'code',
  scope: 'openid email clients GLWebStudio offline_access',
  authority: ssoUrl,
  post_logout_redirect_uri: 'glwebstudio://post_login/',
  automaticSilentRenew: false,
  revokeAccessTokenOnSignout: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  sessionTimeout: 900, // Registered session length 15 minutes
};
