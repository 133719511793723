import { Flex, Menu } from '@mantine/core';

import { notifications } from '@mantine/notifications';

import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { Xapis } from 'store';
import { GlossaryRuleForm } from './GlossaryRuleForm';
import { randomId } from '@mantine/hooks';
import { isSuccessStatus } from 'helpers';
import classes from './GlossaryItemMenu.module.css';

export const GlossaryItemMenu = ({ item }: { item: GlossaryItem }) => {
  const {
    comment,
    is_case_sensitive,
    is_mt,
    rule,
    source_text,
    source_text_hash,
    target_text,
    translation_key,
  } = item || {};

  const deleteGlossaryRule = (translation_key: string, ruleHash: string) => {
    Xapis.Glossary.delete(translation_key, ruleHash).then((res) => {
      if (isSuccessStatus(res.status)) {
        notifications.show({
          message: 'Glossary Rule Deleted.',
        });
      }
    });
  };

  const formInitialValues = {
    comment,
    is_case_sensitive,
    is_mt,
    rule,
    source_text,
    targetTextList:
      target_text?.map((t) => ({ targetText: t, id: randomId() })) || [],
    translation_keys: [translation_key],
  };

  return (
    <Menu
      width={80}
      shadow="sm"
      position="bottom-end"
      zIndex={20}
      keepMounted
      classNames={{
        dropdown: classes.dropdown,
        item: classes.item,
        itemLabel: 'wrapText',
      }}
    >
      <Menu.Target>
        <Flex c="background2.2" justify="center" style={{ cursor: 'pointer' }}>
          <HiOutlineDotsHorizontal color="text.8" size={24} />
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <GlossaryRuleForm
          buttonProps={{
            w: '100%',
            h: 'auto',
            variant: 'subtle',
            c: 'text.8',
            classNames: {
              label: 'wrapText',
            },
            styles: {
              root: {
                padding: '8px 10px',
              },
              label: {
                display: 'block',
              },
            },
          }}
          type="Edit"
          initialValues={formInitialValues}
        />
        <Menu.Item
          onClick={() => deleteGlossaryRule(translation_key, source_text_hash)}
        >
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
