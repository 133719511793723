export const formatAvatarInitials = (name: string) => {
  let parsedName = name;
  parsedName = parsedName
    .replace(/^https?:\/\//, '')
    .replace(/^www\./, '')
    .replace(/\..*/, '');
  const nameArray = parsedName?.replace(/[^a-zA-Z ]/g, '').split(' ');
  let formattedName = nameArray
    .filter((word) => word !== '')
    .map((word) => word[0].toUpperCase())
    .join('');
  if (formattedName.length >= 2) formattedName = formattedName.substring(0, 2);
  return formattedName;
};
