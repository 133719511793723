import { Divider, Modal, Title } from '@mantine/core';
import { useGLGOParams } from 'hooks';
import ContactUsModalForm from './ContactUsModalForm';
import { useUserProvider, useGlobalModalStore, GlobalModalStore } from 'store';

function ContactUsModal() {
  const { projectKey } = useGLGOParams();
  const { showContactUsModal, setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const user = useUserProvider().xapisUser;
  const activeProject = user?.project_keys?.find(
    (p) => p.project_key === projectKey
  );

  const { first_name = '', last_name = '', email = '', user_key } = user;

  return (
    <Modal
      centered
      opened={showContactUsModal}
      onClose={() => setShowContactUsModal(false)}
      padding="3rem"
      size="lg"
    >
      <Modal.Body p={0}>
        <Title order={3} mx="auto" pb="1rem">
          Contact Us
        </Title>
        <Divider color="divider.1" m="0.5rem 0 1.5rem 0" size="xs" w="100%" />
        <ContactUsModalForm
          userName={`${first_name} ${last_name}`}
          email={email}
          userKey={user_key}
          projectName={activeProject?.project_name || ''}
          projectKey={projectKey || ''}
        />
      </Modal.Body>
    </Modal>
  );
}

export default ContactUsModal;
