import * as React from 'react';
import { useState } from 'react';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { useGLGOParams, useLogout } from 'hooks';
import { useMediaQuery } from '@mantine/hooks';
import {
  ActionIcon,
  AppShell,
  Burger,
  Container,
  Divider,
  Grid,
  Image,
  Menu,
  Paper,
  rem,
  Text,
  Transition,
} from '@mantine/core';
import { BsFillPersonFill } from 'react-icons/bs';
import { MdContactSupport } from 'react-icons/md';
import ProjectSelector from '../../../../ProjectSelector';
import ViewTypeBadge from '../../../../ViewTypeBadge';
import { BackendSelector } from '../../../../BackendSelector';
import { MainHeaderNotificationsPopover } from './MainHeaderNotificationsPopover';
import {
  SideNavStore,
  useSideNavStore,
  defaultSubscription,
  useGlobalModalStore,
  GlobalModalStore,
  useUserProvider,
  useCurrentProjectData,
} from 'store';
import { NavLinkItem } from '../gonavigation/SideNav';
import {
  dashboardRC,
  isDesktopApp,
  getNotificationTimeslice,
  getRemainingSubscriptionDays,
} from 'helpers';
import ContactUsModal from './ContactUsModal';
import AboutModal from './AboutModal';
import MobileNotificationsPaper from './MobileNotificationsPaper';
import { CircleAvatar } from '../../Generic';
import { MobileMenu } from './MobileMenu';
import classes from './MainHeader.module.css';
import { useAuth } from 'react-oidc-context';
import SubscribeButton from '../shared/SubscribeButton';

type Props = {
  logo: string;
  navLinks: NavLinkItem[];
  isDesktopSize: boolean;
  opened: boolean;
  toggle: VoidFunction;
  close: () => void;
  children?: React.ReactNode;
  version?: string;
};

const isGLGODesktop = isDesktopApp();

export const MainHeader = ({
  logo,
  navLinks,
  isDesktopSize,
  opened,
  toggle,
  close,
  version,
}: Props) => {
  const { projectKey, projectId } = useGLGOParams();
  const auth = useAuth();
  const logout = useLogout();

  const { isNavExpanded } = useSideNavStore((state: SideNavStore) => state);
  const isMobileSize = useMediaQuery('(max-width: 991px)');

  const isExpanded = isNavExpanded && isDesktopSize;
  const { subscriptions } = useCurrentProjectData();

  const { xapisUser } = useUserProvider();
  const activeProject = xapisUser.project_keys?.find(
    (p) => p.project_key === projectKey
  );

  const pathSegments = useLocation().pathname.split('/');
  const currentPage = projectKey
    ? pathSegments[2]?.split('-')?.join(' ')
    : pathSegments[1];

  const [showAboutModal, setShowAboutModal] = useState(false);

  const { setShowContactUsModal } = useGlobalModalStore(
    (state: GlobalModalStore) => state
  );

  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const [notificationTimeslice, setNotificationTimeslice] = useState('');
  const [firstSubscription = defaultSubscription] = subscriptions;
  const { next_renewal_date, price } = firstSubscription;

  const todaysDateTimestamp = Date.now();
  const renewalDateTimestamp = new Date(next_renewal_date).getTime();
  const isAfterRenewalDate = todaysDateTimestamp > renewalDateTimestamp;

  const isSubscriptionExpired = !subscriptions?.length || isAfterRenewalDate;
  const showSubButton = isSubscriptionExpired || price === 0;

  const remainingDaysInSubscription =
    getRemainingSubscriptionDays(next_renewal_date);

  const encroachingDays = 7;
  const notifications = [] as Array<{
    isNew: boolean;
    text: string;
    time: string;
  }>;

  if (remainingDaysInSubscription <= encroachingDays) {
    notifications.push({
      isNew: true,
      text: `Your Free Trial will run out in ${remainingDaysInSubscription} days.`,
      time: `${notificationTimeslice}`,
    });
  }
  if (notifications.length === 0) {
    notifications.push({
      isNew: false,
      text: 'You have no new notifications. Check back soon!',
      time: 'now',
    });
  }

  const calculateLeftPadding = () => {
    if (isMobileSize) return 0;
    return isExpanded ? 230 : 90;
  };

  return (
    <>
      <AboutModal opened={showAboutModal} setOpened={setShowAboutModal} />
      <ContactUsModal />
      <AppShell.Header
        data-testid={
          isExpanded
            ? 'pw-expand-top-nav-container'
            : 'pw-collapse-top-nav-container'
        }
        h={rem(60)}
        mb={120}
        style={{
          transition: 'all 0.1s',
        }}
        className={`${classes.header} ${classes.main}`}
        pl={calculateLeftPadding()}
      >
        {!isMobileSize ? (
          <Container
            className={classes.headerContainer}
            px={!isMobileSize ? 40 : 0}
          >
            <Grid w="100%" align="center" gutter="xl">
              <Grid.Col
                span={{ base: 8, md: 7, lg: 8 }}
                display="flex"
                style={{ alignItems: 'center', gap: 15 }}
              >
                <Text
                  data-testid="pw-header-text"
                  w="fit-content"
                  maw="15rem"
                  lineClamp={2}
                  className={classes.overviewText}
                  fz="1.35rem"
                  lh="1.35rem"
                  style={{ wordWrap: 'break-word' }}
                >
                  {currentPage}
                </Text>
                <Divider className={classes.border} orientation="vertical" />
                <ProjectSelector />
                <ViewTypeBadge />
                <BackendSelector />
              </Grid.Col>
              <Grid.Col
                className={classes.actionsContainer}
                span={{ base: 4, md: 5, lg: 4 }}
                display="flex"
                px={0}
              >
                {showSubButton && (
                  <>
                    <SubscribeButton />
                    <Divider
                      className={classes.border}
                      mr={15}
                      ml={30}
                      orientation="vertical"
                    />
                  </>
                )}
                <MainHeaderNotificationsPopover subscriptions={subscriptions} />
                <Menu
                  width={150}
                  shadow="sm"
                  position="bottom-end"
                  classNames={{
                    dropdown: classes.accountMenu,
                    item: classes.accountMenuItem,
                  }}
                >
                  <Menu.Target>
                    <ActionIcon
                      bg="transparent"
                      data-testid="support-icon"
                      mx={12}
                    >
                      <MdContactSupport className={classes.icon} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown
                    style={{ border: '1px solid rgb(44, 73, 172)' }}
                  >
                    <Menu.Item
                      onClick={() =>
                        window.open(
                          'https://support.globallinkgo.com',
                          '_blank'
                        )
                      }
                    >
                      Help
                    </Menu.Item>
                    <Menu.Item onClick={() => setShowContactUsModal(true)}>
                      Contact Us
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                <Menu
                  width={150}
                  shadow="sm"
                  position="bottom-end"
                  classNames={{
                    dropdown: classes.accountMenu,
                    item: classes.accountMenuItem,
                  }}
                >
                  <Menu.Target>
                    <ActionIcon
                      className={classes.iconContainer}
                      bg="transparent"
                      data-testid="user-icon"
                      mx={12}
                    >
                      <BsFillPersonFill className={classes.icon} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown
                    style={{ border: '1px solid rgb(44, 73, 172)' }}
                  >
                    <Link
                      className={classes.menuLink}
                      to={`${projectId}/${dashboardRC.PAGE_ACCOUNT_INFO}/my-profile`}
                    >
                      <Menu.Item>Account</Menu.Item>
                    </Link>
                    {!isGLGODesktop && (
                      <Link
                        className={classes.menuLink}
                        to={`${projectId}/${dashboardRC.PAGE_DESKTOP_APP}`}
                      >
                        <Menu.Item>Get GLGO Desktop</Menu.Item>
                      </Link>
                    )}
                    <Menu.Item onClick={() => setShowAboutModal(true)}>
                      About
                    </Menu.Item>
                    <Menu.Item onClick={logout}>Log Out</Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Grid.Col>
            </Grid>
          </Container>
        ) : (
          <Container className={classes.mobileHeader}>
            <Grid align="center" w="100%">
              <Grid.Col
                span={6}
                display="flex"
                style={{ alignItems: 'center' }}
                px={0}
              >
                <Burger
                  color="white"
                  opened={opened}
                  onClick={() => {
                    if (showNotificationsMenu) setShowNotificationsMenu(false);
                    setNotificationTimeslice(getNotificationTimeslice(auth));
                    toggle();
                  }}
                  classNames={{ burger: classes.burger }}
                  size="sm"
                  mr={10}
                />
                <NavLink
                  to={`${projectId}/${dashboardRC.PAGE_DASHBOARD}`}
                  style={{ width: 150 }}
                >
                  <Image
                    title={version || ''}
                    my="auto"
                    height="auto"
                    width={150}
                    src={logo}
                    alt="logo"
                  />
                </NavLink>
                <BackendSelector />
              </Grid.Col>
              <Grid.Col
                span={6}
                display="flex"
                style={{ alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <CircleAvatar
                  name={activeProject?.project_name}
                  backgroundColor="background.0"
                  textColor="text.2"
                  circleSize="2.5rem"
                  fontSize="1rem"
                />
              </Grid.Col>
            </Grid>
            <Transition transition="scale-y" duration={200} mounted={opened}>
              {(styles) => (
                <Paper
                  pb={showNotificationsMenu ? 0 : 20}
                  className={classes.dropdown}
                  style={styles}
                >
                  {showNotificationsMenu ? (
                    <MobileNotificationsPaper
                      notifications={notifications}
                      setShowNotificationsMenu={setShowNotificationsMenu}
                    />
                  ) : (
                    <>
                      <Divider w="95%" my={15} mx="auto" color="white" />
                      <MobileMenu
                        navLinks={navLinks}
                        closeMenu={close}
                        setShowContactUsModal={setShowContactUsModal}
                        setShowNotificationsMenu={setShowNotificationsMenu}
                      />
                    </>
                  )}
                </Paper>
              )}
            </Transition>
          </Container>
        )}
      </AppShell.Header>
    </>
  );
};
export default MainHeader;
