import React from 'react';
import { Card, Container, Divider, Grid, Group } from '@mantine/core';
import { CardInterface } from '../../../../types';
import WorldTraffic from './WorldTraffic';
import TranslatedTraffic from './TranslatedTraffic';
import WordsServed from './WordsServed';
import { useMediaQuery } from '@mantine/hooks';
import {
  TranslatedTrafficTimeslice,
  WordsServedResponse,
  WorldTrafficResponse,
} from 'store';
import classes from './MetricsSummary.module.css';

type Props = CardInterface & {
  prevTimeSlices: TranslatedTrafficTimeslice[];
  timeSlices: TranslatedTrafficTimeslice[];
  worldTraffic: WorldTrafficResponse[];
  wordsServed: WordsServedResponse[];
  prevWordsServed: WordsServedResponse[];
};

export const MetricsSummary = ({
  header,
  prevTimeSlices,
  timeSlices,
  worldTraffic,
  wordsServed,
  prevWordsServed,
}: Props) => {
  const isTabletSize = useMediaQuery('(max-width: 991px)');

  const MobileDivider = (
    <Divider
      className={classes.mobileDivider}
      size="sm"
      orientation="horizontal"
    />
  );

  return (
    <Container mx={0} px={0} maw="100%" className="glwebContainer">
      <Group>{header}</Group>
      <Card>
        <Grid h={isTabletSize ? '100%' : 250} py={20}>
          <Grid.Col
            className={classes.summaryCol}
            px={30}
            py={0}
            span={{ base: 12, md: 4 }}
          >
            <TranslatedTraffic
              prevTimeSlices={prevTimeSlices}
              timeSlices={timeSlices}
            />
          </Grid.Col>
          {MobileDivider}
          <Grid.Col
            className={classes.worldTrafficCol}
            px={30}
            py={0}
            span={{ base: 12, md: 4 }}
          >
            <WorldTraffic worldTraffic={worldTraffic} />
          </Grid.Col>
          {MobileDivider}
          <Grid.Col
            className={classes.summaryCol}
            px={30}
            py={0}
            span={{ base: 12, md: 4 }}
          >
            <WordsServed
              wordsServed={wordsServed}
              prevWordsServed={prevWordsServed}
            />
          </Grid.Col>
        </Grid>
      </Card>
    </Container>
  );
};

export default MetricsSummary;
