import React, { ReactElement } from 'react';
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';
import { Box, Flex, Tooltip, Image, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from './GlossaryTable.module.css';
import CaseSensitive from '../../gosetup/images/CaseSensitiveIcon.svg';
import OverrideMT from '../../gosetup/images/OverrideMTSymbol.svg';
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
} from 'mantine-react-table';
import {
  NEVER_TRANSLATE,
  NEVER_TRANSLATE_AS,
  RuleType,
  TRANSLATE_AS,
} from './glossaryConstants';
import TranslateAsIcon from '../../gosetup/images/TranslateAsGlossaryIcon.svg';
import NeverTranslateAsIcon from '../../gosetup/images/NeverTranslateAsGlossaryIcon.svg';
import NeverTranslateIcon from '../../gosetup/images/NeverTranslateGlossaryIcon.svg';

export type GlossaryRow = {
  id: string;
  rule: string;
  terms: string;
  isCaseSensitive: string;
  isMt: string;
  languages: string;
  comment: string;
  manage: ReactElement;
  [key: string]: string | ReactElement;
};

type Props = {
  records: GlossaryRow[];
};

export const GlossaryTable = ({ records }: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const heightOffset = isMobileSize ? 450 : 370;

  const icons: { [key in RuleType]: string } = {
    [TRANSLATE_AS]: TranslateAsIcon,
    [NEVER_TRANSLATE_AS]: NeverTranslateAsIcon,
    [NEVER_TRANSLATE]: NeverTranslateIcon,
  };

  const columns: Array<MRT_ColumnDef<GlossaryRow>> = [
    {
      accessorKey: 'manage',
      header: '',
      size: 60,
      enableSorting: false,
    },
    {
      accessorKey: 'rule',
      header: 'RULE',
      size: 225,
      Cell: ({ cell }) => {
        const rule = cell.getValue() as string;
        const ruleString =
          rule === NEVER_TRANSLATE_AS
            ? "Don't Translate As"
            : rule
                .split('_')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        return (
          <Flex data-value={ruleString} columnGap="1rem">
            <Box>
              <Image src={icons[rule]} />
            </Box>
            <Text>{ruleString}</Text>
          </Flex>
        );
      },

      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      accessorKey: 'terms',
      header: 'TERMS',
      size: 380,
      Cell: ({ cell }) => {
        const [source_text, targetTextString] = (
          cell.getValue() as string
        ).split(' as ');
        return (
          <Tooltip
            label={`${source_text + ' as ' + targetTextString}`}
            position="top-start"
            events={{ hover: true, touch: true, focus: false }}
            withinPortal
          >
            <Text data-value={source_text} truncate>
              <Text truncate span td="underline" fw={700}>
                {source_text}
              </Text>
              {targetTextString && ' as '}
              <Text truncate span td="underline" fw={700}>
                {targetTextString}
              </Text>
            </Text>
          </Tooltip>
        );
      },
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
    {
      accessorKey: 'isCaseSensitive',
      header: 'CASE SENSITIVE',
      Header: (
        <Tooltip label="CASE SENSITIVE">
          <Box>
            <Image src={CaseSensitive} />
          </Box>
        </Tooltip>
      ),
      size: 100,
    },
    {
      accessorKey: 'isMt',
      header: 'MT OVERRIDE',
      Header: (
        <Tooltip label="MT OVERRIDE">
          <Box>
            <Image src={OverrideMT} />
          </Box>
        </Tooltip>
      ),
      size: 100,
    },
    {
      accessorKey: 'languages',
      header: 'LANGUAGE',
      size: 250,
    },
    {
      accessorKey: 'comment',
      header: 'NOTES',
      size: 200,
      Cell: ({ cell }) => {
        const comment = cell.getValue() as string;
        return (
          <Tooltip
            label={`${comment}`}
            position="top-end"
            events={{ hover: true, touch: true, focus: false }}
            withinPortal
          >
            <Text data-value={comment} truncate>
              {comment}
            </Text>
          </Tooltip>
        );
      },
      sortingFn: (rowA, rowB, columnId) =>
        (rowA.getValue(columnId) as string).localeCompare(
          rowB.getValue(columnId) as string
        ),
    },
  ];

  const table = useMantineReactTable({
    columns,
    data: records,

    enableColumnActions: false,
    enableRowVirtualization: true,
    enableFilters: false,
    enablePagination: false,
    enableTopToolbar: false,

    mantineBottomToolbarProps: {
      style: { display: 'flex', alignContent: 'center' },
    },
    mantineTableContainerProps: {
      className: classes.scrollbar,
      style: { maxHeight: `calc(90vh - ${heightOffset}px)` },
    },
    mantineTableHeadProps: { className: classes.header },
    mantineTableProps: { highlightOnHover: false },

    rowVirtualizerOptions: {
      overscan: 50, //adjust the number of rows that are rendered above and below the visible area of the table
    },
    renderBottomToolbarCustomActions: () => (
      <Flex justify="center" ml={20}>
        <Text fw={700}>{`${records.length} Results`}</Text>
      </Flex>
    ),
    icons: {
      IconArrowsSort: () => <TiArrowUnsorted color="#757575" size={14} />,
      IconSortAscending: () => <TiArrowSortedUp color="#757575" size={14} />,
      IconSortDescending: () => <TiArrowSortedDown color="#757575" size={14} />,
    },
  });

  return <MantineReactTable table={table} />;
};
