import { Box } from '@mantine/core';
import { routes, settings } from './routes';
import { RouterTabs } from 'ui';

export const QuotesPage = () => {
  return (
    <Box pt={60}>
      <RouterTabs tabs={routes} settings={settings} />
    </Box>
  );
};
