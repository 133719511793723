import { IconDeer } from '@tabler/icons';
import {
  MdDashboard,
  MdSettings,
  MdTimeline,
  MdTranslate,
  MdFormatQuote,
} from 'react-icons/md';
import { PiUsersThreeFill } from 'react-icons/pi';
import { BsFillPersonFill } from 'react-icons/bs';
import { dashboardRC } from 'helpers';
import { translationsTabs, metricsTabs, settingsTabs } from 'pages';
import { NavLinkItem } from 'ui';

export const navLinks: NavLinkItem[] = [
  {
    icon: <MdDashboard size={24} />,
    label: dashboardRC.PAGE_TITLE_DASHBOARD,
    path: dashboardRC.PAGE_DASHBOARD,
  },
  {
    icon: <MdTranslate size={24} />,
    label: dashboardRC.PAGE_TITLE_TRANSLATIONS,
    path: dashboardRC.PAGE_TRANSLATIONS,
    defaultTab: translationsTabs[0].path,
  },
  {
    icon: <MdTimeline size={24} />,
    label: dashboardRC.PAGE_TITLE_METRICS,
    path: dashboardRC.PAGE_METRICS,
    defaultTab: metricsTabs[0].path,
  },
  {
    icon: <MdSettings size={24} />,
    label: dashboardRC.PAGE_TITLE_SETTINGS,
    path: dashboardRC.PAGE_SETTINGS,
    defaultTab: settingsTabs[0].path,
  },
  {
    icon: <IconDeer size={24} />,
    label: dashboardRC.PAGE_TITLE_DEBUG,
    path: dashboardRC.PAGE_STORE_DEBUG,
  },
  {
    icon: <PiUsersThreeFill size={24} />,
    label: dashboardRC.PAGE_TITLE_USER_MANAGEMENT,
    path: dashboardRC.PAGE_USER_MANAGEMENT,
  },
];

export const quoteNavLink: NavLinkItem = {
  icon: <MdFormatQuote size={24} />,
  label: dashboardRC.PAGE_TITLE_QUOTES,
  path: dashboardRC.PAGE_QUOTES,
};

export const fixedMobileLinks: NavLinkItem[] = [
  {
    icon: <MdDashboard size={24} />,
    label: dashboardRC.PAGE_TITLE_DASHBOARD,
    path: dashboardRC.PAGE_DASHBOARD,
  },
  {
    icon: <MdTranslate size={24} />,
    label: dashboardRC.PAGE_TITLE_TRANSLATIONS,
    path: dashboardRC.PAGE_TRANSLATIONS,
    defaultTab: translationsTabs[0].path,
  },
  {
    icon: <MdTimeline size={24} />,
    label: dashboardRC.PAGE_TITLE_METRICS,
    path: dashboardRC.PAGE_METRICS,
    defaultTab: metricsTabs[0].path,
  },
  {
    icon: <MdSettings size={24} />,
    label: dashboardRC.PAGE_TITLE_SETTINGS,
    path: dashboardRC.PAGE_SETTINGS,
    defaultTab: settingsTabs[0].path,
  },
  {
    icon: <PiUsersThreeFill size={24} />,
    label: dashboardRC.PAGE_TITLE_USER_MANAGEMENT,
    path: dashboardRC.PAGE_USER_MANAGEMENT,
  },
];

export const headerLinks: NavLinkItem[] = [
  {
    icon: <BsFillPersonFill size={24} />,
    label: dashboardRC.PAGE_TITLE_ACCOUNT_INFO,
    path: `${dashboardRC.PAGE_ACCOUNT_INFO}/my-profile`,
  },
  {
    icon: <IconDeer size={24} />,
    label: dashboardRC.PAGE_TITLE_DEBUG,
    path: dashboardRC.PAGE_STORE_DEBUG,
  },
];

export default { headerLinks, fixedMobileLinks, navLinks };
