import { Card, Flex, CloseButton, Divider, Text, Anchor } from '@mantine/core';

type Props = {
  setShowExplanationCard: React.Dispatch<React.SetStateAction<boolean>>;
};

const ExplanationCard = ({ setShowExplanationCard }: Props) => (
  <Card
    h="fit-content"
    mt={{ base: 15, xl: 0 }}
    ml={{ base: 0, xl: 30 }}
    px={{ base: 20, xs: 30, sm: 30 }}
    py={{ base: 20, xs: 30, sm: 40 }}
    w={{ base: '100%', xl: '30%' }}
  >
    <Flex direction="column" rowGap={20}>
      <Flex justify="space-between" gap={20}>
        <Text fw="700" fz="1.75rem" ta="center">
          How does the Custom CSS Editor work?
        </Text>
        <CloseButton
          onClick={() => setShowExplanationCard(false)}
          title="Close explanation card"
          size="md"
        />
      </Flex>
      <Divider
        w="100%"
        my={5}
        orientation="horizontal"
        size="sm"
        color="border.1"
      />
      <Text fw="500" fz="1rem">
        You can customize the styling of the language selector on your website
        by using the CSS Editor. It is pre-populated with your current default
        settings.&nbsp;
        <Anchor
          c="text.8"
          href="https://support.globallinkgo.com/article/139-how-do-i-customize-the-look-of-the-language-selector"
          target="_blank"
        >
          Learn more about customizing your selector.
        </Anchor>
      </Text>
    </Flex>
  </Card>
);

export default ExplanationCard;
