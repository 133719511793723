import React from 'react';
import {
  Button,
  Divider,
  Modal,
  Flex,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { updateDomain } from './utils';
import { useGLGOParams } from 'hooks';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentDomain: string;
  setCurrentDomain: React.Dispatch<React.SetStateAction<string>>;
  newDomain: string;
  setEditDomain: React.Dispatch<React.SetStateAction<boolean>>;
  resetForm: (data?: Record<string, string>) => void;
  setOriginName: React.Dispatch<React.SetStateAction<string>>;
};
const ConfirmEditDomainModal = ({
  showModal,
  setShowModal,
  currentDomain,
  setCurrentDomain,
  newDomain,
  setEditDomain,
  resetForm,
  setOriginName,
}: Props) => {
  const { colors } = useMantineTheme();
  const { projectKey = '' } = useGLGOParams();

  return (
    <Modal
      centered
      size="lg"
      opened={showModal}
      onClose={() => setShowModal(false)}
      styles={{
        header: {
          paddingBottom: 0,
        },
        content: {
          borderRadius: 20,
        },
      }}
    >
      <Flex
        w="100%"
        direction="column"
        align="center"
        rowGap="1.5rem"
        px="2rem"
        pb="2rem"
      >
        <Text fz="2rem" fw={700}>
          Confirmation
        </Text>
        <Divider w="100%" color={colors.divider[1]} />
        <Text ta="center" fz="1.25rem">
          You are about to change your project’s source domain from{' '}
          <Text fz="1.25rem" fw={600} span>
            {currentDomain}
          </Text>
          &nbsp;to&nbsp;
          <Text fz="1.25rem" fw={600} span>
            {newDomain}
          </Text>
          , please confirm that this is what you want to do.
        </Text>
        <Flex columnGap="1rem">
          <Button
            c="text.8"
            bg="background.0"
            fz="1.05rem"
            style={{
              borderRadius: 30,
              border: `1px solid ${colors.text[8]}`,
            }}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            c="text.0"
            bg="background2.2"
            fz="1.05rem"
            style={{
              borderRadius: 30,
              border: `1px solid ${colors.text[8]}`,
            }}
            onClick={() => {
              updateDomain('origin_name', newDomain, [], projectKey).then(
                (response) => {
                  const { project: { origin_name: originName = '' } = {} } =
                    response || {};
                  if (originName) {
                    setOriginName(originName);
                    setCurrentDomain(originName);
                    setShowModal(false);
                    setEditDomain(false);
                    resetForm({ domain: originName });
                  }
                }
              );
            }}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ConfirmEditDomainModal;
