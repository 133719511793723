import React from 'react';
import {
  Accordion,
  Anchor,
  Box,
  Card,
  Divider,
  Flex,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FaGear } from 'react-icons/fa6';
import { MdEdit, MdOutlineArrowDropDown } from 'react-icons/md';
import ChangeMethodModal from './ChangeMethodModal';
import ConfirmMethodModal from './ConfirmMethodModal';
import MethodsSwitch from './MethodsSwitch';
import ChangeMethodMenu from './ChangeMethodMenu';
import { useDeploymentContext } from './DeploymentContextProvider';

const Deployment = () => {
  const { colors } = useMantineTheme();
  const {
    showForm,
    setShowForm,
    accordionValue,
    setAccordionValue,
    showChangeMethodModal,
    showConfirmMethodModal,
    selectedMethod,
    activeProject: { deployment_method: defaultMethod = '' } = {},
  } = useDeploymentContext();

  const methodType = showForm
    ? 'edit'
    : selectedMethod && !showChangeMethodModal && !showConfirmMethodModal
      ? 'configure'
      : 'default';

  const isConfigureType = methodType === 'configure';

  return (
    <>
      {showConfirmMethodModal && <ConfirmMethodModal />}
      {showChangeMethodModal && <ChangeMethodModal />}
      <MethodsSwitch methodType={isConfigureType ? methodType : null} />
      {!isConfigureType && (
        <Card mt={30} maw="44rem" p="2rem">
          <Flex rowGap="1.5rem" direction="column">
            <Flex w="100%" align="center" columnGap="0.75rem">
              <Text fz="1.25rem" fw={700} tt="uppercase">
                Deployment Methods
              </Text>
              <ChangeMethodMenu>
                <Box style={{ cursor: 'pointer' }}>
                  <FaGear size={25} color={colors.icon[4]} />
                </Box>
              </ChangeMethodMenu>
            </Flex>
            <Divider size="xs" orientation="horizontal" color="divider.1" />
            <Text fz="1.25rem">
              There are four ways to deploy GlobalLink GO.{' '}
              <Anchor
                fz="1.25rem"
                td="underline"
                c="text.8"
                fw={700}
                target="_blank"
                href="https://support.globallinkgo.com/article/130-what-are-the-different-deployment-methods"
              >
                Click here
              </Anchor>{' '}
              to learn about the different deployment methods.
            </Text>
            <Card p="1.5rem" style={{ boxShadow: 'none' }}>
              <Text fz="1.25rem" fw={600}>
                Your current deployment method:&nbsp;&nbsp;&nbsp;
                <Text
                  display="inline-block"
                  fz="1.25rem"
                  c="text.5"
                  fw={700}
                  tt="capitalize"
                  span
                >
                  {defaultMethod}
                </Text>
              </Text>
            </Card>
            <Accordion
              styles={{
                content: {
                  padding: 0,
                },
                item: {
                  borderBottom: 'none',
                },
              }}
              style={{ borderBottom: 'none' }}
              chevron={null}
              value={accordionValue}
              defaultValue={accordionValue}
              onChange={(e) => {
                if (showForm) setShowForm(false);
                setAccordionValue(e);
              }}
            >
              <Accordion.Item value="details">
                <Accordion.Control>
                  <Flex c="text.8" align="center" columnGap="1rem">
                    <MdOutlineArrowDropDown size={30} />
                    <Text c="text.8" fw={600} fz="1.25rem">
                      Details
                    </Text>
                    <Box
                      display={
                        accordionValue !== 'details' || showForm
                          ? 'none'
                          : 'block'
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowForm(true);
                      }}
                    >
                      <MdEdit size={22} />
                    </Box>
                  </Flex>
                </Accordion.Control>
                <Accordion.Panel>
                  <MethodsSwitch
                    methodType={isConfigureType ? null : methodType}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default Deployment;
