import React, { useCallback, useMemo, useState } from 'react';
import { Button, Divider, Flex, Modal, Text } from '@mantine/core';
import ScopeOption from '../scope-option/ScopeOption';
import { useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { ProjectResponse, Xapis } from 'store';
import { asciiToHex, getYYTarget, hexToObject } from 'helpers';
import { notifications } from '@mantine/notifications';
import classes from './ScopeChangeFormModal.module.css';

type Props = {
  showFormModal: boolean;
  setShowFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentScope: string;
  setCurrentScope: React.Dispatch<React.SetStateAction<string>>;
};

const ScopeChangeFormModal = ({
  showFormModal,
  setShowFormModal,
  currentScope,
  setCurrentScope,
}: Props) => {
  const validator = useRevalidator();
  const { project, project: { translations = [] } = {} } = useRouteLoaderData(
    'settings'
  ) as ProjectResponse;

  const [selectedScope, setSelectedScope] = useState('');

  const options = ['all', 'exclude', 'include'].filter(
    (scope) => scope !== currentScope
  );

  const yyTarget = useMemo(() => {
    return getYYTarget(translations) as TranslationKey;
  }, [translations]);

  const handleChangeScope = useCallback(() => {
    const {
      translation_config: activeTranslationConfig = '',
      translation_config_hash: activeTranslationConfigHash = '',
    } = yyTarget || {};

    const activeProjectKey = project?.project_key || '';
    const hash = activeTranslationConfigHash;
    const decodedConfig = hexToObject(activeTranslationConfig);

    const excludeUris = ['all', 'exclude'].includes(selectedScope)
      ? []
      : ['.*'];
    const emptyRules = {
      ...decodedConfig.spider_rules,
      exclude_uris: excludeUris,
      include_uris: [],
    };
    const updatedConfig = JSON.stringify({
      ...decodedConfig,
      spider_rules: emptyRules,
      scope_preset: selectedScope,
    });
    const updatedConfigHex = asciiToHex(updatedConfig);
    const data = {
      translation_config: updatedConfigHex,
      translation_config_hash: hash,
    };

    Xapis.ProjectTranslation.put(
      activeProjectKey,
      yyTarget?.translation_key || '',
      data
    )
      .then(() => {
        setCurrentScope(selectedScope);
        notifications.show({
          message: "Your project's scope updated successfully!",
        });
        validator.revalidate();
      })
      .catch(() => {
        notifications.show({
          message: "We are unable to update your project's scope at this time.",
        });
      });
  }, [
    project?.project_key,
    selectedScope,
    setCurrentScope,
    validator,
    yyTarget,
  ]);

  return (
    <Modal
      centered
      opened={showFormModal}
      onClose={() => {
        setShowFormModal(false);
      }}
      padding="3rem"
      size="xxl"
      withCloseButton={false}
    >
      <Text c="text.2" fw="700" fz="2rem" lh={1.2} mb={20} ta="center">
        Change your default scope
      </Text>
      <Divider
        w="100%"
        my={5}
        orientation="horizontal"
        size="sm"
        color="border.1"
      />
      <Flex direction="column" mt="2rem" px={{ base: '1rem', lg: 0 }}>
        {options.map((s) => (
          <ScopeOption
            key={s}
            scopeName={s}
            currentScope={currentScope}
            handleSelectedScope={setSelectedScope}
            selectedScope={selectedScope}
          />
        ))}
      </Flex>
      <Divider
        w="100%"
        mb="2rem"
        mt="0.25rem"
        orientation="horizontal"
        size="sm"
        color="border.1"
      />
      <Flex justify="center" w="100%" gap={30}>
        <Button
          variant="outline"
          className={classes.cancelButton}
          onClick={() => setShowFormModal(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedScope}
          className={classes.saveButton}
          onClick={() => {
            setShowFormModal(false);
            handleChangeScope();
          }}
        >
          Save
        </Button>
      </Flex>
    </Modal>
  );
};

export default ScopeChangeFormModal;
