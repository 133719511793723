import { LAST_PROJECT_KEY_COOKIE } from '../constants';
const { getLastDTProjectKey, saveProjectKey } = window.UserManager || {};

const getProjectKeyFromCookie = () => {
  const cookie = document.cookie
    .split(';')
    .find((c) => c.includes(LAST_PROJECT_KEY_COOKIE));
  return cookie ? cookie.split('=')[1] : '';
};

export const getSavedProjectKey = async () => {
  // get the last selected project key from either desktop storage or cookies
  // This is done during the initialization of the app
  if (typeof getLastDTProjectKey === 'function') {
    return await getLastDTProjectKey();
  }
  return getProjectKeyFromCookie();
};

export const getDefaultProjectKey = (projects: ProjectKey[] = []) => {
  if (!projects?.length) return '';
  const lastSelectedProjectKey =
    sessionStorage.getItem(LAST_PROJECT_KEY_COOKIE) || '';
  projects.sort((a, b) => a.project_name.localeCompare(b.project_name));

  return projects.some((p) => p.project_key === lastSelectedProjectKey)
    ? lastSelectedProjectKey
    : projects[0].project_key;
};

export const saveSelectedProjectKey = (projectKey: string) => {
  // Save the project key in either desktop storage or cookies for persistence
  // Cookies doesn't work in desktop app
  if (typeof saveProjectKey === 'function') {
    saveProjectKey(projectKey);
  } else {
    document.cookie = `${LAST_PROJECT_KEY_COOKIE}=${projectKey};Max-Age=31536000; path=/`;
  }
  // Save the project key in session storage for the current session
  sessionStorage.setItem(LAST_PROJECT_KEY_COOKIE, projectKey);
};
