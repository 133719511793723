import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLogout } from 'hooks';

export const Logout = () => {
  const auth = useAuth();
  const logout = useLogout();
  const { isAuthenticated = false, isLoading = false } = auth;

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      logout();
    }
  }, [isAuthenticated, isLoading, logout]);

  return <div />;
};

export default Logout;
