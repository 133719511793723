import { Xapis, ProjectResponse } from '../../xapis-wrappers/xapis';
import { Params } from 'react-router-dom';
import { xapisEnv } from '../../service-wrappers/xapisService';
import { decodeId, filterTranslations, decodeProjectId } from 'helpers';
import { fetchSubscriptionsPromise } from '../../PaySubscriptionStore';
import {
  WordsServedUrlsResponse,
  fetchWordsServedUrlsPromise,
} from '../../WordsServedUrlsStore';
import {
  WordsServedResponse,
  fetchWordsServedPromise,
} from '../../WordsServedStore';

export type UsageLoaderData = {
  status: number;
  project?: ProjectKey | undefined;
  message?: string | undefined;
  targets: TranslationKey[] | undefined;
  threshold_value: string | undefined;
  urls: WordsServedUrlsResponse[];
  timeSliceFrom: string;
  wordsServed: WordsServedResponse[];
};

export async function usageLoader({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);
  const response: ProjectResponse = await Xapis.Project.get(projectKey);

  const { payKey } = xapisEnv.getHost;
  let threshold_value = '0';
  try {
    const subscriptionsRes = await fetchSubscriptionsPromise(
      payKey,
      projectKey
    );
    const { subscriptions = [] } = subscriptionsRes.data;
    const { threshold_value: threshold = '0' } = subscriptions[0] || {};
    threshold_value = threshold;
  } catch (error) {
    console.error('ERROR in usage loader:', error);
  }

  const { translations } = response.project || {};
  const translationKeys = filterTranslations(translations || []).map(
    (t) => t.translation_key
  );

  const data = {
    targets: translations,
    urls: [] as string[],
    threshold_value,
    timeSliceFrom: '',
    wordsServed: [] as string[],
    ...response,
  };

  const url = new URL(request.url);
  const keys = url.searchParams.getAll('t');

  const timeSliceFrom = url.searchParams.get('d');

  if (keys.length > 0 && keys[0] === '0') return data;

  const keysInUrl = keys.map((k) => decodeId(k));

  const getWordsServed = async () => {
    const maxSelection = 10;
    const firstTenKeys = translationKeys.slice(0, maxSelection);
    const firstTenKeysString = firstTenKeys.join(',');
    const concatenatedKeysOffUrl = keysInUrl.join(',');
    const keys =
      concatenatedKeysOffUrl.length > 0
        ? concatenatedKeysOffUrl
        : firstTenKeysString;

    const isYearly = timeSliceFrom?.includes('year');
    const rollup = isYearly ? 'month' : 'day';

    try {
      const response = await fetchWordsServedPromise(
        keys,
        timeSliceFrom || '1 month',
        rollup
      );

      if (response?.status !== 200)
        throw Error(response?.data?.message || 'Something went wrong.');

      return response?.data.data;
    } catch (error) {
      console.error('error in getWordsServed fn in usageLoader:', error);
      return [];
    }
  };

  const getWordsServedUrls = async () => {
    const maxSelection = 10;
    const firstTenKeys = translationKeys.slice(0, maxSelection);
    const firstTenKeysString = firstTenKeys.join(',');
    const concatenatedKeysOffUrl = keysInUrl.join(',');
    const keys =
      concatenatedKeysOffUrl.length > 0
        ? concatenatedKeysOffUrl
        : firstTenKeysString;
    try {
      const response = await fetchWordsServedUrlsPromise(
        keys,
        timeSliceFrom || '1 month'
      );
      if (response?.status !== 200)
        throw Error(response?.data?.message || 'Something went wrong.');

      return response?.data.data;
    } catch (error) {
      console.error('error in getWordsServedUrls fn in usageLoader:', error);
      return [];
    }
  };

  const wordsServed = await getWordsServed();
  const urls = await getWordsServedUrls();

  data.urls = urls;
  data.timeSliceFrom = timeSliceFrom ?? '1 month';
  data.wordsServed = wordsServed;

  return data;
}
