import { sortAlphabetically } from './sorting';

/**
 * Returns languages based on 'type' specified and the opposing 'type' value(s).
 * Source Languages: filtered based on paired target_lang_code(s) and google_code instances.
 * Target Languages: filtered based on paired source_lang_code.
 */
export default function getAvailableLanguages(
  type: 'source' | 'targets',
  languageCodes: LanguageCode[],
  languageCodePairs: {
    sourceLangCode: string;
    targetLangCodes: string[];
  }
) {
  const { sourceLangCode = '', targetLangCodes = [] } = languageCodePairs || {};

  const canonicalLanguages = languageCodes.filter(({ is_canonical }) => {
    return is_canonical === '1';
  });

  const googleCodeCountMap = new Map<string, number>();
  canonicalLanguages.forEach(({ language_name }) => {
    const count = googleCodeCountMap.get(language_name) || 0;
    googleCodeCountMap.set(language_name, count + 1);
  });

  const isSource = type === 'source';

  const reducedLanguages = isSource
    ? canonicalLanguages.reduce(
        (accum: { label: string; value: string }[], lang) => {
          const { language_name, langcode_name, langcode_code } = lang || {};
          const langCodeSet = new Set([...accum.map(({ value }) => value)]);

          if (!langCodeSet.has(langcode_code)) {
            const numOfGoogleCodeInstances =
              googleCodeCountMap.get(language_name) || 0;
            const hasDuplicateGoogleCode = numOfGoogleCodeInstances > 1;
            const label = hasDuplicateGoogleCode
              ? langcode_name
              : language_name;
            accum.push({ label, value: langcode_code });
          }
          return accum;
        },
        []
      )
    : canonicalLanguages.map(({ langcode_code, langcode_name }) => {
        return {
          label: langcode_name,
          value: langcode_code,
        };
      });

  const selectedTargetLangCodesSet = new Set(
    targetLangCodes.map((langCode) => {
      return langCode;
    })
  );

  const filteredLanguages = isSource
    ? reducedLanguages.filter(({ value }) => {
        return !selectedTargetLangCodesSet.has(value);
      })
    : reducedLanguages.filter(({ value }) => {
        return sourceLangCode !== value;
      });

  return sortAlphabetically(filteredLanguages, 'label');
}
