import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { auth } from 'auth';

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthProvider userManager={auth._userManager}>{children}</AuthProvider>
  );
};

export default AuthWrapper;
