import React, { Suspense, useState } from 'react';
import { useLoaderData, Link, useSearchParams } from 'react-router-dom';
import { useGLGOParams } from 'hooks';
import {
  DashboardLoaderResponse,
  defaultSubscription,
  useDashboardQueries,
  useCurrentProjectData,
  useRecommendedThresholdContext,
  xapisEnv,
  WordsServedResponse,
  queryQuickQuote,
} from 'store';
import { Box, Card, Divider, Grid, Group } from '@mantine/core';
import {
  BasicHorizontalDivider,
  CardHeader,
  DashboardCard,
  DateFilter,
  filterTargets,
  getStatusBannerBody,
  getTimeSliceOptions,
  LoadingSkeleton,
  MetricsFilters,
  MetricsSummary,
  MyLanguages,
  PlanDetails,
  StatusBanner,
  updateDateParam,
  WordsServedEstimate,
  WordsServedUsage,
} from 'ui';
import { dashboardRC } from 'helpers';
import { HiPencil } from 'react-icons/hi';
import { GoLiveModal } from 'ui';
import { QueryMultiSelector } from 'ui';
import classes from './DashboardPage.module.css';
import { useSuspenseQuery } from '@tanstack/react-query';

const WordsServedUsageHeader = ({ showBlur }: { showBlur?: boolean }) => (
  <CardHeader
    blur={showBlur}
    title="Words Served Usage"
    tooltipLabel="This chart shows your total words served for the month, the last day's increase,
                      and the projected total for the month based on your usage up to today. You can view historical
                      data and more filters in your Account settings."
  />
);

type QuickQuoteProps = {
  isFreePlan: boolean;
  thresholdValue: number;
  monthlyWordsServed: WordsServedResponse[];
  originName: string;
  shouldQueryQuickQuote: boolean;
};

const QuickQuote = ({
  isFreePlan,
  thresholdValue,
  monthlyWordsServed,
  originName,
  shouldQueryQuickQuote,
}: QuickQuoteProps) => {
  const [showEstimate, setShowEstimate] = useState(isFreePlan);

  const { data: quickQuote } = useSuspenseQuery(
    queryQuickQuote(shouldQueryQuickQuote, originName)
  );

  const isQuickQuoteCached =
    quickQuote?.qmessage && quickQuote.qmessage.includes('result was cached');

  return showEstimate && isQuickQuoteCached ? (
    <WordsServedEstimate
      quickQuote={quickQuote}
      header={<WordsServedUsageHeader showBlur={true} />}
      setShowEstimate={setShowEstimate}
    />
  ) : (
    <WordsServedUsage
      thresholdValue={thresholdValue}
      monthlyWordsServed={monthlyWordsServed}
      header={<WordsServedUsageHeader />}
    />
  );
};

export const DashboardPage = () => {
  const { concatenatedKey, timeSliceFrom, shouldQueryQuickQuote } =
    useLoaderData() as DashboardLoaderResponse;

  const { projectId, projectKey } = useGLGOParams();
  const { payKey } = xapisEnv.getHost;

  const [searchParams, setSearchParams] = useSearchParams();
  const [showGoLiveModal, setShowGoLiveModal] = useState(false);
  const thresholdFromEstimate = useRecommendedThresholdContext().threshold;
  const { project } = useCurrentProjectData();

  const {
    subscriptions,
    monthlyWordsServed,
    prevWordsServed,
    wordsServed,
    worldTraffic,
    translatedTraffic,
  } = useDashboardQueries({
    projectKey: projectKey!,
    payKey,
    concatenatedKey,
    timeSliceFrom,
  });

  const { translations: targets = [] } = project;

  const activeProjectStatus = project?.project_status || '';
  const isEnterprisePlan = project?.project_type === 'GLGO-ENTERPRISE';
  const isExpiredPlan = project?.subscription_status === 'expired';

  const {
    threshold_value = 0,
    words_served = 0,
    price = 0,
  } = subscriptions[0] || {};

  const hasActiveSubscription = !isExpiredPlan && subscriptions.length > 0;
  const isFreePlan = price === 0;

  const thresholdValue = hasActiveSubscription ? threshold_value : 0;
  const wordsServedAmount = hasActiveSubscription
    ? parseInt(words_served || defaultSubscription.words_served)
    : 0;

  const filteredTargets = filterTargets(project.translations || []);

  const statusBannerBody = getStatusBannerBody(
    isExpiredPlan,
    isFreePlan,
    activeProjectStatus,
    setShowGoLiveModal,
    projectId || '',
    thresholdFromEstimate
  );

  const handleSelectedDate = (value: string) => {
    updateDateParam(value, searchParams, setSearchParams);
  };

  if (projectKey && project) {
    return (
      <Box className={classes.pageWrapper}>
        {showGoLiveModal && (
          <GoLiveModal
            activeProjectKey={projectKey}
            setOpened={setShowGoLiveModal}
            opened={showGoLiveModal}
          />
        )}
        {statusBannerBody ? (
          <StatusBanner
            statusBody={statusBannerBody}
            statusType={isExpiredPlan ? 'subscription' : 'translations'}
          />
        ) : (
          <></>
        )}
        <Grid className={classes.mainGrid}>
          <Grid.Col span={12}>
            <DashboardCard>
              <MetricsSummary
                timeSlices={translatedTraffic.slices}
                prevTimeSlices={translatedTraffic.prevSlices}
                worldTraffic={worldTraffic}
                wordsServed={wordsServed}
                prevWordsServed={prevWordsServed}
                header={
                  <CardHeader
                    title="Metrics Summary"
                    tooltipLabel="This section of your dashboard presents summary statistics related to your project.
                  You can filter by language and time period. For a more detailed breakdown, you can go to the Metrics tab."
                    flexItems={
                      <MetricsFilters>
                        <QueryMultiSelector
                          encodeKeys
                          selectAll
                          shouldRevalidate
                          options={filteredTargets}
                        />
                        <DateFilter
                          timeSliceOptions={getTimeSliceOptions()}
                          timeSliceFrom={timeSliceFrom}
                          onSelect={handleSelectedDate}
                        />
                      </MetricsFilters>
                    }
                    itemsType="filter"
                    justifyItems="flex-start"
                  />
                }
              />
            </DashboardCard>
          </Grid.Col>
          <BasicHorizontalDivider borderWidth={2} />
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col
                span={{ base: 12, lg: 7 }}
                className={classes.wordsServedUsageCol}
              >
                <DashboardCard noPadding="left">
                  <Suspense
                    fallback={
                      <Box>
                        <Group>
                          <WordsServedUsageHeader />
                        </Group>
                        <Card h={500} p={30}>
                          <LoadingSkeleton />
                        </Card>
                      </Box>
                    }
                  >
                    <QuickQuote
                      isFreePlan={isFreePlan}
                      thresholdValue={thresholdValue}
                      monthlyWordsServed={monthlyWordsServed}
                      originName={project.origin_name ?? ''}
                      shouldQueryQuickQuote={shouldQueryQuickQuote}
                    />
                  </Suspense>
                </DashboardCard>
              </Grid.Col>
              <Divider
                size="xs"
                display={{ base: 'block', lg: 'none' }}
                m={{ base: '0 20px', sm: 'auto' }}
                w="100%"
                c="divider.0"
                orientation="horizontal"
              />
              <Grid.Col span={{ base: 12, lg: 5 }}>
                <Grid>
                  {isEnterprisePlan ? (
                    <></>
                  ) : (
                    <>
                      <Grid.Col span={12}>
                        <DashboardCard noPadding="right">
                          <PlanDetails
                            isExpiredPlan={isExpiredPlan}
                            subscriptions={subscriptions}
                            wordsServedAmount={wordsServedAmount}
                            header={
                              <CardHeader
                                title="Plan Details"
                                tooltipLabel="This shows your current subscription package and your subscription’s next renewal date. You can manage your subscription in your Account setting."
                                flexItems={
                                  <Box data-testid="pw-plan-details-edit-button">
                                    <Link
                                      to={`/${projectId}/${dashboardRC.PAGE_ACCOUNT_INFO}/billing-information`}
                                    >
                                      <HiPencil className="cardActionIcon" />
                                    </Link>
                                  </Box>
                                }
                                itemsType="icon"
                                justifyItems="flex-end"
                              />
                            }
                          />
                        </DashboardCard>
                      </Grid.Col>
                      <BasicHorizontalDivider borderWidth={2} />
                    </>
                  )}
                  <Grid.Col span={12}>
                    <DashboardCard noPadding="right">
                      <MyLanguages
                        targets={targets}
                        subscriptions={subscriptions}
                        header={
                          <CardHeader
                            title="My Languages"
                            tooltipLabel="This shows the languages your website can be translated into.
                            To add or remove languages, and to manage other settings, go to your Project Settings."
                            flexItems={
                              <Box data-testid="pw-my-languages-edit-button">
                                <Link
                                  to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
                                >
                                  <HiPencil className="cardActionIcon" />
                                </Link>
                              </Box>
                            }
                          />
                        }
                      />
                    </DashboardCard>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Box>
    );
  }

  return <></>;
};

export default DashboardPage;
