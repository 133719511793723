import { Divider, Text, Modal, Flex, Title } from '@mantine/core';

type Props = {
  opened: boolean;
  setOpened: (value: ((prevState: boolean) => boolean) | boolean) => void;
};

const AboutModal = (props: Props) => {
  const { opened, setOpened } = props;
  const { VITE_APP_VERSION = 'NOT_FOUND', VITE_BUILD_TIMESTAMP = 'NOT_FOUND' } =
    import.meta.env || {};
  const buildString = `Build: ${VITE_APP_VERSION}\n(${VITE_BUILD_TIMESTAMP})`;

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => setOpened(false)}
      size="lg"
      padding="2rem"
      styles={{ content: { gap: 0 } }}
    >
      <Modal.Body p={0}>
        <Flex direction="column" gap={20} ta="center">
          <Title order={3} mx="auto">
            About
          </Title>
          <Divider color="divider.1" size="xs" w="100%" />
          <Text>{buildString}</Text>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default AboutModal;
