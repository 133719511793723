import { Badge } from '@mantine/core';
import {
  GLGO_PROJECT_TYPE,
  GLWEB_PROJECT_TYPE,
  OLJS_PROJECT_TYPE,
  ONELINKJS_USER_TYPE,
} from 'helpers/constants';
import { glgoUser, useCurrentProjectData } from 'store';
import { useCallback } from 'react';

export const ViewTypeBadge = () => {
  const {
    project: { project_type: projectType },
  } = useCurrentProjectData();

  const userType = glgoUser.userType();

  const getBadgeContent = useCallback(() => {
    if (userType === ONELINKJS_USER_TYPE) {
      return 'GLWEB Internal';
    }
    if (projectType === GLWEB_PROJECT_TYPE) {
      return 'GLWEB Client';
    }
    if (projectType === OLJS_PROJECT_TYPE) {
      return 'OLJS Client';
    }
    return null;
  }, [projectType, userType]);

  const badgeContent = getBadgeContent();

  return projectType !== GLGO_PROJECT_TYPE && badgeContent ? (
    <Badge>{badgeContent}</Badge>
  ) : null;
};
export default ViewTypeBadge;
