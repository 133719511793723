import React from 'react';
import {
  BackgroundImage,
  Button,
  Divider,
  Image,
  Flex,
  Text,
  Card,
} from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import AddSnippetBackground from './images/add-snippet-background.svg';
import AddSnippetPerson from './images/add-snippet-person.svg';

export const AddSnippetSideMenuContent = () => {
  const { setActiveStep, setIsNextButtonDisabled } = useSetupStepsContext();
  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Text fz="2rem" fw={700}>
        Add Code Snippet
      </Text>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="1.15rem" fw={500} ta="center">
        You’ll need to add a snippet of JavaScript to your website. But don't
        worry!&nbsp; Your site will not be visibly affected and no translations
        will be publicly&nbsp; available until you say so.
        <br />
        <br />
        You’ll be able to preview everything first and when you’re confident
        everything looks good you can set it to public.
      </Text>
      <Button
        w={{ base: '100%', xs: '7rem' }}
        bg="background2.2"
        radius={30}
        onClick={() => {
          setIsNextButtonDisabled(true);
          setActiveStep((prevStep) => prevStep + 1);
        }}
        fz="1rem"
      >
        Next
      </Button>
    </Flex>
  );
};

export const AddSnippetBodyContent = () => (
  <BackgroundImage
    w="100%"
    h="100%"
    src={`"${AddSnippetBackground}"`}
    style={{
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
    }}
    pl="3rem"
    pr="2rem"
  >
    <Flex w="100%" h="100%" align="center" justify="center">
      <Image
        w="100%"
        maw="45rem"
        h="auto"
        src={AddSnippetPerson}
        alt="Person on file step copying GLGO translation code snippet"
      />
    </Flex>
  </BackgroundImage>
);

export const MobileAddSnippetStep = () => {
  const { setActiveStep, setIsNextButtonDisabled } = useSetupStepsContext();
  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" h="100%" direction="column" align="center" rowGap="1.5rem">
        <Text fz={{ base: '1.5rem', sm: '2rem' }} fw={700}>
          Add Code Snippet
        </Text>
        <Divider w="100%" color="divider.0" size={2} />
        <Text fz={{ base: '1rem', sm: '1.15rem' }} fw={500} ta="center">
          You’ll need to add a snippet of JavaScript to your website. But don't
          worry!&nbsp; Your site will not be visibly affected and no
          translations will be publicly&nbsp; available until you say so.
          <br />
          <br />
          You’ll be able to preview everything first and when you’re confident
          everything looks good you can set it to public.
        </Text>
        <Button
          w={{ base: '100%', xs: '7rem' }}
          bg="background2.2"
          radius={30}
          onClick={() => {
            setIsNextButtonDisabled(true);
            setActiveStep((prevStep) => prevStep + 1);
          }}
          fz="1rem"
        >
          Next
        </Button>
      </Flex>
    </Card>
  );
};
