import { Navigate, RouteObject, Outlet } from 'react-router-dom';
import {
  CreateAccount,
  AccountPage,
  QuotesPage,
  DashboardPage,
  MetricsPage,
  ProjectSetupPage,
  SettingsPage,
  TranslationsPage,
  translationsTabs,
  settingsTabs,
  metricsTabs,
  accountTabs,
  quoteTabs,
  DowngradeConfirmationPage,
  OrderConfirmationPage,
  UserManagementPage,
  CancellationConfirmationPage,
  DesktopAppPage,
} from 'pages';
import {
  dashboardLoader,
  accountLoader,
  downgradeConfirmationLoader,
  metricsLoader,
  orderConfirmationLoader,
  projectLoader,
  projectQueryLoader,
  quotesLoader,
  cancellationConfirmationLoader,
} from 'store';
import { dashboardRC } from 'helpers';
import PostLogin from './PostLogin';
import SilentRenew from './SilentRenew';
import { ErrorView } from './ErrorView';
import { PostLogout } from './PostLogout';
import { Logout } from './Logout';
import StoreDebug from './StoreDebug';
import { NavigationLayout } from './NavigationLayout';
import { Root } from './Root';
import { ErrorBoundary } from 'ui';
export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorView />,
    children: [
      {
        element: <NavigationLayout />,
        errorElement: <ErrorView />,
        children: [
          {
            path: ':projectId',
            element: <Outlet />,
            id: 'project',
            loader: projectQueryLoader,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={dashboardRC.PAGE_DASHBOARD} replace={true} />
                ),
              },
              {
                errorElement: <ErrorView />,
                path: dashboardRC.PAGE_DASHBOARD,
                element: <DashboardPage />,
                id: 'dashboard',
                loader: dashboardLoader,
              },
              {
                path: dashboardRC.PAGE_METRICS,
                id: 'metrics',
                element: <MetricsPage />,
                loader: metricsLoader,
                errorElement: <ErrorBoundary />,
                children: [...metricsTabs],
              },
              {
                path: dashboardRC.PAGE_TRANSLATIONS,
                id: 'translations',
                element: <TranslationsPage />,
                children: [...translationsTabs],
              },
              {
                path: dashboardRC.PAGE_SETTINGS,
                id: 'settings',
                element: <SettingsPage />,
                shouldRevalidate: () => {
                  return true;
                },
                loader: projectLoader,
                children: [...settingsTabs],
              },
              {
                path: dashboardRC.PAGE_ACCOUNT_INFO,
                id: 'account',
                element: <AccountPage />,
                loader: accountLoader,
                errorElement: <ErrorBoundary />,
                children: [...accountTabs],
              },
              {
                path: dashboardRC.PAGE_QUOTES,
                id: 'quotes',
                element: <QuotesPage />,
                loader: quotesLoader,
                errorElement: <ErrorBoundary />,
                children: [...quoteTabs],
              },
              {
                path: dashboardRC.PAGE_STORE_DEBUG,
                element: <StoreDebug />,
              },
              {
                path: dashboardRC.PAGE_ORDER_CONFIRMATION,
                element: <OrderConfirmationPage />,
                loader: orderConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_DOWNGRADE_CONFIRMATION,
                element: <DowngradeConfirmationPage />,
                loader: downgradeConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_CANCELLATION_CONFIRMATION,
                element: <CancellationConfirmationPage />,
                loader: cancellationConfirmationLoader,
              },
              {
                path: dashboardRC.PAGE_USER_MANAGEMENT,
                element: <UserManagementPage />,
              },
              {
                path: dashboardRC.PAGE_DESKTOP_APP,
                element: <DesktopAppPage />,
              },
            ],
          },
        ],
      },
      {
        path: dashboardRC.PAGE_CREATE_PROJECT,
        element: <ProjectSetupPage />,
      },
    ],
  },
  {
    path: dashboardRC.PAGE_CREATE_ACCOUNT,
    element: <CreateAccount />,
    errorElement: <ErrorView />,
  },
  {
    path: dashboardRC.PAGE_LOGIN_REDIRECT,
    element: <PostLogin />,
  },
  {
    path: dashboardRC.PAGE_SILENT_RENEW,
    element: <SilentRenew />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT,
    element: <Logout />,
  },
  {
    path: dashboardRC.PAGE_LOGOUT_REDIRECT,
    element: <PostLogout />,
  },
];
