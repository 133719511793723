import { useAuth } from 'react-oidc-context';

const { logoutDTUser } = window.UserManager || {};

export const useLogout = () => {
  const auth = useAuth();
  return () => {
    if (logoutDTUser && typeof logoutDTUser === 'function') {
      sessionStorage.removeItem('TOKEN_STATUS');
      logoutDTUser(() =>
        auth.signoutRedirect().catch((error) => {
          console.error('signoutRedirect error:', error.toString());
        })
      );
    } else {
      auth.signoutRedirect().catch((error) => {
        console.error('signoutRedirect error:', error.toString());
      });
    }
  };
};
