import {
  Modal,
  Flex,
  Divider,
  Button,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { getYYTarget } from 'helpers';
import { useGLGOParams } from 'hooks';
import { useRouteLoaderData, useSubmit } from 'react-router-dom';
import { ProjectResponse } from 'store';

type Props = {
  showSpecifyModal: boolean;
  setShowSpecifyModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTranslations: Partial<LanguageCode>[];
  setSelectedTranslations: React.Dispatch<
    React.SetStateAction<Partial<LanguageCode>[]>
  >;
};

export const SpecifyModal = ({
  showSpecifyModal,
  setShowSpecifyModal,
  selectedTranslations,
}: Props) => {
  const submit = useSubmit();
  const { projectId = '', projectKey = '' } = useGLGOParams();
  const { project: { translations = [], deployment_method = '' } = {} } =
    useRouteLoaderData('settings') as ProjectResponse;
  const yyTarget = getYYTarget(translations);

  const addLanguages = (shouldRedirect?: boolean) => {
    setShowSpecifyModal(false);
    submit(
      {
        projectKey,
        yyTarget: JSON.stringify(yyTarget),
        selectedTranslations: JSON.stringify(selectedTranslations),
        deployment_method,
        redirectRoute: shouldRedirect
          ? `/${projectId}/settings/deployment?edit=1`
          : '',
      },
      {
        method: 'post',
        encType: 'application/json',
      }
    );
  };

  return (
    <Modal
      size="lg"
      centered
      opened={showSpecifyModal}
      onClose={() => setShowSpecifyModal(false)}
      styles={{
        content: {
          borderRadius: 20,
        },
        close: {
          color: 'var(--mantine-color-icon-0)',
          height: 'fit-content',
          marginRight: 20,
          marginTop: 20,
          position: 'absolute',
          right: 25,
          top: 20,
          width: 'fit-content',
          '& svg': {
            height: '24px !important',
            width: '24px !important',
          },
        },
      }}
    >
      <Flex direction="column" gap={10} px={40} pt={20} pb={40}>
        <Text fz="1.5rem" fw={700}>
          Specify{' '}
          <Text span tt="capitalize" fz="1.5rem" fw={700}>
            {deployment_method}s
          </Text>
        </Text>
        <Divider orientation="horizontal" size="xs" color="border.1" />
        <Text fz="1.05rem" fw={400} mt={20} mb={5}>
          Your project is deployed using <Text span>{deployment_method}</Text>s.
          You must specify the <Text span>{deployment_method}</Text>s for these
          languages in order for them to be added to your project, otherwise the
          language will not appear in the language selector.
          <br />
          <br />
          <UnstyledButton
            bg="transparent"
            c="text.8"
            fw={600}
            onClick={() => addLanguages(true)}
            px={0}
          >
            Go to your deployment configuration
          </UnstyledButton>
        </Text>
        <Divider orientation="horizontal" size="xs" color="border.1" />
        <Flex mt={20} w="100%" justify="end" columnGap={20}>
          <Button variant="outline" onClick={() => setShowSpecifyModal(false)}>
            Cancel
          </Button>
          <Button onClick={() => addLanguages()}>Confirm</Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
