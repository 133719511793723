import React, { useEffect, useState } from 'react';
import {
  BackgroundImage,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
} from '@mantine/core';
import { FaArrowRight } from 'react-icons/fa6';
import BackgroundLogo from './background-logo.svg';
import { Link } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useGLGOParams } from 'hooks';
import { useMediaQuery } from '@mantine/hooks';
import EstimateCalculation from './EstimateCalculation';
import { CloseIcon } from '../../../Generic';
import {
  querySkus,
  QuickQuoteResponse,
  useCurrentProjectData,
  useRecommendedThresholdContext,
  useUserProvider,
  xapisEnv,
} from 'store';
import {
  calculateEstimatedWordsServed,
  calculateMonthlyPageViews,
  getRecommendedPlan,
} from 'helpers';

type Props = {
  header: React.ReactNode;
  setShowEstimate: React.Dispatch<React.SetStateAction<boolean>>;
  quickQuote: QuickQuoteResponse | null;
};

const WordsServedEstimate = ({
  header,
  setShowEstimate,
  quickQuote,
}: Props) => {
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const { glgoMarketingUrl } = xapisEnv.getHost;

  const { projectId } = useGLGOParams();
  const { xapisUser: { email = '' } = {} } = useUserProvider();
  const setThreshold = useRecommendedThresholdContext().setThreshold;
  const { project } = useCurrentProjectData();
  const { origin_name = '' } = project;

  const { payKey } = xapisEnv.getHost;
  const { data: skus } = useSuspenseQuery(querySkus(payKey));

  const {
    spider_job: {
      num_words = '0',
      num_pages = '0',
      average_words_per_page = 0,
    } = {},
    traffic: { results: { visits = '0', pages_per_visit = '0' } = {} } = {},
  } = quickQuote || {};

  const [translatedTraffic, setTranslatedTraffic] = useState(10);

  const monthlyPageViews = calculateMonthlyPageViews(visits, pages_per_visit);
  const estimatedWordsServed = calculateEstimatedWordsServed(
    translatedTraffic,
    average_words_per_page,
    monthlyPageViews
  );
  const { name = '', threshold_value = 0 } = getRecommendedPlan(
    estimatedWordsServed,
    skus
  );

  useEffect(() => {
    // SubscribeButton in plan details and the main header needs access to the threshold value
    setThreshold(threshold_value);
  }, [setThreshold, threshold_value]);

  const isEnterprisePlan = name.toLowerCase().includes('enterprise');
  const subscribeRoute = `/${projectId}/account/billing-information/manage-subscription?threshold=${threshold_value}`;
  const contactUsUrl = `${glgoMarketingUrl}/about-us#contact-us-form`;

  const analyzedWords = num_words.replace('+', '').toLocaleString();
  const analyzedPages = num_pages.replace('+', '').toLocaleString();

  return (
    <>
      <Group>{header}</Group>
      <Card p={0}>
        <Box
          py="1rem"
          pl="1rem"
          pr={{ base: '5rem', xs: '1rem' }}
          bg="background2.2"
        >
          <Text
            c="text.0"
            fw={700}
            fz="1.25rem"
            ta={{ base: 'left', xs: 'center' }}
          >
            Words Served Estimate
          </Text>
          <Center top={15} right={15} pos="absolute">
            <CloseIcon onClick={() => setShowEstimate(false)} />
          </Center>
        </Box>
        <Flex wrap={{ base: 'wrap', md: 'nowrap' }}>
          <BackgroundImage
            w="100%"
            maw={{ base: '100%', md: '50%' }}
            px="1rem"
            src={`"${BackgroundLogo}"`}
          >
            <Stack align="center" py="5rem">
              <Text ta="center" fz="1.25rem" fw={600}>
                You're on a free trial.
              </Text>
              <Text ta="center" fz="1.15rem">
                Based on our scan of your website, here’s what we’d recommend.
              </Text>
              <Text ta="center" fz="1.75rem" fw={700}>
                {name}
              </Text>
              <Link
                to={isEnterprisePlan ? contactUsUrl : subscribeRoute}
                target={isEnterprisePlan ? '_blank' : '_self'}
              >
                <Button bg="button.0" w="18rem" h="2.75rem">
                  {isEnterprisePlan ? 'Contact Us' : 'Subscribe'}
                </Button>
              </Link>
              <Link
                to={`${glgoMarketingUrl}/estimate?domain=${origin_name}&email=${email}`}
                target="_blank"
              >
                <Button
                  w="fit-content"
                  bg="transparent"
                  c="text.8"
                  fz="1.15rem"
                  rightSection={<FaArrowRight />}
                >
                  See Full Analysis
                </Button>
              </Link>
            </Stack>
          </BackgroundImage>
          {!isMobileSize && (
            <Divider
              orientation="vertical"
              mx="0.5rem"
              my="2rem"
              color="divider.1"
            />
          )}
          <Box w="100%" maw={{ base: '100%', md: '50%' }}>
            <EstimateCalculation
              monthlyPageViews={monthlyPageViews}
              estimatedWordsServed={estimatedWordsServed}
              analyzedWords={analyzedWords}
              analyzedPages={analyzedPages}
              averageWordsPerPage={average_words_per_page}
              translatedTraffic={translatedTraffic}
              setTranslatedTraffic={setTranslatedTraffic}
            />
          </Box>
        </Flex>
      </Card>
    </>
  );
};

export default WordsServedEstimate;
