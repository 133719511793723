import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Card,
  Divider,
  Grid,
  Progress,
  ScrollArea,
  Text,
  Tooltip,
} from '@mantine/core';
import { TranslatedTrafficUrl } from 'store';
import LoadingSkeleton from '../godashboard/LoadingSkeleton';
import { NoDataFoundPlaceHolder } from '../../Generic';

const { Col } = Grid;

type Props = {
  translationKeys: string[];
  urls: TranslatedTrafficUrl[];
  loading: boolean;
};

const TranslatedTrafficTable = ({ translationKeys, urls, loading }: Props) => {
  const contentUrlMap = useMemo(() => {
    return urls.reduce((map, urlObj) => {
      const { content_url = '' } = urlObj || {};
      const totalTraffic = translationKeys.reduce((accum, tKey) => {
        return (accum += urlObj[tKey] || 0);
      }, 0);
      map.set(content_url, totalTraffic);
      return map;
    }, new Map());
  }, [urls, translationKeys]);

  const totalTranslatedTraffic: number = useMemo(() => {
    return [...contentUrlMap.values()].reduce((accum, count) => {
      return accum + count;
    }, 0);
  }, [contentUrlMap]);

  const getTranslatedTrafficPercentage = useCallback(
    (count: number) => {
      return Math.floor((count * 100) / totalTranslatedTraffic);
    },
    [totalTranslatedTraffic]
  );

  const contentUrlTraffic = useMemo(() => {
    return [...contentUrlMap.entries()]
      .map((entry) => {
        const [key, count] = entry || ['', 0];
        return {
          page: key,
          visits: count,
          percentage: getTranslatedTrafficPercentage(count),
        };
      })
      .filter(({ visits = 0, percentage = 0 }) => {
        return visits && percentage;
      })
      .sort((objA, objB) => {
        const { visits: visitsA = 0 } = objA || {};
        const { visits: visitsB = 0 } = objB || {};
        return visitsB - visitsA;
      });
  }, [contentUrlMap, getTranslatedTrafficPercentage]);

  return (
    <Box mt={30} w="100%" p={0}>
      <Card p={0}>
        <Grid w="100%" m={0} py={10} bg="background2.5">
          <Col pl={30} span={{ base: 7, sm: 6 }}>
            <Text fw={700} p={8}>
              PAGES
            </Text>
          </Col>
          <Col span={{ base: 5, sm: 6 }}>
            <Text fw={700} py={8}>
              VISITS
            </Text>
          </Col>
        </Grid>
        <ScrollArea
          data-testid="pw-translated-traffic-card-2"
          w="100%"
          mt={5}
          styles={{
            viewport: {
              '& > div': {
                display: 'block !important',
              },
            },
          }}
          type="always"
          offsetScrollbars
        >
          <Box h={450} w="100%" pl={30} pr={20}>
            {loading ? (
              <LoadingSkeleton height={450} />
            ) : contentUrlTraffic.length === 0 ? (
              <NoDataFoundPlaceHolder height={450} />
            ) : (
              <></>
            )}
            {contentUrlTraffic.map(
              ({ page = '', visits = 0, percentage = 0 }, index) => (
                <Box w="100%" key={page}>
                  {index !== 0 && index !== contentUrlTraffic.length ? (
                    <Divider
                      w="100%"
                      orientation="horizontal"
                      size="xs"
                      color="divider.2"
                    />
                  ) : (
                    <></>
                  )}
                  <Grid align="center" display="flex" py="0.7rem">
                    <Col span={{ base: 7, sm: 6 }}>
                      <Tooltip
                        events={{ hover: true, touch: true, focus: false }}
                        label={page}
                      >
                        <Text fw={400} fz="1rem" truncate>
                          {page}
                        </Text>
                      </Tooltip>
                    </Col>
                    <Col span={{ base: 5, sm: 2 }}>
                      <Tooltip
                        position="top"
                        events={{ hover: true, touch: true, focus: false }}
                        label={visits}
                      >
                        <Text fw={700} fz="1rem" truncate>
                          {visits.toLocaleString() || 0}
                        </Text>
                      </Tooltip>
                    </Col>
                    <Col
                      display={{ base: 'none', sm: 'block' }}
                      span={{ base: 0, sm: 'auto' }}
                    >
                      <Tooltip
                        position="top"
                        events={{ hover: true, touch: true, focus: false }}
                        label="This is the percentage of total translated traffic that went to this particular page"
                      >
                        <Progress
                          bg="background.0"
                          w="100%"
                          value={percentage}
                        />
                      </Tooltip>
                    </Col>

                    <Col
                      display={{ base: 'none', sm: 'block' }}
                      span={{ base: 0, sm: 1 }}
                    >
                      <Tooltip
                        position="top"
                        events={{ hover: true, touch: true, focus: false }}
                        label="This is the percentage of total translated traffic that went to this particular page"
                      >
                        <Text fw={700} fz="1rem">
                          {percentage}%
                        </Text>
                      </Tooltip>
                    </Col>
                  </Grid>
                </Box>
              )
            )}
          </Box>
        </ScrollArea>
      </Card>
    </Box>
  );
};

export default TranslatedTrafficTable;
