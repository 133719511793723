import React from 'react';
import {
  Divider,
  Text,
  Modal,
  Flex,
  Button,
  Card,
  useMantineTheme,
  Box,
  Tooltip,
} from '@mantine/core';
import { Method, deploymentMethods } from './utils';
import { getStringEquality } from 'helpers';
import classes from './ChangeMethodModal.module.css';
import { useDeploymentContext } from './DeploymentContextProvider';

const ChangeMethodModal = () => {
  const { colors } = useMantineTheme();

  const {
    showChangeMethodModal: opened,
    setShowChangeMethodModal: setOpened,
    setShowConfirmMethodModal,
    selectedMethod,
    setSelectedMethod,
    activeProject: {
      deployment_method: defaultMethod = '',
      origin_name: url = '',
    } = {},
  } = useDeploymentContext();

  return (
    <>
      <Modal
        centered
        size={680}
        radius="lg"
        opened={opened}
        withCloseButton={false}
        closeOnClickOutside={false}
        onClose={() => {
          setOpened(false);
          setSelectedMethod('');
        }}
      >
        <Flex p="2rem" direction="column" rowGap="1.5rem">
          <Text fz="1.65rem" fw={700}>
            Change your deployment method
          </Text>
          <Divider size="xs" orientation="horizontal" color="divider.1" />
          {Object.keys(deploymentMethods).map((method: string) => {
            const isSelected = method === selectedMethod;

            const { Icon = () => <></>, UrlPreview = () => <></> } =
              deploymentMethods[method as Method] || {};

            const isDefaultMethod = getStringEquality(method, defaultMethod);

            if (isDefaultMethod) {
              return null;
            }

            return (
              <Card
                key={method}
                px="1rem"
                py="0.75rem"
                pos="relative"
                className={classes.changeMethodCard}
                style={{
                  borderColor: isSelected ? colors.border[8] : colors.border[1],
                  overflow: 'visible',
                  borderRadius: 6,
                }}
              >
                <Flex align="center" justify="space-between">
                  <Flex w="22%" columnGap="1rem" align="center">
                    <Icon colors={colors} />
                    <Text fz="1.15rem" fw={600} c="text2.6" tt="capitalize">
                      {method}
                    </Text>
                  </Flex>
                  <Tooltip
                    position="top"
                    events={{ hover: true, touch: true, focus: false }}
                    label={
                      <UrlPreview
                        url={url}
                        textColor="text.2"
                        fontSize="auto"
                      />
                    }
                    zIndex={1000}
                  >
                    <Box w="58%" pr="0.5rem">
                      <Text truncate fz="1.15rem" fw={600} c="text2.6">
                        |&nbsp;&nbsp;&nbsp;
                        <UrlPreview url={url} />
                      </Text>
                    </Box>
                  </Tooltip>
                  <Button
                    w="6rem"
                    bg={isSelected ? 'background2.2' : 'background.0'}
                    c={isSelected ? 'white' : 'text.8'}
                    p={0}
                    fz="1rem"
                    style={{
                      border: isSelected
                        ? 'none'
                        : `1px solid ${colors.border[8]}`,
                    }}
                    onClick={() => setSelectedMethod(method)}
                  >
                    Select
                  </Button>
                </Flex>
              </Card>
            );
          })}
          <Divider size="xs" orientation="horizontal" color="divider.1" />
          <Flex justify="end" gap={20}>
            <Button
              fz="1rem"
              className={classes.actionButton}
              onClick={async () => {
                setOpened(false);
                setSelectedMethod('');
              }}
              variant="outline"
            >
              Back
            </Button>
            <Button
              fz="1rem"
              className={classes.actionButton}
              mb={{ base: '0.5rem', sm: 0 }}
              onClick={() => {
                setOpened(false);
                setShowConfirmMethodModal(true);
              }}
              disabled={!selectedMethod}
            >
              Continue
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default ChangeMethodModal;
