import React from 'react';
import { Anchor, Card, Divider, Flex, List, Text } from '@mantine/core';
import ContactUsModal from '../../../goheader/ContactUsModal';
import { useGLGOParams } from 'hooks';

type Props = {
  isMobileSize: boolean;
};

function ScopeExplanationCard({ isMobileSize }: Props) {
  const { projectId } = useGLGOParams();

  return (
    <>
      <ContactUsModal />
      <Card
        h="100%"
        w={isMobileSize ? '100%' : '47.5%'}
        mt={{ base: 15, md: 0 }}
        ml={{ base: 0, md: 30 }}
        px={{ base: 20, xs: 30, sm: 30 }}
        py={{ base: 20, xs: 30, sm: 40 }}
      >
        <Flex direction="column" rowGap={20}>
          <Text c="text.2" fw="700" fz="1.75rem" ta="center">
            What is the scope for your Project?
          </Text>
          <Divider
            w="100%"
            my={5}
            orientation="horizontal"
            size="sm"
            color="border.1"
          />
          <Text c="text.2" fw="500" fz="1rem">
            For various reasons, there will inevitably be content on your site
            that doesn't require translation. Everything that is in scope will
            be translated. Anything out-of-scope will not be translated.
          </Text>
          <Text c="text.2" fw="500" fz="1rem">
            There are multiple ways to manage what should and shouldn’t be
            translated.
          </Text>
          <List c="text.2" fw="500" fz="1rem">
            <List.Item>
              Individual words and phrases are best managed in the&nbsp;
              <Anchor href={`/${projectId}/translations/glossary`}>
                Glossary
              </Anchor>
              .
            </List.Item>
            <List.Item>
              Content blocks or components are best managed using a CSS class.
            </List.Item>
            <List.Item>
              Entire pages and your site map as a whole are best managed here in
              Project Scope.
            </List.Item>
          </List>
          <Text c="text.2" fw="500" fz="1rem">
            To learn more,&nbsp;
            <Anchor href="https://support.globallinkgo.com/article/124-can-i-limit-what-parts-of-my-site-are-translated">
              see here
            </Anchor>
            .
          </Text>
        </Flex>
      </Card>
    </>
  );
}

export default ScopeExplanationCard;
