import {
  defaultUser,
  filterProject,
  Poster,
  Project,
  startSpiderJob,
  usePayKeyStore,
  useProjectsStore,
  useProjectTranslationStore,
  xapisEnv,
} from 'store';
import { notifications } from '@mantine/notifications';
import { createHubSpotContact, handleHubSpotContact } from 'integrations';
import { AuthContextProps } from 'react-oidc-context';
import { AccountForm } from './types';
import {
  BOTTOM_LEFT_POSITION,
  DARK_THEME,
  getCustomCSS,
  getLanguageSelectorPositions,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
} from './LanguageSelectorAppearances';
import { asciiToHex, LIVE } from 'helpers';

const handleError = (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  message?: string | null | undefined,
  autoClose: boolean = true
) => {
  if (typeof message === 'string' && message.length > 0) {
    notifications.show({
      message:
        message ||
        'Unable to process user at this time. Please try logging in or forgot password.',
      color: 'red',
      autoClose,
      // id,
    });
  }
  setLoading(false);
};

export const handleCreateProject = (
  isCreatingProject: boolean,
  setIsCreatingProject: React.Dispatch<React.SetStateAction<boolean>>,
  setActiveStep: React.Dispatch<React.SetStateAction<number>>,
  selectedSourceLangCode: string | null,
  selectedTargetLangCodes: string[] = [],
  selectedTheme: LanguageSelectorTheme = DARK_THEME,
  selectedPosition: LanguageSelectorPosition = BOTTOM_LEFT_POSITION,
  domain: string = '',
  user: User = defaultUser,
  setXapisUser: (user: User) => void
) => {
  if (isCreatingProject) return;
  setIsCreatingProject(true);

  const { payKey: pay_key = '' } = usePayKeyStore.getState();
  const { setActiveProject, setProjects, setXapisProjects } =
    useProjectsStore.getState();

  const { email = '' } = user;

  const { topBottom, leftRight } =
    getLanguageSelectorPositions(selectedPosition);
  const customCSS = getCustomCSS(topBottom, leftRight, selectedTheme);

  Poster(
    'Register',
    {
      domain,
      pay_key,
      source_lang_code: selectedSourceLangCode,
      targets: selectedTargetLangCodes,
      email: email,
      custom_css: asciiToHex(customCSS),
    },
    ({ data }) => {
      const { project_key = '', project_keys: projects = [] } = data;

      const filteredProjects: Project[] = projects.map(filterProject);

      if (filteredProjects.length > 0) {
        const projectFound = filteredProjects.find(({ projectKey }) => {
          return projectKey === project_key;
        });

        if (projectFound?.projectKey) {
          setProjects(filteredProjects);
          setXapisProjects(projects);
          setActiveProject(projectFound);
          setXapisUser({ ...user, project_keys: projects });
          const fetchTargets =
            useProjectTranslationStore.getState().fetchTargets;
          fetchTargets(project_key, undefined, () => startSpiderJob());
          setIsCreatingProject(false);
          setActiveStep((prevStep) => prevStep + 0.5);

          const { label = '' } = xapisEnv.getHost;
          const isLiveEnv = label === LIVE;

          if (isLiveEnv) {
            handleHubSpotContact(
              {
                properties: {
                  website: domain,
                },
              },
              email
            );
          }
        }
      }
    },
    {
      errorMessage: 'Unable to create project at this time. Please try again.',
    }
  );
};

export const handleCreateAccount = (
  formParams: Partial<AccountForm>,
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIsAlreadyCreated: React.Dispatch<React.SetStateAction<boolean>>,
  auth: AuthContextProps
) => {
  const notificationId = 'register';
  notifications.hide(notificationId);
  if (loading) return;
  setIsAlreadyCreated(false);
  setLoading(true);

  const {
    email = '',
    password = '',
    first_name = '',
    last_name = '',
    subscribed_to_marketing = false,
  } = formParams;

  const payKey = usePayKeyStore.getState().payKey;
  const userInfo = {
    email,
    password,
    first_name,
    last_name,
    pay_key: payKey,
  };

  Poster(
    'Register',
    userInfo,
    async ({ data }) => {
      const { user_key: userKey = '', otac = '', message = '' } = data;
      if (userKey) {
        if (message === 'already created.') {
          setIsAlreadyCreated(true);
          handleError(
            setLoading,
            'Account already created. Please try logging in.',
            false
          );
          return;
        }

        const { label = '' } = xapisEnv.getHost;

        const isLiveEnv = label === LIVE;

        if (isLiveEnv) {
          const todaysDate = new Date();
          const todaysDateInMS = Date.UTC(
            todaysDate.getUTCFullYear(),
            todaysDate.getUTCMonth(),
            todaysDate.getUTCDate()
          );

          createHubSpotContact({
            properties: {
              email: email,
              hs_all_assigned_business_unit_ids: 1557374,
              glgo_account_create_date: todaysDateInMS,
              hs_marketable_status: true,
              hs_marketable_reason_type: 'FORM_SUBMISSION',
              subscribed_to_marketing,
            },
          });
        }

        if (otac) {
          auth
            .signinRedirect({
              extraQueryParams: { acr_values: `otac:${otac}` },
            })
            .catch((error) => {
              console.error('Auth Error: ', error);
              handleError(setLoading);
            });
        } else {
          handleError(
            setLoading,
            'Account created, unable to access auto login. Please log in manually',
            false
          );
        }
      }
    },
    {
      errorMessage: 'Unable to create account at this time.',
      action: () => handleError(setLoading, null),
    },
    { 'user-only': 1, generate_otac: 1 }
  );
};
