import { Xapis, ProjectResponse, getUserKey } from '../../xapis-wrappers/xapis';
import { defer, Params } from 'react-router-dom';
import { decodeProjectId } from 'helpers';
import { xapisEnv } from '../../service-wrappers/xapisService';

import {
  Subscription,
  fetchSubscriptionsPromise,
} from '../../PaySubscriptionStore';
import { PaymentSource, fetchSourcesPromise } from '../../PaySourceStore';
import { SkuWCreatedAndLastMod } from '../../types';
import {
  BillingHistory,
  fetchBillingHistoryPromise,
} from '../../PayInvoiceStore';
import { QuickQuoteResponse, getQuickQuote } from '../../quickQuote';
import { AxiosResponse } from 'axios';
import { fetchSkusPromise } from '../../PaySkuStore';

export type BillingLoaderData = {
  status: number;
  project?: ProjectKey | undefined;
  message?: string | undefined;
  subscriptions: Subscription[];
  sources: PaymentSource[];
  skus: SkuWCreatedAndLastMod[];
  billingHistory: BillingHistory[];
  default_source_id: string;
  recommendedThresholdValue: number;
  quickQuotePromise:
    | Promise<AxiosResponse<QuickQuoteResponse>>
    | Record<string, never>;
};

export async function billingLoader({
  params,
}: {
  params: Params<string>;
  request: Request;
}): Promise<BillingLoaderData | ReturnType<typeof defer>> {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  const { payKey } = xapisEnv.getHost;
  const user_key = getUserKey();

  const projectKey = decodeProjectId(projectId);
  const projectResponse: ProjectResponse = await Xapis.Project.get(projectKey);
  const subscription_status =
    projectResponse.project?.subscription_status ?? 'expired';

  const fetches = [
    fetchSubscriptionsPromise(
      payKey,
      projectKey,
      subscription_status === 'expired'
    ),
    fetchSourcesPromise(payKey, user_key),
    fetchBillingHistoryPromise(payKey, projectKey),
    fetchSkusPromise(payKey),
  ];

  let subscriptions = [] as Subscription[],
    sources = [] as PaymentSource[],
    orders = [] as BillingHistory[],
    default_source_id = '',
    skus = [] as SkuWCreatedAndLastMod[];

  try {
    const responses = await Promise.allSettled([...fetches]);

    const [subscriptionsRes, sourcesRes, billingHistoryRes, skusRes] =
      responses;

    if (
      subscriptionsRes.status === 'fulfilled' &&
      subscriptionsRes.value.status === 200
    ) {
      const { data } = subscriptionsRes.value;

      subscriptions = data.subscriptions;
    }

    if (sourcesRes.status === 'fulfilled' && sourcesRes.value.status === 200) {
      const { data } = sourcesRes.value;
      sources = data.payment_sources;
      default_source_id = data.default_source_id;
    }

    if (
      billingHistoryRes.status === 'fulfilled' &&
      billingHistoryRes.value.status === 200
    ) {
      const { data } = billingHistoryRes.value;
      orders = data.orders;
    }

    if (
      billingHistoryRes.status === 'fulfilled' &&
      billingHistoryRes.value.status === 200
    ) {
      const { data } = billingHistoryRes.value;
      orders = data.orders;
    }

    if (skusRes.status === 'fulfilled' && skusRes.value.status === 200) {
      const { data } = skusRes.value;
      skus = data.skus;
    }
  } catch (error) {
    console.error('ERROR from Promise.allSettled:', error);
  }

  const data = {
    billingHistory: orders,
    subscriptions,
    default_source_id,
    sources,
    skus,
    ...projectResponse,
    ...(projectResponse.project?.origin_name && {
      quickQuotePromise: getQuickQuote(projectResponse.project.origin_name),
    }),
  };

  return defer(data);
}
