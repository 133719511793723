import React from 'react';
import PlanInformation from './plan-information/PlanInformation';
import { BasicHorizontalDivider } from '../../Generic';
import BillingHistory from './BillingHistory';
import { BillingLoaderData } from 'store';
import { useRouteLoaderData } from 'react-router-dom';

export const BillingInformation = () => {
  const { subscriptions } = useRouteLoaderData('billing') as BillingLoaderData;

  const { price = 0 } = subscriptions[0] || {};
  const isPaidPlan = subscriptions.length > 0 && price !== 0;

  return (
    <>
      <PlanInformation />

      {isPaidPlan && (
        <>
          <BasicHorizontalDivider borderWidth={2} mobileMargin="0 auto" />
          <BillingHistory />
        </>
      )}
    </>
  );
};
