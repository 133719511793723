import React from 'react';
import { Button, Flex, Skeleton, Text } from '@mantine/core';
import CurrentPlan from './CurrentPlan';
import classes from './CurrentPlan.module.css';
import { useNavigation } from 'react-router-dom';
import { Subscription } from 'store';
import ManageSubButton from './ManageSubButton';

const SubscriptionInfoBlock = ({
  loading,
  subscriptions,
  isExpired,
}: {
  loading: boolean;
  subscriptions: Subscription[];
  isExpired: boolean;
}) => {
  if (loading) {
    <Flex direction="column" gap={5}>
      <Skeleton h={8} width={200} />
      <Skeleton h={8} width={230} />
      <Skeleton h={8} width={260} />
      <Skeleton h={8} width={250} />
    </Flex>;
  }
  if (subscriptions.length === 0)
    return <Text>No subscription info found.</Text>;

  const [currentSub] = subscriptions;

  return <CurrentPlan sub={currentSub} isExpired={isExpired} />;
};

type Props = {
  subscriptions: Subscription[];
  isExpired: boolean;
  openRestartModal: () => void;
  isCancelled: boolean;
};

const PaidPlanInformation = ({
  subscriptions,
  isExpired,
  openRestartModal,
  isCancelled,
}: Props) => {
  const loading = useNavigation().state !== 'idle';

  return (
    <>
      <Flex
        align={{ base: 'start', lg: 'center' }}
        direction={{ base: 'column', lg: 'row' }}
        justify={{ base: 'start', lg: 'space-between' }}
        mb="1.5rem"
        columnGap="1rem"
      >
        <Text tt="uppercase" fw={700} size="1.25rem" truncate>
          Current Plan / Threshold
        </Text>
        {isCancelled ? (
          <Button
            px={0}
            size="md"
            c="text.8"
            classNames={{
              root: classes.manageSubsButton,
              inner: classes.subsButtonInner,
              label: classes.subsButtonLabel,
            }}
            variant="subtle"
            onClick={openRestartModal}
          >
            Restart Subscription
          </Button>
        ) : (
          <ManageSubButton>Manage Subscription</ManageSubButton>
        )}
      </Flex>
      <SubscriptionInfoBlock
        loading={loading}
        subscriptions={subscriptions}
        isExpired={isExpired}
      />
    </>
  );
};

export default PaidPlanInformation;
