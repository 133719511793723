import React from 'react';
import { Flex, Text, Paper, useMantineTheme, Button } from '@mantine/core';
import { Link, useAsyncValue, useRouteLoaderData } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import {
  BillingLoaderData,
  QuickQuoteResponse,
  useUserProvider,
  xapisEnv,
} from 'store';
import LoadingSkeleton from '../../godashboard/LoadingSkeleton';
import EstimateCalculation from '../../godashboard/words-served-estimate/EstimateCalculation';
import { AxiosResponse } from 'axios';
import {
  calculateEstimatedWordsServed,
  calculateMonthlyPageViews,
} from 'helpers';

type Props = {
  translatedTraffic: number;
  setTranslatedTraffic: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
  isQuickQuoteCached?: boolean;
};
const EstimateInformation = ({
  translatedTraffic,
  setTranslatedTraffic,
  loading,
  isQuickQuoteCached,
}: Props) => {
  const { colors } = useMantineTheme();
  const { glgoMarketingUrl } = xapisEnv.getHost;
  const { xapisUser: { email = '' } = {} } = useUserProvider();

  const { project: { origin_name = '' } = {} } = useRouteLoaderData(
    'billing'
  ) as BillingLoaderData;

  const {
    data: {
      spider_job: {
        num_words = '0',
        num_pages = '0',
        average_words_per_page = 0,
      } = {},
      traffic: { results: { visits = '0', pages_per_visit = '0' } = {} } = {},
    } = {},
  } = (useAsyncValue() as AxiosResponse<QuickQuoteResponse>) || {};

  const monthlyPageViews = calculateMonthlyPageViews(visits, pages_per_visit);
  const estimatedWordsServed = calculateEstimatedWordsServed(
    translatedTraffic,
    average_words_per_page,
    monthlyPageViews
  );
  const analyzedWords = num_words.replace('+', '').toLocaleString();
  const analyzedPages = num_pages.replace('+', '').toLocaleString();

  return (
    <Flex w="100%" h="100%" rowGap="1rem" direction="column" justify="center">
      <Flex
        mih="2.25rem"
        justify="space-between"
        align="center"
        columnGap="1rem"
      >
        <Text fw={700} tt="uppercase" truncate>
          Words Served Estimate
        </Text>
        <Link
          to={`${glgoMarketingUrl}/estimate?domain=${origin_name}&email=${email}`}
          target="_blank"
        >
          <Button
            w="fit-content"
            bg="transparent"
            c="text.8"
            rightSection={<FaArrowRight />}
          >
            See Full Analysis
          </Button>
        </Link>
      </Flex>
      <Paper
        h="100%"
        p="2rem"
        style={{
          borderRadius: 12,
          border: `1px solid ${colors.border[1]}`,
          boxShadow: 'none',
        }}
      >
        {loading ? (
          <LoadingSkeleton />
        ) : isQuickQuoteCached ? (
          <EstimateCalculation
            monthlyPageViews={monthlyPageViews}
            estimatedWordsServed={estimatedWordsServed}
            analyzedWords={analyzedWords}
            analyzedPages={analyzedPages}
            averageWordsPerPage={average_words_per_page}
            translatedTraffic={translatedTraffic}
            setTranslatedTraffic={setTranslatedTraffic}
            stackProps={{ px: 0, py: 0, gap: '1rem' }}
          />
        ) : (
          <Text>Estimate Unavailable</Text>
        )}
      </Paper>
    </Flex>
  );
};

export default EstimateInformation;
