import { Glossary, NoTranslateLanding } from 'ui';
import { Languages } from './Languages';
import { dashboardRC } from 'helpers';
import ErrorBoundary from 'ui/components/navigation/dashboard/ErrorBoundary';
import { glossaryLoader } from 'store';

export const routes = [
  {
    path: 'languages',
    loader: glossaryLoader,
    element: <Languages />,
  },
  {
    path: 'glossary',
    loader: glossaryLoader,
    element: <Glossary />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'no-translate-rules',
    element: <NoTranslateLanding />,
  },
];

export const settings = {
  path: dashboardRC.PAGE_TRANSLATIONS,
  defaultTab: routes[0].path,
};
