import React from 'react';
import {
  Badge,
  Box,
  Card,
  Container,
  Flex,
  Grid,
  Group,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { CardInterface } from '../../../../types';
import styles from './Dashboard.styles';
import { Subscription } from 'store';
import getPrettyDate from 'helpers/utils/getPrettyDate';
import {
  getRemainingSubscribedWordsServed,
  getRemainingSubscriptionDays,
} from 'helpers';
import SubscribeButton from '../shared/SubscribeButton';

type Props = CardInterface & {
  subscriptions: Subscription[];
  wordsServedAmount: number;
  isExpiredPlan: boolean;
};

export const PlanDetails = ({
  children,
  header,
  subscriptions,
  wordsServedAmount,
  isExpiredPlan,
}: Props) => {
  const theme = useMantineTheme();

  const {
    next_renewal_date: nextRenewalDate = '',
    sku_name: skuName = 'GlobalLink GO Free',
    subscription_type: subscriptionType = 'MONTHLY',
    price: planPrice = 0,
  } = subscriptions[0] || {};

  const subscriptionDate = getPrettyDate(nextRenewalDate);

  const { is_canceled } =
    subscriptions.length > 0 ? subscriptions[0] : { is_canceled: true };
  const isPaidPlan = subscriptions.length > 0 && planPrice !== 0;

  const remainingDays = getRemainingSubscriptionDays(nextRenewalDate);
  const remainingWordsServed =
    getRemainingSubscribedWordsServed(wordsServedAmount);

  const getSubscriptionText = (
    isSubscriptionExpired: boolean,
    isCanceled: boolean
  ) => {
    if (isCanceled) {
      return isSubscriptionExpired ? 'EXPIRED DATE' : 'EXPIRATION DATE';
    } else {
      return 'NEXT RENEWAL DATE';
    }
  };

  return (
    <Container m={0} className="glwebContainer">
      <Group>{header}</Group>
      <Card
        data-testid="pw-plan-details-current-plan-container"
        p={{ base: '30px 10px', md: 30 }}
      >
        <Container
          p={0}
          m={0}
          maw="100%"
          className={styles.planContainer(theme, isExpiredPlan)}
        >
          {isPaidPlan ? (
            <Box w="100%">
              <Grid w="100%" align="center" className="planType">
                <Grid.Col span={5}>
                  <Text fw={700} data-testid="pw-plan-details-current-plan">
                    {isExpiredPlan ? 'INACTIVE PLAN' : 'CURRENT PLAN'}
                  </Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  <Flex
                    w="100%"
                    columnGap={10}
                    align="center"
                    wrap={{ base: 'wrap', xs: 'nowrap' }}
                  >
                    <Text>{skuName}</Text>
                    {isPaidPlan && (
                      <Badge size="md" className="planBadge">
                        {subscriptionType[0]}
                        <span style={{ textTransform: 'lowercase' }}>
                          {subscriptionType.substring(1)}
                        </span>
                      </Badge>
                    )}
                  </Flex>
                </Grid.Col>
              </Grid>
              <Grid w="100%" align="center" className="planDate">
                <Grid.Col span={5}>
                  <Text fw={700} w="100%">
                    {getSubscriptionText(isExpiredPlan, is_canceled)}
                  </Text>
                </Grid.Col>
                <Grid.Col span={7}>
                  {!isExpiredPlan ? (
                    <Text w="100%">{subscriptionDate}</Text>
                  ) : (
                    <Text span>
                      {subscriptionDate}
                      <Text c="red" fs="italic" fz="0.9em" fw={500} span>
                        &nbsp;(will not renew)
                      </Text>
                    </Text>
                  )}
                </Grid.Col>
              </Grid>
            </Box>
          ) : (
            <>
              <Grid w="100%" align="center" className="planType">
                {isExpiredPlan ? (
                  <Grid.Col>
                    <Flex
                      gap={{ base: 0, sm: 20 }}
                      align="center"
                      justify={{ base: 'space-between', sm: 'start' }}
                    >
                      <Text>Your free trial has expired.</Text>
                      <SubscribeButton />
                    </Flex>
                  </Grid.Col>
                ) : (
                  <>
                    <Grid.Col span={4}>
                      <Text fw={700} data-testid="pw-plan-details-current-plan">
                        Current Plan:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                      <Flex w="100%" columnGap={10} align="center">
                        <Text>Free Trial</Text>
                        <SubscribeButton />
                      </Flex>
                    </Grid.Col>
                  </>
                )}
              </Grid>
              {!isExpiredPlan && (
                <Grid w="100%" align="center" className="planType">
                  <Grid.Col span={4}>
                    <Text fw={700} data-testid="pw-plan-details-current-plan">
                      Days/Words Remaining:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Flex columnGap={10} align="center">
                      <Text>{`${remainingDays} / ${remainingWordsServed.toLocaleString()}`}</Text>
                    </Flex>
                  </Grid.Col>
                </Grid>
              )}
            </>
          )}
          {children !== undefined && children}
        </Container>
      </Card>
    </Container>
  );
};

export default PlanDetails;
