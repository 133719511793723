import React, { useEffect, useState } from 'react';
import { useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { ProjectLoaderResponse, Xapis } from 'store';
import {
  Button,
  Modal,
  Title,
  TextInput,
  Divider,
  Text,
  Flex,
  MultiSelect,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FaExternalLinkAlt } from 'react-icons/fa';
import {
  ADMIN,
  BILLING,
  DEVELOPER,
  getGroupKey,
  GLGO_ADMIN,
  LINGUIST,
  PROJECT_MANAGER,
  ProjectUserPermission,
  TRANSPERFECT_ADMIN,
  TRANSPERFECT_USER,
  UserRole,
} from 'helpers/utils/projectPermissions';
import { ModalAction } from './UserManagementPage';
import { failure, isSuccessStatus } from 'helpers';
import setDifference from 'helpers/utils/setDifference';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  roles: UserRole[];
  translation_keys: string[];
}

interface Props {
  opened: boolean;
  close: () => void;
  modalAction: ModalAction;
  user: ProjectUserPermission;
}

const UserManagementModal = ({ opened, close, modalAction, user }: Props) => {
  const { project, translations, projectTranslationMap } = useRouteLoaderData(
    'project'
  ) as ProjectLoaderResponse;

  const { groups = [], project_key = '' } = project || {};
  const projectGroupSet = new Set(groups.map(({ group_key }) => group_key));

  const colors = useMantineTheme().colors;

  const targetsOptions = translations.map(
    ({ translation_key = '', target_lang_name = '' }) => {
      return { label: target_lang_name, value: translation_key };
    }
  );

  const revalidator = useRevalidator();

  const [loading, setLoading] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email || '',
      roles: Array.from(user.rolePermissionSet),
      translation_keys: Array.from(user.languagePermissionSet),
    },
  });

  useEffect(() => {
    form.setValues({
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      email: user.email || '',
      roles: Array.from(user.rolePermissionSet),
      translation_keys: Array.from(user.languagePermissionSet),
    });
  }, [user]);

  const formRolesSet: Set<UserRole> = new Set(form.values.roles);
  const formTranslationsSet = new Set(form.values.translation_keys);

  const basicUserRoles = [ADMIN, BILLING, DEVELOPER, PROJECT_MANAGER, LINGUIST];
  const transperfectUserRoles = [
    TRANSPERFECT_ADMIN,
    TRANSPERFECT_USER,
    GLGO_ADMIN,
  ];
  const isTransPerfectUser =
    user.rolePermissionSet.has(TRANSPERFECT_ADMIN) ||
    user.rolePermissionSet.has(TRANSPERFECT_USER) ||
    user.rolePermissionSet.has(GLGO_ADMIN);
  const currentRoles: UserRole[] = isTransPerfectUser
    ? transperfectUserRoles
    : basicUserRoles;
  const roleData = currentRoles.filter(
    (role) =>
      (formRolesSet.has(ADMIN) && role === ADMIN) ||
      (!formRolesSet.has(ADMIN) && role)
  );

  const handleClose = () => {
    form.reset();
    close();
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const getGroupKeysToAdd = (): string[] => {
      const rolesToAddSet = setDifference<UserRole>(
        formRolesSet,
        user.rolePermissionSet
      );
      const isAddingLinguist = formRolesSet.has(LINGUIST);
      rolesToAddSet.delete(LINGUIST);
      const groupKeysToAddArray = Array.from(rolesToAddSet).map((role) =>
        getGroupKey(project_key, role)
      );

      if (isAddingLinguist) {
        const languagesToAddSet = setDifference<string>(
          formTranslationsSet,
          user.languagePermissionSet
        );
        return groupKeysToAddArray.concat(Array.from(languagesToAddSet));
      }

      return groupKeysToAddArray;
    };

    const getGroupKeysToRemove = (): string[] => {
      const rolesToRemoveSet = setDifference<UserRole>(
        user.rolePermissionSet,
        formRolesSet
      );
      const isRemovingLinguist = rolesToRemoveSet.has(LINGUIST);
      rolesToRemoveSet.delete(LINGUIST);
      const groupKeysToRemoveArray = Array.from(rolesToRemoveSet).map((role) =>
        getGroupKey(project_key, role)
      );

      if (isRemovingLinguist) {
        return groupKeysToRemoveArray.concat(
          Array.from(user.languagePermissionSet)
        );
      }

      const languagesToRemoveSet = setDifference<string>(
        user.languagePermissionSet,
        formTranslationsSet
      );
      return groupKeysToRemoveArray.concat(Array.from(languagesToRemoveSet));
    };

    let isSuccessfulApiCallMade = false;
    const getXapisUserPromise = () => {
      const userData = {
        first_name: form.values.first_name,
        last_name: form.values.last_name,
        email: form.values.email,
      };

      if (modalAction === 'Add') {
        return Xapis.User.post(userData).then((response) => {
          isSuccessfulApiCallMade = isSuccessStatus(response.status);
          return response;
        });
      } else {
        const isUserChanged =
          userData.first_name !== user.first_name ||
          userData.last_name !== user.last_name ||
          userData.email !== user.email;

        if (isUserChanged) {
          return Xapis.User.put(user.user_key, userData).then((response) => {
            isSuccessfulApiCallMade = isSuccessStatus(response.status);
            return response;
          });
        }

        // No API call is needed to be made
        return Promise.resolve({
          data: user,
          status: 200,
        });
      }
    };

    // TODO: Doesn't make admin groups like TPT_ADMIN_GROUP_KEY or GLGO_ADMINS_GROUP_KEY
    const getXapisNewGroupPromise = (groupKey: string, userKey: string) => {
      const isTranslationGroup = projectTranslationMap[groupKey];
      const isProjectGroup = groupKey === project_key;

      if (isTranslationGroup) {
        return Xapis.Group.post({
          translation_key: groupKey,
          user_key: userKey,
        });
      }
      if (isProjectGroup) {
        return Xapis.Group.post({
          project_key,
          user_key: userKey,
        });
      }
      return Xapis.Group.post({
        project_key,
        prefix: groupKey.slice(0, groupKey.indexOf('-')),
        user_key: userKey,
      });
    };

    setLoading(true);
    getXapisUserPromise().then((userResponse) => {
      const { data: { user_key: newUserKey = '' } = {}, status } =
        userResponse || {};

      if (isSuccessStatus(status)) {
        const xapisAddGroupUserCalls = getGroupKeysToAdd().map((groupKey) =>
          projectGroupSet.has(groupKey)
            ? Xapis.GroupUser.post(groupKey, [newUserKey])
            : getXapisNewGroupPromise(groupKey, newUserKey)
        );
        const xapisRemoveGroupUserCalls = getGroupKeysToRemove().map(
          (groupKey) => Xapis.GroupUser.delete(groupKey, [newUserKey])
        );
        const xapisGroupUserCalls = xapisAddGroupUserCalls.concat(
          xapisRemoveGroupUserCalls
        );

        Promise.allSettled(xapisGroupUserCalls).then(
          (xapisGroupUserCallResults) => {
            xapisGroupUserCallResults.forEach((xapisGroupUserCallResult) => {
              if (xapisGroupUserCallResult.status === 'fulfilled') {
                const groupUserResponse = xapisGroupUserCallResult.value || {};
                const { status } = groupUserResponse;

                if (isSuccessStatus(status)) {
                  isSuccessfulApiCallMade = true;
                } else {
                  failure(groupUserResponse);
                }
              } else {
                failure(xapisGroupUserCallResult.reason);
              }
            });

            if (isSuccessfulApiCallMade) {
              revalidator.revalidate();
            }

            close();
            setLoading(false);
          }
        );
      } else {
        // The User API call failed, so do not continue with the GroupUser calls
        failure(userResponse);
        setLoading(false);
      }
    });
  };

  const isSubmitDisabled =
    !form.values.first_name ||
    !form.values.last_name ||
    !form.values.email ||
    !form.values.roles.length ||
    (formRolesSet.has(LINGUIST) && !form.values.translation_keys.length);

  return (
    <Modal opened={opened} onClose={handleClose} size="lg" padding="3rem">
      <LoadingOverlay
        visible={loading}
        zIndex={1000}
        overlayProps={{ radius: 'sm', blur: 2 }}
      />
      <Modal.Body p={0}>
        <Title order={3} mx="auto" pb="1rem">{`${modalAction} User`}</Title>
        <Divider />
        <form onSubmit={onSubmit}>
          <Flex direction="column">
            <Text mt="1rem" fw={600}>
              First Name
            </Text>
            <TextInput
              placeholder="John"
              {...form.getInputProps('first_name')}
            />
            <Text mt="1rem" fw={600}>
              Last Name
            </Text>
            <TextInput placeholder="Doe" {...form.getInputProps('last_name')} />
            <Text mt="1rem" fw={600}>
              Email
            </Text>
            <TextInput
              disabled={modalAction === 'Edit'}
              placeholder="johndoe@example.com"
              {...form.getInputProps('email')}
            />
            <Text mt="1rem" fw={600}>
              Role
            </Text>
            <Flex
              align="center"
              justify="space-between"
              style={{ width: '100%' }}
            >
              <MultiSelect
                comboboxProps={{ zIndex: 1000 }}
                placeholder={
                  form.values.roles.length === 0 ? 'Select roles' : ''
                }
                data={roleData}
                style={{ flex: 1 }}
                {...form.getInputProps('roles')}
                onChange={(values) => {
                  values.includes(ADMIN)
                    ? form.setFieldValue('roles', [ADMIN])
                    : form.setFieldValue('roles', values as UserRole[]);
                }}
              />
              <FaExternalLinkAlt
                style={{ marginLeft: 10, cursor: 'pointer' }}
                color={colors.text3[2]}
                onClick={() =>
                  window.open(
                    'https://support.globallinkgo.com/article/145-how-can-i-manage-users-on-my-project',
                    '_blank'
                  )
                }
              />
            </Flex>
            {form.values.roles.includes(LINGUIST) && (
              <>
                <Text fw={600}>Select Languages</Text>
                <MultiSelect
                  comboboxProps={{ zIndex: 1000 }}
                  data={targetsOptions}
                  placeholder={
                    form.values.translation_keys.length === 0
                      ? 'Select languages'
                      : ''
                  }
                  {...form.getInputProps('translation_keys')}
                  onChange={(values) => {
                    form.setFieldValue('translation_keys', values);
                  }}
                />
              </>
            )}
            <Button mt={50} type="submit" disabled={isSubmitDisabled}>
              Submit
            </Button>
          </Flex>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserManagementModal;
