import {
  Button,
  Flex,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Input,
  rem,
  Menu,
  LoadingOverlay,
  Tooltip,
} from '@mantine/core';
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
} from '@tabler/icons-react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { useState } from 'react';
import { CardHeader } from 'ui';
import classes from './UserManagement.module.css';
import { MdSearch } from 'react-icons/md';
import * as React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { useDisclosure } from '@mantine/hooks';
import UserManagementModal from './UserManagementModal';
import { useRevalidator, useRouteLoaderData } from 'react-router-dom';
import { ProjectLoaderResponse, Xapis } from 'store';
import { failure, isSuccessStatus } from 'helpers';
import {
  defaultProjectUserPermission,
  ProjectUserPermission,
} from 'helpers/utils/projectPermissions';

export type UserManagementRowData = ProjectUserPermission & {
  name: string;
  roles: string;
  languages: string;
};

interface UserManagementTableHeadProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

const UserManagementTableHead = ({
  children,
  reversed,
  sorted,
  onSort,
}: UserManagementTableHeadProps) => {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th>
      <UnstyledButton onClick={onSort}>
        <Group justify="space-between">
          <Text fw={700} fz="md">
            {children}
          </Text>
          <Center>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
};

export type ModalAction = 'Add' | 'Edit';

export const UserManagementPage = () => {
  const { projectTranslationMap = {}, projectUserPermissionMap = {} } =
    useRouteLoaderData('project') as ProjectLoaderResponse;

  const revalidator = useRevalidator();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<keyof UserManagementRowData>('');
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [modalAction, setModalAction] = useState<ModalAction>('Add');
  const [currentUserKey, setCurrentUserKey] = useState<string>('');

  const currentUser =
    projectUserPermissionMap[currentUserKey] || defaultProjectUserPermission;

  const filterData = (data: UserManagementRowData[]) => {
    const query = search.toLowerCase().trim();
    return data.filter((rowData) => {
      const { name = '', email = '', roles = '', languages = '' } = rowData;
      return (
        name.toLowerCase().includes(query) ||
        email.toLowerCase().includes(query) ||
        roles.toLowerCase().includes(query) ||
        languages.toLowerCase().includes(query)
      );
    });
  };

  const sortData = (data: UserManagementRowData[]) => {
    if (!sortBy) {
      return data;
    }

    return [...data].sort((a, b) => {
      if (reverseSortDirection) {
        return b[sortBy]?.toString().localeCompare(a[sortBy]?.toString());
      }

      return a[sortBy]?.toString().localeCompare(b[sortBy]?.toString());
    });
  };

  const rowData = filterData(
    sortData(
      Object.values(projectUserPermissionMap).map(
        (user) =>
          ({
            ...user,
            name: `${user.first_name} ${user.last_name}`,
            roles: Array.from(user.rolePermissionSet).join(', '),
            languages: Array.from(user.languagePermissionSet)
              .map(
                (translationKey) =>
                  projectTranslationMap[translationKey].target_lang_name
              )
              .join(', '),
          }) as UserManagementRowData
      )
    )
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
  };

  const setSorting = (field: keyof UserManagementRowData) => {
    setReverseSortDirection(sortBy === field ? !reverseSortDirection : false);
    setSortBy(field);
  };

  const handleRemoveUser = (row: UserManagementRowData) => {
    const { user_key } = row;

    setLoading(true);
    Xapis.User.delete(user_key).then((response) => {
      if (isSuccessStatus(response.status)) {
        revalidator.revalidate();
      } else {
        failure(response);
      }

      setLoading(false);
    });
  };

  return (
    <Flex mt={70} direction="column" rowGap="2rem" pt={10} px={20}>
      <CardHeader
        title={
          <Text className="headerTitle" span>
            Users
          </Text>
        }
      />
      <Flex justify={'space-between'} align="center" w="100%">
        <Input
          maw={500}
          placeholder="Search by any field"
          classNames={{
            input: classes.searchBarInput,
            section: classes.searchBarRightSection,
          }}
          rightSection={<MdSearch color="#00328D" size={20} />}
          value={search}
          onChange={handleSearchChange}
          style={{ flexGrow: 1 }}
        />
        <Button
          fz="0.85rem"
          variant="transparent"
          c="text.8"
          rightSection={<IoMdAdd size={20} />}
          onClick={() => {
            open();
            setModalAction('Add');
            setCurrentUserKey(''); // Clear current user data
          }}
        >
          Add User
        </Button>
      </Flex>
      <UserManagementModal
        opened={opened}
        close={close}
        modalAction={modalAction}
        user={currentUser}
      />
      <ScrollArea
        style={{
          borderRadius: '17px',
          boxShadow: '0px 4px 10px 0px rgba(5, 53, 140, 0.1)',
        }}
      >
        <LoadingOverlay
          visible={loading}
          zIndex={1000}
          overlayProps={{ radius: 'sm', blur: 2 }}
        />
        <Table horizontalSpacing="md" verticalSpacing="xs" layout="fixed">
          <Table.Thead>
            <Table.Tr bg="#EAF0FF">
              <UserManagementTableHead
                sorted={sortBy === 'name'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('name')}
              >
                Name
              </UserManagementTableHead>
              <UserManagementTableHead
                sorted={sortBy === 'email'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('email')}
              >
                Email
              </UserManagementTableHead>
              <UserManagementTableHead
                sorted={sortBy === 'roles'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('roles')}
              >
                Roles
              </UserManagementTableHead>
              <Table.Th className={classes.tableCell}>
                <Text fw={700} fz="md">
                  Languages
                </Text>
              </Table.Th>
              <Table.Th>
                <Text fw={700} fz="md">
                  Actions
                </Text>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rowData.length > 0 ? (
              rowData.map((row) => (
                <Table.Tr key={row.user_key}>
                  <Table.Td className={classes.tableCell}>{row.name}</Table.Td>
                  <Table.Td className={classes.tableCell}>
                    <Tooltip label={row.email} withArrow multiline>
                      <span>{row.email}</span>
                    </Tooltip>
                  </Table.Td>
                  <Table.Td className={classes.tableCell}>{row.roles}</Table.Td>
                  <Table.Td className={classes.tableCell}>
                    {row.languages}
                  </Table.Td>
                  <Table.Td>
                    <Menu>
                      <Menu.Target>
                        <UnstyledButton>
                          <HiOutlineDotsHorizontal
                            style={{ width: rem(16), height: rem(16) }}
                          />
                        </UnstyledButton>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => {
                            setModalAction('Edit');
                            setCurrentUserKey(row.user_key); // Set current user data
                            open();
                          }}
                        >
                          Edit
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            handleRemoveUser(row);
                          }}
                        >
                          Remove
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </Table.Td>
                </Table.Tr>
              ))
            ) : (
              <Table.Tr>
                <Table.Td colSpan={4}>
                  <Text fw={500} ta="center">
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Flex>
  );
};

export default UserManagementPage;
