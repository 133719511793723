import { useRef } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { NavLinkItem } from './SideNav';

export const useDynamicLink = ({ link }: { link: NavLinkItem }) => {
  const { projectId } = useParams();
  const { path, defaultTab } = link;

  const childPath = useRef<string>(defaultTab || '');

  const match = useMatch(`/:projectId/${path}/*`);
  const child = match?.params['*']?.split('/')[0] || defaultTab || '';
  if (match && child !== childPath.current) {
    childPath.current = child;
  }

  const isActive = !!match;
  const defaultChild = defaultTab ? `/${defaultTab}` : '';
  const tab = childPath.current
    ? `${projectId}/${path}/${childPath.current}`
    : `${projectId}/${path}${defaultChild}`;

  return { isActive, tab };
};
