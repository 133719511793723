import { Xapis, ProjectResponse, getUserKey } from '../xapis-wrappers/xapis';
import { Params } from 'react-router-dom';
import {
  createTranslationMap,
  decodeProjectId,
  filterTranslations,
  getYYTarget,
} from 'helpers';
import { xapisEnv } from '../service-wrappers/xapisService';

import {
  Subscription,
  fetchSubscriptionsPromise,
} from '../PaySubscriptionStore';
import {
  createProjectUserPermissionMap,
  UserRole,
} from 'helpers/utils/projectPermissions';
type LoaderArgs = {
  params: Params<string>;
  request: Request;
};

export async function projectLoader({ params }: LoaderArgs) {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const projectKey = decodeProjectId(projectId);

  const response: ProjectResponse = await Xapis.Project.get(projectKey, true);
  const { payKey } = xapisEnv.getHost;
  const { project } = response;

  const projectTranslations = filterTranslations(project?.translations || []);
  const yyTranslation = getYYTarget(project?.translations || []);

  const projectTranslationMap = createTranslationMap(project);
  const projectUserPermissionMap = createProjectUserPermissionMap(
    project,
    projectTranslationMap
  );

  const isUserRole = (userRole: UserRole): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].rolePermissionSet.has(userRole);
  const isTranslationRole = (translationKey: string): boolean =>
    projectUserPermissionMap[getUserKey()] &&
    projectUserPermissionMap[getUserKey()].languagePermissionSet.has(
      translationKey
    );

  const data = {
    isTranslationRole,
    isUserRole,
    projectTranslationMap,
    projectUserPermissionMap,
    subscriptions: [] as Subscription[],
    translations: projectTranslations,
    yyTranslation,
    ...response,
  };

  try {
    const subscriptionsRes = await fetchSubscriptionsPromise(
      payKey,
      projectKey
    );
    const { subscriptions = [] } = subscriptionsRes?.data || {};
    data.subscriptions = subscriptions;
  } catch (error) {
    console.error('subs fetch error inside projectLoader', error);
  }

  return data;
}

export type ProjectLoaderResponse = Awaited<ReturnType<typeof projectLoader>>;
