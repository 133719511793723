import { useEffect, useState, useRef } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { ProjectLoaderResponse } from 'store';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { Button, Tooltip } from '@mantine/core';
import classes from './LanguageCSSEditor.module.css';

const SELECTOR_STYLE_ID = 'glwebSelectorCustomCss';

export const LangSelectorPreview = ({ css }: { css: string }) => {
  const { translations } = useRouteLoaderData(
    'project'
  ) as ProjectLoaderResponse;
  const deploymentValues = translations.map(
    (t) => t.target_native_name || t.target_lang_name || t.target_lang_code
  );
  const [showLangSelectorPreview, setShowLangSelectorPreview] = useState(false);
  const hasSelectorCss = css.includes('language-selector');
  const isMoxieInjected = useRef(
    !!document.getElementById('GLGOLanguageSelector')
  );
  const disablePreview = !hasSelectorCss || isMoxieInjected.current;
  const [selectedValue, setSelectedValue] = useState(
    deploymentValues[Math.floor(Math.random() * deploymentValues.length)]
  );
  const [opened, setOpened] = useState(true);

  const injectCss = (css: string) => {
    const customCssEl = document.getElementById(SELECTOR_STYLE_ID);
    if (customCssEl) {
      customCssEl.innerHTML = css;
    } else {
      const style = document.createElement('style');
      style.id = SELECTOR_STYLE_ID;
      style.innerHTML = css;
      document.head.appendChild(style);
    }
  };

  useEffect(() => {
    if (disablePreview) return;
    injectCss(css);
    return () => {
      const customCssEl = document.getElementById(SELECTOR_STYLE_ID);
      if (customCssEl) {
        customCssEl.remove();
      }
    };
  }, [css, disablePreview]);

  // Disable preview if no selector css or if Moxie is injected
  if (disablePreview) return <div />;

  return (
    <>
      <Tooltip
        label={
          showLangSelectorPreview
            ? 'Close Language selector preview'
            : 'Show language selector preview'
        }
      >
        <Button
          variant="transparent"
          className={classes.icon}
          onClick={() => setShowLangSelectorPreview((p) => !p)}
        >
          <BsMenuButtonWideFill size={24} />
        </Button>
      </Tooltip>
      {showLangSelectorPreview && (
        <div
          id="LanguageSelectorPreview"
          className="OneLinkNoTx GLGO-language-selector"
        >
          <div
            className={`oljs-select-selected${opened ? ' oljs-select-arrow-active' : ''}`}
            onClick={() => setOpened((o) => !o)}
          >
            {selectedValue}
          </div>
          <div
            className={`oljs-select-items${opened ? '' : ' oljs-select-hide'}`}
          >
            {deploymentValues.map((value) => {
              const isSelected = value === selectedValue;
              return (
                <div
                  key={value}
                  className={`oljs-select-item${isSelected ? ' oljs-same-as-selected' : ''}`}
                  onClick={() => {
                    setSelectedValue(value);
                    setOpened(false);
                  }}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
