import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Text,
  Image,
  useMantineTheme,
} from '@mantine/core';
import { formatDollarAmount } from 'helpers';
import { PlanOption } from '../plan-information/PlanInformation';
import { DiscountedPlan } from './PlanOptions';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import Unselected from './unselected.svg';
import classes from './Plan.module.css';

type Props = {
  plan: PlanOption;
  handleSubSelect(item: PlanOption): void;
  isSelectedPlan: boolean;
  isCurrentPlan: boolean;
  isRecommendedPlan: boolean;
};

const Plan = ({
  plan,
  handleSubSelect,
  isSelectedPlan,
  isCurrentPlan,
  isRecommendedPlan,
}: Props) => {
  const colors = useMantineTheme().colors;

  const CheckMarkIcon = ({
    current,
    selected,
  }: {
    current: boolean;
    selected: boolean;
  }) => {
    if (current || selected) {
      return (
        <BsFillCheckCircleFill
          size={24}
          color={current ? colors.text[2] : colors.text[8]}
        />
      );
    }

    return (
      <Box h={30}>
        <Image height={25} width={25} src={Unselected} mb={0} />
      </Box>
    );
  };

  return (
    <Card
      bd={`1px solid ${isSelectedPlan ? 'text.8' : isCurrentPlan ? 'text.2' : colors.border[1]}`}
      key={plan.sku_name}
      my={10}
      p={0}
      shadow="none"
      style={{
        borderRadius: 10,
        overflow: 'visible',
        boxShadow: isSelectedPlan
          ? `0px 0px 5px 5px ${colors.text[8]}`
          : 'none',
      }}
      ta="center"
      w={150}
      h={400}
    >
      {isRecommendedPlan && (
        <Badge
          bg="#F8BE31"
          c="text.2"
          pos="absolute"
          top={-11}
          left="12%"
          mx="auto"
          style={{ zIndex: 1 }}
        >
          Recommended
        </Badge>
      )}
      <Flex direction="column" h="100%" justify="space-between" pb="1rem">
        <Box
          bg={
            isSelectedPlan
              ? 'text.8'
              : isCurrentPlan
                ? 'text.2'
                : colors.background2[5]
          }
          bd={`1px solid ${
            isSelectedPlan
              ? 'text.8'
              : isCurrentPlan
                ? 'text.2'
                : colors.background2[5]
          }`}
          style={{
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            boxShadow: isSelectedPlan
              ? `0px 0px 2px 2px ${colors.text[8]}`
              : 'none',
          }}
          pos="relative"
        >
          {isSelectedPlan && (
            <Box
              bg="text.8"
              pos="absolute"
              bottom={-10}
              left={65}
              h={25}
              style={{
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                transform: 'rotate(45deg)',
                boxShadow: `0px 0px 2px 2px ${colors.text[8]}`,
              }}
            />
          )}
          <Text
            fw={700}
            p="1rem 0.5rem"
            c={isSelectedPlan || isCurrentPlan ? 'white' : 'text.2'}
          >
            GlobalLink GO
            <br />
            {plan.sku_name.split('GlobalLink GO')[1]}
          </Text>
        </Box>
        <Flex direction="column">
          {plan.subscription_type === 'YEARLY' && (
            <Text className={classes.regularPrice} mx="auto" w="fit-content">
              {formatDollarAmount(
                String((plan as DiscountedPlan).regularPrice),
                { maximumFractionDigits: 0 }
              )}
            </Text>
          )}
          <Text fw={700}>
            {formatDollarAmount(String(plan.price), {
              maximumFractionDigits: 0,
            })}
          </Text>
        </Flex>
        <Text fw={700}>
          {' '}
          {new Intl.NumberFormat('en-US').format(plan.threshold_value)}
        </Text>
        <Flex direction="column" gap={30}>
          {[1, 2].map((i) => (
            <Box mx="auto" key={i}>
              <CheckMarkIcon
                current={isCurrentPlan}
                selected={isSelectedPlan}
              />
            </Box>
          ))}
        </Flex>
        <Button
          bg={
            isSelectedPlan ? 'text.8' : isCurrentPlan ? 'text.2' : 'transparent'
          }
          c={isCurrentPlan || isSelectedPlan ? 'white' : 'text.8'}
          disabled={isCurrentPlan}
          onClick={() => handleSubSelect(plan)}
          variant={isCurrentPlan || isSelectedPlan ? 'filled' : 'outline'}
          bd={isCurrentPlan ? 'text.8' : 'text.2'}
          w="fit-content"
          mx="auto"
        >
          {isCurrentPlan ? 'Current' : `Select${isSelectedPlan ? 'ed' : ''}`}
        </Button>
      </Flex>
    </Card>
  );
};

export default Plan;
