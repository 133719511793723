import { Params } from 'react-router';
import {
  decodeProjectId,
  decodeId,
  filterTranslationKeys,
  getPrevTimeSliceFrom,
} from 'helpers';
import { xapisEnv } from '../service-wrappers/xapisService';
import {
  querySubscriptions,
  querySkus,
  queryMonthlyWordsServed,
  queryPrevWordsServed,
  queryWordsServed,
  queryWorldTraffic,
  queryTranslatedTraffic,
  queryQuickQuote,
} from '../queries/dashboardQueries';
import { queryClient } from '../queries/queryClient';
import { projectQuery } from '../queries/projectQuery';

type LoaderArgs = {
  params: Params<string>;
  request: Request;
};

export type DashboardLoaderResponse = {
  concatenatedKey: string;
  timeSliceFrom: string;
  shouldQueryQuickQuote: boolean;
};

export async function dashboardLoader({
  params,
  request,
}: LoaderArgs): Promise<DashboardLoaderResponse> {
  const { projectId } = params;
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }

  const projectKey = decodeProjectId(projectId);
  const { payKey } = xapisEnv.getHost;

  const projectData = await queryClient.ensureQueryData(
    projectQuery(projectKey)
  );
  // Ensure project data
  const {
    origin_name = '',
    translations = [],
    subscription_status,
  } = projectData.project;

  const url = new URL(request.url);
  const timeSliceFrom = url.searchParams.get('d') || '1 month';

  // Ensure subscriptions and SKUs
  await queryClient.ensureQueryData(
    querySubscriptions(payKey, projectKey, subscription_status === 'expired')
  );
  await queryClient.ensureQueryData(querySkus(payKey));

  const translationKeysOffProject = filterTranslationKeys(translations);
  const keysInUrl = url.searchParams.getAll('t');
  const decodedkeysInUrl = keysInUrl.map((key) => decodeId(key));

  const concatenatedKey =
    decodedkeysInUrl.length > 0
      ? decodedkeysInUrl.join(',')
      : translationKeysOffProject.join(',');

  const prevTimeSlice = getPrevTimeSliceFrom(timeSliceFrom);
  const rollup = timeSliceFrom.includes('year') ? 'month' : 'day';

  await queryClient.ensureQueryData(queryMonthlyWordsServed(concatenatedKey));
  await queryClient.ensureQueryData(
    queryPrevWordsServed(concatenatedKey, prevTimeSlice, timeSliceFrom, rollup)
  );

  await queryClient.ensureQueryData(
    queryWordsServed(concatenatedKey, timeSliceFrom, rollup)
  );
  await queryClient.ensureQueryData(
    queryWorldTraffic(concatenatedKey, timeSliceFrom)
  );
  await queryClient.ensureQueryData(
    queryTranslatedTraffic(concatenatedKey, timeSliceFrom)
  );

  const subscriptions = await queryClient.ensureQueryData(
    querySubscriptions(payKey, projectKey, subscription_status === 'expired')
  );

  const shouldQueryQuickQuote =
    subscriptions &&
    subscriptions.length > 0 &&
    subscriptions[0].price === 0 &&
    origin_name?.length > 0;

  // we don't await this one and it will sometimes trigger React suspense
  queryClient.ensureQueryData(
    queryQuickQuote(shouldQueryQuickQuote, origin_name)
  );

  // these params needed for the queries, both here in the loader and when the query is called on the component
  // there is no need for the component to have to figure them all out again so we pass them as loader data
  return {
    concatenatedKey,
    timeSliceFrom,
    shouldQueryQuickQuote,
  };
}
