import {
  Button,
  Container,
  Flex,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useLocation, useRouteLoaderData } from 'react-router-dom';
import { CardHeader } from 'ui/components/glgo/Generic';
import { useGLGOParams } from 'hooks';
import { QuotesLoaderResponse } from 'store';
import { QuoteCard } from './QuoteCard';
import { decodeProjectId, desktopRC, encodeKey } from 'helpers';
import { OpenContext } from 'ui';

export const QuotesProject = () => {
  const { projectId = '' } = useGLGOParams();
  const { quotes, yyTarget } = useRouteLoaderData(
    'quotes'
  ) as QuotesLoaderResponse;

  const projectKey = decodeProjectId(projectId);
  const { colors } = useMantineTheme();
  const currentPath = useLocation().pathname;

  const projectQuotes = Object.values(quotes).filter(
    (quote) => quote.projectKey === projectKey
  );

  const newQuotePath = `/${projectId}/${desktopRC.QUOTE}/${encodeKey(yyTarget?.translation_key || '')}`;

  return (
    <Container
      pb="2rem"
      px={0}
      mx={0}
      maw={{ base: '100%', md: '90%', lg: '75%', xl: '65%' }}
    >
      <Flex align="center" mt={20}>
        <CardHeader
          title={
            <Text fw={700} mb={10} size="1.5rem" span>
              {`Quotes (${Object.keys(projectQuotes).length.toLocaleString()})`}
            </Text>
          }
          flexItems={
            <>
              <Button
                bg="#0071DB"
                h={40}
                px={30}
                mr="10"
                style={{
                  borderRadius: 30,
                  borderColor: colors.text[8],
                }}
                mt={20}
                onClick={() => {
                  window.GlGoAPI?.openInContext(
                    `${desktopRC.QUOTE}`,
                    currentPath
                  );
                }}
                rightSection={
                  <OpenContext color={'white'} path={`${desktopRC.QUOTE}`} />
                }
              >
                New Website Quote
              </Button>
              <Button
                bg="#0071DB"
                h={40}
                px={30}
                style={{
                  borderRadius: 30,
                  borderColor: colors.text[8],
                }}
                mt={20}
                onClick={() => {
                  window.GlGoAPI?.openInContext(newQuotePath, currentPath);
                }}
                rightSection={
                  <OpenContext color={'white'} path={newQuotePath} />
                }
              >
                Project Quote
              </Button>
            </>
          }
          itemsType="button"
          justifyItems="flex-end"
        />
      </Flex>
      <ScrollArea
        h="calc(100vh - 245px)"
        mt={20}
        data-testid="pw-quote-cards-container"
      >
        <Flex p={10} direction="column" rowGap={10}>
          {Object.keys(projectQuotes).length > 0 ? (
            Object.values(projectQuotes).map((quote) => (
              <QuoteCard key={quote.id} quote={quote} yyTarget={yyTarget} />
            ))
          ) : (
            <Text>No quotes available for project.</Text>
          )}
        </Flex>
      </ScrollArea>
    </Container>
  );
};
