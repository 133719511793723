import React, { useCallback, useMemo } from 'react';
import { Container, Flex, Text } from '@mantine/core';
import { CardHeader } from '../../Generic';
import { ChoroplethMap } from './ChoroplethMap';
import { CountryTable } from './CountryTable';
import { MetricsFilters } from '../selectors-filters/MetricsFilters';
import { getTimeSliceOptions } from '../utils';
import { filterTargets, updateDateParam } from './trafficUtils';
import { MetricsLoaderData } from './TranslatedTraffic';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { QueryMultiSelector } from '../selectors-filters/QueryMultiSelector';
import DateFilter from '../selectors-filters/DateFilter';
import { useGetNavigationStatus } from 'hooks';

export const WorldTraffic = () => {
  const { targets, timeSliceFrom, worldTraffic } = useRouteLoaderData(
    'metrics'
  ) as MetricsLoaderData;
  const loading = useGetNavigationStatus().isNavigatingInPlace;

  const filteredTargets = useMemo(() => filterTargets(targets), [targets]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedDate = useCallback(
    (value: string) => {
      updateDateParam(value, searchParams, setSearchParams);
    },
    [searchParams, setSearchParams]
  );

  return (
    <Container maw="100%" p={0} mt={30}>
      <CardHeader
        title={
          <Text className="headerTitle" span>
            World Traffic
          </Text>
        }
        tooltipLabel="The chart and the table below show traffic to your website from countries around the world.
        Includes percentages indicating the share of total traffic from that country.
        Note that this is not limited to translated traffic but all traffic."
        flexItems={
          <MetricsFilters>
            <QueryMultiSelector
              encodeKeys
              selectAll
              shouldRevalidate
              options={filteredTargets}
            />
            <DateFilter
              timeSliceOptions={getTimeSliceOptions()}
              timeSliceFrom={timeSliceFrom}
              onSelect={handleSelectedDate}
            />
          </MetricsFilters>
        }
        itemsType="filter"
        justifyItems="flex-start"
      />
      <Flex direction="column" rowGap={30}>
        <ChoroplethMap worldTraffic={worldTraffic} loading={loading} />
        <CountryTable
          worldTraffic={worldTraffic}
          loading={loading}
          timeSlice={timeSliceFrom}
        />
      </Flex>
    </Container>
  );
};

export default WorldTraffic;
