import { useParams, Link, useSearchParams } from 'react-router-dom';
import { Box, Flex, ScrollArea, Text } from '@mantine/core';
import { CardHeader } from 'ui';
import { useCurrentProjectData } from 'store';

import { TranslationCard } from './TranslationCard';
import { dashboardRC, decodeId, encodeKey } from 'helpers';
import { AiFillTool } from 'react-icons/ai';
import { TranslationEditor } from './TranslationEditor';

export const Languages = () => {
  const { projectId } = useParams();

  const { translations } = useCurrentProjectData();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTKey = decodeId(searchParams.get('t') || '');
  const target = translations.find((t) => t.translation_key === selectedTKey);

  // target not found but selectedTKey is not empty
  if (selectedTKey && !target) {
    // Redirect to languages tab
    setTimeout(() => {
      setSearchParams({});
    }, 1500);
    return (
      <Box mt={30}>
        Invalid translation key. Please select a language from the list.
      </Box>
    );
  }

  const handleTranslationSelection = (tkey: string) => {
    setSearchParams({ t: encodeKey(tkey) });
  };

  return (
    <Box mt={30}>
      {target ? (
        <>
          <TranslationEditor segmentEditorRef={null} target={target} />
        </>
      ) : (
        <Box maw={{ base: '100%', md: '90%', lg: '75%', xl: '65%' }}>
          <CardHeader
            title={
              <Text
                className="headerTitle"
                data-testid="pw-translations-languages-count"
                span
              >
                {`Languages (${translations.length.toLocaleString()})`}
              </Text>
            }
            flexItems={
              <Link
                to={`/${projectId}/${dashboardRC.PAGE_SETTINGS}/language-settings`}
                style={{ textDecoration: 'none', color: '#0071DB' }}
              >
                <Flex align={'center'} gap={5}>
                  <Text fz="0.85rem">Manage Languages</Text>
                  <AiFillTool size={16} />
                </Flex>
              </Link>
            }
            itemsType="button"
            justifyItems="flex-end"
          />
          <ScrollArea
            h="calc(100vh - 245px)"
            mt={20}
            data-testid="pw-translations-card-container"
          >
            <Flex p={10} direction="column" rowGap={10}>
              {translations?.length > 0 ? (
                translations.map((translation) => (
                  <TranslationCard
                    key={translation.translation_key}
                    translation={translation}
                    handleSelection={handleTranslationSelection}
                  />
                ))
              ) : (
                <></>
              )}
            </Flex>
          </ScrollArea>
        </Box>
      )}
    </Box>
  );
};
