/**
 * Calculates the number of remaining days until the given renewal date.
 *
 * @param {string} renewalDate - The date of the subscription renewal'.
 * @return {number} The number of remaining days until the renewal date. Returns 0 if the renewal date has already passed.
 */
export const getRemainingSubscriptionDays = (renewalDate: Date | string) => {
  const today = new Date();
  const renewal = new Date(renewalDate);
  const remainingMs = renewal.getTime() - today.getTime();

  if (remainingMs <= 0) {
    return 0;
  }
  // convert ms to days
  return Math.ceil(remainingMs / (1000 * 60 * 60 * 24));
};

/**
 * Calculates the remaining words served based on the threshold and the number of words served.
 *
 * @param {number} wordsServedUsed - The number of words served.
 * @param {number} [threshold=500000] - The threshold value.
 * @return {number} The remaining words served.
 */
export const getRemainingSubscribedWordsServed = (
  wordsServedUsed: number,
  threshold: number = 500000
) => {
  return threshold - wordsServedUsed;
};
