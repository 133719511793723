import {
  fetchTranslatedTrafficPromise,
  TranslatedTrafficTimeslice,
} from '../TranslatedTrafficStore';
import {
  fetchSubscriptionsPromise,
  Subscription,
} from '../PaySubscriptionStore';
import { fetchSkusPromise } from '../PaySkuStore';
import {
  fetchMonthlyWordsServedPromise,
  fetchPrevWordsServedPromise,
  fetchWordsServedPromise,
  WordsServedResponse,
} from '../WordsServedStore';
import {
  fetchWorldTrafficPromise,
  WorldTrafficResponse,
} from '../WorldTrafficStore';
import { getQuickQuote } from '../quickQuote';
import {
  queryOptions,
  useSuspenseQueries,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { getPrevTimeSliceFrom } from 'helpers';
import { useCurrentProjectData } from './projectQuery';

export const querySubscriptions = (
  payKey: string,
  projectKey: string,
  isExpired: boolean
) =>
  queryOptions({
    queryKey: ['subscriptions', payKey, projectKey, isExpired],
    staleTime: 1000 * 60, // shorter stale time for actively used subscriptions
    queryFn: async () => {
      const response = await fetchSubscriptionsPromise(
        payKey,
        projectKey,
        isExpired
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.subscriptions
      ) {
        throw new Error('Failed to fetch subscriptions data');
      }
      return response.data.subscriptions as Subscription[];
    },
  });

export const querySkus = (payKey: string) =>
  queryOptions({
    queryKey: ['skus', payKey],
    queryFn: async () => {
      const response = await fetchSkusPromise(payKey);
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.skus
      ) {
        throw new Error('Failed to fetch SKUs data');
      }
      return response.data.skus;
    },
  });

export const queryMonthlyWordsServed = (concatenatedKey: string) =>
  queryOptions({
    queryKey: ['monthlyWordsServed', concatenatedKey],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchMonthlyWordsServedPromise(concatenatedKey);
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch monthly words served data');
      }
      return response.data.data;
    },
  });

export const queryPrevWordsServed = (
  concatenatedKey: string,
  prevTimeSlice: string,
  timeSliceFrom: string,
  rollup: string
) =>
  queryOptions({
    queryKey: [
      'prevWordsServed',
      concatenatedKey,
      prevTimeSlice,
      timeSliceFrom,
      rollup,
    ],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchPrevWordsServedPromise(
        concatenatedKey,
        prevTimeSlice,
        timeSliceFrom,
        rollup
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch previous words served data');
      }
      return response.data.data;
    },
  });

export const queryWordsServed = (
  concatenatedKey: string,
  timeSliceFrom: string,
  rollup: string
) =>
  queryOptions({
    queryKey: ['wordsServed', concatenatedKey, timeSliceFrom, rollup],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchWordsServedPromise(
        concatenatedKey,
        timeSliceFrom,
        rollup
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch words served data');
      }
      return response.data.data;
    },
  });

export const queryWorldTraffic = (
  concatenatedKey: string,
  timeSliceFrom: string
) =>
  queryOptions({
    queryKey: ['worldTraffic', concatenatedKey, timeSliceFrom],

    queryFn: async () => {
      if (!concatenatedKey.length || concatenatedKey === '0') return [];
      const response = await fetchWorldTrafficPromise(
        concatenatedKey,
        timeSliceFrom
      );
      if (
        !response ||
        response.status !== 200 ||
        !response.data ||
        !response.data.data
      ) {
        throw new Error('Failed to fetch world traffic data');
      }
      return response.data.data as WorldTrafficResponse[];
    },
  });

export const queryTranslatedTraffic = (
  translationKey: string,
  timeSliceFrom: string
) =>
  queryOptions({
    queryKey: ['translatedTraffic', translationKey, timeSliceFrom],

    queryFn: async () => {
      let prevSlices = [] as TranslatedTrafficTimeslice[],
        slices = [] as TranslatedTrafficTimeslice[];
      const prevTimeSliceFrom = getPrevTimeSliceFrom(timeSliceFrom);

      if (translationKey && timeSliceFrom && prevTimeSliceFrom) {
        try {
          const [prevTranslatedTrafficRes, translatedTrafficRes] =
            await Promise.allSettled([
              fetchTranslatedTrafficPromise(
                translationKey,
                prevTimeSliceFrom,
                'timeseries'
              ),
              fetchTranslatedTrafficPromise(
                translationKey,
                timeSliceFrom,
                'timeseries'
              ),
            ]);

          if (
            prevTranslatedTrafficRes.status === 'fulfilled' &&
            prevTranslatedTrafficRes.value.status === 200
          ) {
            const { data } = prevTranslatedTrafficRes.value;
            prevSlices = data.data;
          }

          if (
            translatedTrafficRes.status === 'fulfilled' &&
            translatedTrafficRes.value.status === 200
          ) {
            const { data } = translatedTrafficRes.value;
            slices = data.data;
          }
        } catch (err) {
          console.error('ERROR from Promise.allSettled:', err);
          throw new Error('Failed to fetch translated traffic');
        }
      }

      return { prevSlices, slices } as TranslatedTrafficResponse;
    },
  });

export type TranslatedTrafficResponse = {
  prevSlices: TranslatedTrafficTimeslice[];
  slices: TranslatedTrafficTimeslice[];
};

export const queryQuickQuote = (
  shouldQueryQuickQuote: boolean,
  origin_name: string
) =>
  queryOptions({
    queryKey: ['quickQuote', origin_name, shouldQueryQuickQuote],

    queryFn: async () => {
      if (!shouldQueryQuickQuote) return null;
      const response = await getQuickQuote(origin_name);
      if (!response || response.status !== 200 || !response.data) {
        throw new Error('Failed to fetch quick quote data');
      }
      return response.data;
    },
  });

/**
 * A custom hook that fetches and returns various dashboard-related data.
 *
 * This hook uses `useSuspenseQueries` to fetch multiple sets of data concurrently:
 * - Subscriptions
 * - Monthly words served
 * - Previous words served
 * - Current words served
 * - World traffic
 * - Translated traffic
 *
 * It also uses the `useCurrentProject` hook to get the current project data,
 * which is used to determine if the subscription is expired.
 *
 * @param {Object} params - The parameters for the queries.
 * @param {string} params.projectKey - The key of the current project.
 * @param {string} params.payKey - The pay key associated with the project.
 * @param {string} params.concatenatedKey - The concatenated key for filtering data.
 * @param {string} params.timeSliceFrom - The start time for the data slice.
 *
 * @returns {Object} An object containing the fetched data:
 *   @property {Subscription[]} subscriptions - The project's subscriptions.
 *   @property {any} monthlyWordsServed - Monthly words served data.
 *   @property {any} prevWordsServed - Previous period's words served data.
 *   @property {any} wordsServed - Current period's words served data.
 *   @property {WorldTrafficResponse[]} worldTraffic - World traffic data.
 *   @property {Object} translatedTraffic - Translated traffic data.
 *   @property {TranslatedTrafficTimeslice[]} translatedTraffic.prevSlices - Previous period's translated traffic.
 *   @property {TranslatedTrafficTimeslice[]} translatedTraffic.slices - Current period's translated traffic.
 *
 * @throws Will throw an error if any of the queries fail to fetch data.
 */
export const useDashboardQueries = (params: {
  projectKey: string;
  payKey: string;
  concatenatedKey: string;
  timeSliceFrom: string;
}) => {
  const { projectKey, payKey, concatenatedKey, timeSliceFrom } = params;

  const prevTimeSlice = getPrevTimeSliceFrom(timeSliceFrom);
  const rollup = timeSliceFrom.includes('year') ? 'month' : 'day';

  const { project } = useCurrentProjectData();

  const { data: subscriptions } = useSuspenseQuery(
    querySubscriptions(
      payKey,
      projectKey,
      project.subscription_status === 'expired'
    )
  );

  const queries = [
    queryMonthlyWordsServed(concatenatedKey),
    queryPrevWordsServed(concatenatedKey, prevTimeSlice, timeSliceFrom, rollup),
    queryWordsServed(concatenatedKey, timeSliceFrom, rollup),
    queryWorldTraffic(concatenatedKey, timeSliceFrom),
    queryTranslatedTraffic(concatenatedKey, timeSliceFrom),
  ];

  const results = useSuspenseQueries({
    queries,
  });

  return {
    subscriptions,
    monthlyWordsServed: results[0].data as WordsServedResponse[],
    prevWordsServed: results[1].data as WordsServedResponse[],
    wordsServed: results[2].data as WordsServedResponse[],
    worldTraffic: results[3].data as WorldTrafficResponse[],
    translatedTraffic: results[4].data as TranslatedTrafficResponse,
  };
};
