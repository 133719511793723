import { Box, Flex, Select, Text, Tooltip } from '@mantine/core';
import { xapisEnv } from 'store';
import { useState } from 'react';
import { useLogout } from 'hooks';
import cheet from 'cheet.js';
import { HostLabel, LIVE, LOCAL, RC, STAGING, HOST_OVERRIDE } from 'helpers';

const { VITE_IS_GLGO_DESKTOP } = import.meta.env;

const envOptions: {
  [key in HostLabel]: string[];
} = {
  [LOCAL]: [STAGING],
  [STAGING]: [LOCAL, STAGING],
  [RC]: [LOCAL, STAGING, RC],
  [LIVE]: [LOCAL, STAGING, RC, LIVE],
};

const desktopEnvOptions = [LOCAL, STAGING, RC];

const HostTipLabel = ({ text }: { text?: string }) => {
  const host = xapisEnv.getHost;
  return (
    <Flex direction={'column'}>
      <Text td="underline" fw={600} size={'md'}>
        Current Backend constants
      </Text>
      {Object.keys(host).map((key) => (
        <Flex key={key}>
          <Text w={130} fw={'bold'}>
            {key}
          </Text>
          : {host[key as keyof typeof host]}
        </Flex>
      ))}

      {text && (
        <Text mt={10} fw={600} size={'md'}>
          {text}
        </Text>
      )}
    </Flex>
  );
};

export const BackendSelector = () => {
  const logout = useLogout();
  const currnetEnv = xapisEnv.getLabel;
  const defaultEnv = xapisEnv.getDefaultLabel;
  const isOverride = currnetEnv !== defaultEnv;
  const [showSelector, setShowSelector] = useState(isOverride);
  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl x a p i s',
    () => {
      setShowSelector(!showSelector);
    }
  );

  function handleBackendChange(label: string) {
    if (label === currnetEnv) {
      return;
    }
    if (
      window.confirm(
        `Do you want to change xapis environment to ${label}?\nYou will be logged out. The change will take effect after you log back in.`
      )
    ) {
      // sessionStorage can't be used here because it's deleted after logout
      if (label === defaultEnv) {
        localStorage.removeItem(HOST_OVERRIDE);
      } else {
        localStorage.setItem(HOST_OVERRIDE, label);
      }
      logout();
    }
  }

  if (!showSelector) return <></>;

  const selectorOptions = VITE_IS_GLGO_DESKTOP
    ? desktopEnvOptions.filter((o) => o !== currnetEnv)
    : envOptions[currnetEnv];

  const tipText =
    currnetEnv === defaultEnv ? '' : `** Default environment is ${defaultEnv}`;

  return (
    <Box style={{ marginLeft: 10, WebkitAppRegion: 'no-drag' }}>
      {VITE_IS_GLGO_DESKTOP ? (
        <Tooltip label={<HostTipLabel />}>
          <Box
            style={{
              border: '1px solid red',
              borderRadius: 5,
              padding: '4px 10px',
            }}
          >{`Backend: ${currnetEnv}`}</Box>
        </Tooltip>
      ) : (
        <Tooltip label={<HostTipLabel text={tipText} />}>
          <Select
            leftSection="Backend:"
            onChange={(label) => label && handleBackendChange(label)}
            placeholder={currnetEnv}
            value={currnetEnv}
            data={selectorOptions}
            styles={{
              input: {
                '&::placeholder': { color: 'black', fontSize: '1rem' },
                borderWidth: 1,
                borderColor: isOverride ? 'red' : 'gray',
                borderStyle: 'solid',
                width: 180,
                zIndex: 0,
              },
              root: { lineHeight: 1, borderColor: 'transparent', zIndex: 0 },
              label: {
                fontSize: '0.8rem',
                zIndex: 10000,
                marginLeft: 5,
              },
              section: {
                width: 80,
              },
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
