import { useState } from 'react';
import { Accordion, Card, Title, Flex, Text } from '@mantine/core';
import SelectorDefaults from './SelectorDefaults';
import ExplanationCard from './ExplanationCard';
import { ProjectLoaderResponse } from 'store';
import { isPresetCss } from '../../gosetup/LanguageSelectorAppearances';
import { useRouteLoaderData } from 'react-router-dom';
import { LanguageCSSEditor } from '../custom-css-editor/LanguageCSSEditor';
// import { LangSelectorPreview } from './LangSelectorPreview';

export const SelectorAccordion = () => {
  const { yyTranslation } = useRouteLoaderData(
    'project'
  ) as ProjectLoaderResponse;

  const [showExplanationCard, setShowExplanationCard] = useState(true);
  const { custom_css: hexedCustomCss = '' } = yyTranslation || {};

  const currentAccordionItem = isPresetCss(hexedCustomCss)
    ? 'defaults'
    : 'customOptions';

  return (
    <Flex mt={30} direction={{ base: 'column', xl: 'row' }} rowGap={20}>
      <Card
        h="fit-content"
        maw={1100}
        w={{ base: '100%', xl: '70%' }}
        px={{ xs: 10, sm: 40 }}
      >
        <Title mx="auto" order={4} p="sm" tt="uppercase" w="fit-content">
          Customize Your Selector
        </Title>
        <Accordion
          chevronPosition="left"
          defaultValue={currentAccordionItem}
          key={currentAccordionItem}
        >
          <Accordion.Item
            value="defaults"
            style={{
              borderTop: '0.0625rem solid #dee2e6',
              borderBottom: 'none',
            }}
          >
            <Accordion.Control p="0.5rem">
              Default Customization Settings
            </Accordion.Control>
            <Accordion.Panel>
              <SelectorDefaults
                key={currentAccordionItem}
                yyTarget={yyTranslation}
              />
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item
            value="customOptions"
            style={{
              borderTop: '0.0625rem solid #dee2e6',
              borderBottom: 'none',
            }}
          >
            <Accordion.Control p="0.5rem">
              <Flex align="center" justify="space-between" gap={10}>
                <Flex>
                  <Text>Custom CSS Editor</Text>
                  <Text
                    c="text.8"
                    fw="bold"
                    fz="0.95rem"
                    ml={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowExplanationCard(!showExplanationCard);
                    }}
                  >
                    What's this?
                  </Text>
                </Flex>
              </Flex>
            </Accordion.Control>
            <Accordion.Panel>
              <Text mb={20}>
                <Text span fw="bold">
                  NOTE:
                </Text>{' '}
                This editor will only apply to the widget and override any
                selections made in the default section.
              </Text>
              <LanguageCSSEditor target={yyTranslation} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Card>
      {showExplanationCard ? (
        <ExplanationCard setShowExplanationCard={setShowExplanationCard} />
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default SelectorAccordion;
