import React from 'react';
import { Divider, Flex, ScrollArea, Text } from '@mantine/core';
import { useUserProvider } from 'store';
import { CreateAccountForm } from 'ui';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';
import { encodeProjectKey, getDefaultProjectKey } from 'helpers';
import AccoladesWrapper from './AccoladesWrapper';

export const CreateAccount = () => {
  const auth = useAuth();
  const { userProjects } = useUserProvider();
  const defaultProjectId = encodeProjectKey(getDefaultProjectKey(userProjects));

  // Wait for auth to load before redirecting
  if (auth.isLoading) return null;

  if (auth.isAuthenticated) {
    const redirectUrl = defaultProjectId
      ? `/${defaultProjectId}/dashboard`
      : '/create-project';
    return <Navigate to={redirectUrl} />;
  }

  return (
    <>
      <AccoladesWrapper>
        <Flex h="100vh" direction="column" justify="center" bg="white">
          <ScrollArea style={{ overflowY: 'auto' }}>
            <Flex
              w="100%"
              mih={850}
              direction="column"
              justify="center"
              align="center"
              px="1rem"
            >
              <Flex maw="25rem" direction="column" align="center" rowGap={15}>
                <Text c="text3.4" mt={10} fw={700} fz="1.75rem">
                  Create your account
                </Text>
                <Text c="text3.4">
                  Sign up for{' '}
                  <Text c="text3.4" span fw={700}>
                    FREE
                  </Text>{' '}
                  to start your 30 day trial!
                </Text>
                <Divider
                  w="100%"
                  color="divider.1"
                  orientation="horizontal"
                  size={1}
                />
                <CreateAccountForm />
              </Flex>
            </Flex>
          </ScrollArea>
        </Flex>
      </AccoladesWrapper>
    </>
  );
};

export default CreateAccount;
