import React from 'react';
import { Badge, Button, Flex, Radio, Text } from '@mantine/core';
import { FaCircleCheck } from 'react-icons/fa6';
import { EverythingIcon } from '../../images/EverythingIcon';
import { BsExclude, BsIntersect } from 'react-icons/bs';
import classes from './ScopeOption.module.css';

type Props = {
  currentScope: string;
  scopeName: string;
  selectedScope: string;
  handleSelectedScope: (scope: string) => void;
};

const getScope = (scope: string) => {
  switch (scope) {
    case 'all':
      return {
        iconComponent: <EverythingIcon width="14px" height="14px" />,
        desc: `Your entire site will be translated.`,
      };
    case 'exclude':
      return {
        iconComponent: <BsExclude fill="#5D6B89" width={14} height={14} />,
        desc: (
          <Text
            mt={{ base: '1rem', sm: 0 }}
            ml={{ sm: '1rem' }}
            w={{ base: '100%', sm: '70%' }}
          >
            <strong>
              Everything <em>except</em>
            </strong>{' '}
            the pages you{' '}
            <strong>
              <em>specifically exclude</em>
            </strong>{' '}
            from scope will be translated.
          </Text>
        ),
      };
    case 'include':
      return {
        iconComponent: <BsIntersect fill="#167934" width={14} height={14} />,
        desc: (
          <Text
            mt={{ base: '1rem', sm: 0 }}
            ml={{ sm: '1rem' }}
            w={{ base: '100%', sm: '70%' }}
          >
            <strong>
              Nothing <em>but</em>
            </strong>{' '}
            the pages you{' '}
            <strong>
              <em>specifically include</em>
            </strong>{' '}
            will be translated.
          </Text>
        ),
      };
    default:
      break;
  }
};

const ScopeOption = ({
  scopeName,
  handleSelectedScope,
  selectedScope,
}: Props) => (
  <Flex
    align={{ base: 'start', sm: 'center' }}
    direction={{ base: 'column', sm: 'row' }}
    justify="space-between"
    p="0 1rem 1.5rem 0"
    w="100%"
  >
    <Flex align="center">
      <Radio
        checked={selectedScope === scopeName}
        classNames={{
          label: classes.label,
          radio: classes.radio,
        }}
        icon={FaCircleCheck}
        onChange={() => handleSelectedScope(scopeName)}
        value={scopeName}
      />
      <Button
        classNames={{
          root: `${classes.optionButton} ${selectedScope === scopeName ? classes.checkedOption : classes.uncheckedOption}`,
          inner: classes.optionButtonInner,
        }}
        size="md"
      >
        <>
          {getScope(scopeName)?.iconComponent}
          <Text fw={600} ml="0.5rem">
            {scopeName === 'all'
              ? 'Everything'
              : scopeName[0].toUpperCase() + scopeName.substring(1)}
          </Text>
        </>
      </Button>
    </Flex>
    {scopeName === 'all' ? (
      <Flex
        align={{ base: 'start', xs: 'center' }}
        direction={{ base: 'column', xs: 'row' }}
        justify="space-between"
        mt={{ base: '1rem', sm: 0 }}
        ml={{ sm: '1rem' }}
        w={{ base: '100%', sm: '70%' }}
      >
        <Text mb={{ base: 5, xs: 0 }}>{getScope(scopeName)?.desc}</Text>
        <Badge
          c="icon.0"
          styles={({ colors }) => ({
            root: {
              backgroundColor: colors.badge[6],
              fontSize: '0.8rem',
              fontWeight: 600,
              padding: '0.75rem',
            },
          })}
        >
          R<span style={{ textTransform: 'lowercase' }}>ecommended</span>
        </Badge>
      </Flex>
    ) : (
      getScope(scopeName)?.desc
    )}
  </Flex>
);

export default ScopeOption;
