import { RouteObject } from 'react-router-dom';
import { ErrorBoundary, TranslatedTraffic, WorldTraffic } from 'ui';
import { dashboardRC } from 'helpers';

export const routes: Array<RouteObject & { path: string }> = [
  {
    path: 'translated-traffic',
    element: <TranslatedTraffic />,
    id: 'translatedTraffic',
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'world-traffic',
    element: <WorldTraffic />,
    id: 'worldTraffic',
    errorElement: <ErrorBoundary />,
  },
];
export const settings = {
  path: dashboardRC.PAGE_METRICS,
  defaultTab: routes[0].path,
};
