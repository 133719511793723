import { AuthContextProps } from 'react-oidc-context';

/*
 * Takes in auth as a param and checks for the time our JWT was
 * issued and uses that as our login time
 * */

// works and pass in different values for auth to cover all if cases and any cases where values may be weird if logintime was an undefined
export default function getNotificationTimeSlice(
  auth: AuthContextProps | null
) {
  const loginTime = auth?.user?.profile?.iat ? auth.user.profile.iat * 1000 : 0;
  if (loginTime === 0) {
    return 'just now';
  }

  const now = Date.now();
  const diff = now - loginTime;

  const minutes = Math.floor(diff / 60000);
  const hours = Math.floor(minutes / 60);

  if (minutes < 1) return 'just now';
  if (minutes < 60) return `${minutes}m ago`;
  if (hours < 24) return `${hours}h ago`;
  return `${Math.floor(hours / 24)}d ago`;
}
