import { Params } from 'react-router-dom';
import { xapisEnv } from '../service-wrappers/xapisService';
import { queryClient } from '../queries/queryClient';
import { projectQuery } from '../queries/projectQuery';
import { querySubscriptions } from '../queries/dashboardQueries';

export async function projectQueryLoader({
  params,
}: {
  params: Params<string>;
}) {
  const { projectId } = params || {};
  if (!projectId) {
    throw new Response('Project key is not defined', {
      status: 404,
      statusText: 'Project key is not defined',
    });
  }
  const { payKey } = xapisEnv.getHost;
  const projectData = await queryClient.ensureQueryData(
    projectQuery(projectId)
  );
  const { project } = projectData;
  queryClient.ensureQueryData(
    querySubscriptions(
      payKey,
      project.project_key,
      project.subscription_status === 'expired'
    )
  );
  return projectData;
}
