import React from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip as ReTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomChartTooltip from '../recharts/CustomChartTooltip';

export const TranslatedTrafficChart = ({
  convertedTranslatedTraffic,
}: {
  convertedTranslatedTraffic: { timeslice: string; numRequests: number }[];
}) => (
  <ResponsiveContainer height={150} width="100%">
    <AreaChart data={convertedTranslatedTraffic || []}>
      <defs>
        <linearGradient id="traffic" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#0071DB" stopOpacity={0.5} />
          <stop offset="95%" stopColor="#0071DB" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis hide dataKey="timeslice" />
      <YAxis hide dataKey="numRequests" />
      <Area
        dataKey="numRequests"
        legendType="circle"
        fill="url(#traffic)"
        stroke="#0071DB"
        strokeWidth={4}
        activeDot={{ r: 5, fill: '#0071DB', strokeWidth: 0 }}
      />
      <ReTooltip content={<CustomChartTooltip />} cursor={false} />
    </AreaChart>
  </ResponsiveContainer>
);
