import { RouteObject } from 'react-router-dom';
import {
  languageCodesLoader,
  spiderTreeLoader,
  addLanguagesAction,
} from 'store';
import {
  CurrentScopeHome,
  DeploymentWrapper,
  ManageLanguages,
  CustomCssLanding,
  ScopeUrlsTree,
  SelectorAccordion,
  Injection,
} from 'ui';
import { dashboardRC } from 'helpers';

export const routes: Array<RouteObject & { path: string }> = [
  {
    path: 'language-settings',
    children: [
      {
        index: true,
        loader: languageCodesLoader,
        action: addLanguagesAction,
        element: <ManageLanguages />,
      },
      {
        path: 'custom-css-editor',
        element: <CustomCssLanding />,
      },
    ],
  },
  {
    path: 'language-selector',
    element: <SelectorAccordion />,
  },
  {
    path: 'deployment',
    element: <DeploymentWrapper />,
  },
  {
    path: 'injection',
    element: <Injection />,
  },
  {
    path: 'project-scope',
    children: [
      {
        index: true,
        element: <CurrentScopeHome />,
      },
      {
        path: ':yyTranslationId/urls',
        element: <ScopeUrlsTree />,
        loader: spiderTreeLoader,
      },
    ],
  },
];

export const settings = {
  path: dashboardRC.PAGE_SETTINGS,
  defaultTab: routes[0].path,
};
