import { Flex, Text, useMantineTheme } from '@mantine/core';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';

type Props = {
  text: string;
};

const BackButton = ({ text }: Props) => {
  const colors = useMantineTheme().colors;
  return (
    <Link to=".." style={{ textDecoration: 'none', width: 'fit-content' }}>
      <Flex align="center" gap={10}>
        <MdArrowBack color={colors.text[8]} size={24} />
        <Text fw={700} fz="1.15rem">
          {text}
        </Text>
      </Flex>
    </Link>
  );
};

export default BackButton;
