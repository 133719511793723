import React from 'react';
import { Box, Button, Card, Divider, Flex, Text } from '@mantine/core';
import { useSetupStepsContext } from './ProjectSetupProvider';
import BrowserWindow from './BrowserWindow';
import { TranslatedLanguages } from './TranslatedLanguages';
import { MobileActionHeader } from './MobileActionHeader';
import useSelectedLanguages from './hooks/useSelectedLanguages';
import classes from './SelectLanguagesStep.module.css';
import fathomEventTracker from '../../fathomAnalytics';

export const SelectLanguagesSideMenuContent = () => {
  const { setActiveStep, isNextButtonDisabled, setIsNextButtonDisabled } =
    useSetupStepsContext();

  return (
    <Flex
      w="100%"
      maw={450}
      rowGap="1.5rem"
      direction="column"
      justify="center"
      align="center"
    >
      <Text fz="2rem" fw={700}>
        Project Setup
      </Text>
      <Divider w="100%" color="divider.0" size={2} />
      <Text fz="1.25rem" fw={600} ta="center">
        Choose which language you want to offer.
      </Text>
      <Text fz="1rem" fw={500} ta="center">
        (You can edit this anytime after your project is created.)
      </Text>
      <Flex columnGap="1rem">
        <Button
          w={{ base: '100%', xs: '7rem' }}
          bg="background.0"
          variant="outline"
          radius={30}
          c="text.8"
          className={classes.backButton}
          onClick={() => setActiveStep((prevStep) => prevStep - 0.5)}
          fz="1rem"
        >
          Back
        </Button>
        <Button
          className={classes.nextButton}
          w={{ base: '100%', xs: '7rem' }}
          radius={30}
          disabled={isNextButtonDisabled}
          onClick={() => {
            fathomEventTracker('Added Languages - 3');
            setIsNextButtonDisabled(true);
            setActiveStep((prevStep) => prevStep + 0.5);
          }}
          fz="1rem"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export const SelectLanguagesBodyContent = () => {
  useSelectedLanguages();

  return (
    <Box px="2rem">
      <BrowserWindow>
        <TranslatedLanguages />
      </BrowserWindow>
    </Box>
  );
};

export const MobileSelectLanguagesStep = () => {
  const { setActiveStep, isNextButtonDisabled, setIsNextButtonDisabled } =
    useSetupStepsContext();

  useSelectedLanguages();

  return (
    <Card w="100%" maw={{ base: '100%', xs: 650, lg: 450 }} px="1rem" py="2rem">
      <Flex w="100%" direction="column" align="center" rowGap="1.5rem">
        <MobileActionHeader title="Project Setup" />
        <Divider w="100%" color="divider.0" size={2} />
        <TranslatedLanguages />
        <Button
          className={classes.nextButton}
          w={{ base: '100%', xs: '7rem' }}
          radius={30}
          disabled={isNextButtonDisabled}
          onClick={() => {
            fathomEventTracker('Added Languages - 3');
            setIsNextButtonDisabled(true);
            setActiveStep((prevStep) => prevStep + 0.5);
          }}
          fz="1rem"
        >
          Next
        </Button>
      </Flex>
    </Card>
  );
};
