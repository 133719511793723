import React from 'react';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { dashboardRC } from 'helpers';
import fathomEventTracker from '../../fathomAnalytics';

type ModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  encodedProjectKey: string;
};

const SkipInjectionModal = ({
  isModalOpen,
  setIsModalOpen,
  encodedProjectKey,
}: ModalProps) => {
  return (
    <Modal
      centered
      padding="2rem"
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
    >
      <Flex
        p="1.5rem"
        pt={0}
        direction="column"
        justify="center"
        align="center"
        rowGap="2rem"
      >
        <Text ta="center" fw={500} fz="1.25rem">
          Skip the injection and verification step for now and continue to the
          dashboard.
          <br />
          <br />
          You'll have to inject the javascript on your site to preview your
          translations and enable all the functionality of the dashboard.
        </Text>
        <Link to={`/${encodedProjectKey}/${dashboardRC.PAGE_DASHBOARD}`}>
          <Button
            fz="1.1rem"
            p="0 1.125rem"
            onClick={() => fathomEventTracker('Preview Site Skip - 6')}
          >
            Confirm
          </Button>
        </Link>
      </Flex>
    </Modal>
  );
};

export default SkipInjectionModal;
