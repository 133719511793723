import { Box } from '@mantine/core';
import { RouterTabs } from 'ui';
import { routes, settings } from './routes';

export const SettingsPage = () => {
  return (
    <Box className="glwebBody">
      <RouterTabs tabs={routes} settings={settings} />
    </Box>
  );
};
