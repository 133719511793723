export const dashboardRC = {
  /* Page Routes */
  PAGE_NOT_FOUND: '*',
  PAGE_ROOT: '/',
  PAGE_DASHBOARD: 'dashboard',
  PAGE_SIGNUP: '/signup',
  PAGE_LOGIN: '/login',
  PAGE_LOGOUT: '/logout',
  PAGE_CREATE_ACCOUNT: 'create-account',
  PAGE_CREATE_PROJECT: 'create-project',
  PAGE_SILENT_RENEW: '/silent_renew',
  PAGE_METRICS: 'metrics',
  PAGE_NOTIFS: '/notifications',
  PAGE_TRANSLATIONS: 'translations',
  PAGE_WEB_TRAFFIC: '/metrics/web-traffic',
  PAGE_ACCOUNT_INFO: 'account',
  PAGE_QUOTES: 'quotes',
  PAGE_HELP: '/help',
  PAGE_SETTINGS: 'settings',
  PAGE_PROJECT_SETUP: '/project-setup',
  PAGE_ORDER_CONFIRMATION: 'order-confirmation',
  PAGE_DOWNGRADE_CONFIRMATION: 'downgrade-confirmation',
  PAGE_CANCELLATION_CONFIRMATION: 'cancellation-confirmation',
  PAGE_LOGIN_REDIRECT: '/post_login',
  PAGE_LOGOUT_REDIRECT: '/post_logout',
  PAGE_STORE_DEBUG: 'groovy',
  PAGE_SUPPORT: '/support',
  PAGE_USER_MANAGEMENT: 'user-management',
  PAGE_DESKTOP_APP: 'desktop-app',
  /* Page Titles */
  PAGE_TITLE_NOT_FOUND: 'Page Not Found',
  PAGE_TITLE_ROOT: 'Dashboard',
  PAGE_TITLE_DASHBOARD: 'Overview',
  PAGE_TITLE_SIGNUP: 'Project Setup',
  PAGE_TITLE_LOGIN: 'Login',
  PAGE_TITLE_LOGOUT: 'Logout',
  PAGE_TITLE_CREATE_ACCOUNT: 'Create Account',
  PAGE_TITLE_CREATE_PROJECT: 'Create Project',
  PAGE_TITLE_SILENT_RENEW: '',
  PAGE_TITLE_METRICS: 'Metrics',
  PAGE_TITLE_NOTIFS: 'Notifications',
  PAGE_TITLE_TRANSLATIONS: 'Translations',
  PAGE_TITLE_WEB_TRAFFIC: 'Metrics | Web Traffic',
  PAGE_TITLE_ACCOUNT_INFO: 'Account',
  PAGE_TITLE_QUOTES: 'Quotes',
  PAGE_TITLE_HELP: 'Help',
  PAGE_TITLE_SETTINGS: 'Project Settings',
  PAGE_TITLE_PROJECT_SETUP: 'Project Setup',
  PAGE_TITLE_ORDER_CONFIRMATION: 'Order Confirmation',
  PAGE_TITLE_CANCELLATION_CONFIRMATION: 'Cancellation Confirmation',
  PAGE_TITLE_LOGIN_REDIRECT: 'Login Redirect',
  PAGE_TILE_LOGOUT_REDIRECT: 'Logout Redirect',
  PAGE_TITLE_DEBUG: 'Debug',
  PAGE_TITLE_SUPPORT: 'Contact Support',
  PAGE_TITLE_USER_MANAGEMENT: 'User Management',
  PAGE_TITLE_DESKTOP_APP: 'Desktop App',

  /* External Links */
  DR_STORE: 'https://store.digitalriver.com/store/defaults/en_US',
} as const;

export const contextRC = {
  TRANSLATIONS: dashboardRC.PAGE_TRANSLATIONS,
  NO_TRANSLATE_RULES: 'no-translate-rules',
};

/* Tab Routes */
export const PAGE_ACCOUNT_TABS = ['profile', 'usage', 'billing'];
export type RoutesConstants = typeof dashboardRC;

export default dashboardRC;
