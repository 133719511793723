import React from 'react';
import { Divider, Text, Modal, Flex, Anchor, Button } from '@mantine/core';
import { Method, deploymentMethods } from './utils';
import { useDeploymentContext } from './DeploymentContextProvider';

const ConfirmMethodModal = () => {
  const {
    setShowChangeMethodModal,
    showConfirmMethodModal: opened,
    setShowConfirmMethodModal: setOpened,
    selectedMethod,
    setSelectedMethod,
  } = useDeploymentContext();

  const { description = '' } =
    deploymentMethods[selectedMethod as Method] || {};

  return (
    <Modal
      centered
      size={680}
      radius="lg"
      padding="2rem"
      opened={opened}
      withCloseButton
      closeOnClickOutside={false}
      onClose={() => {
        setOpened(false);
        setSelectedMethod('');
      }}
    >
      <Flex pb="2rem" px="2rem" direction="column" rowGap="1.5rem">
        <Text fz="1.65rem" tt="capitalize" fw={700}>
          {selectedMethod}
        </Text>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        {typeof description === 'string' ? (
          <Text fz="1.25rem">{description}</Text>
        ) : (
          description
        )}
        <Text fz="1.25rem">
          <Anchor
            fz="1.25rem"
            fw={700}
            c="text.8"
            target="_blank"
            href="https://support.globallinkgo.com/article/130-what-are-the-different-deployment-methods"
          >
            Click here
          </Anchor>{' '}
          to learn more.
        </Text>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Flex justify="end" gap={20}>
          <Button
            fz="1rem"
            onClick={async () => {
              setOpened(false);
              setShowChangeMethodModal(true);
            }}
            p="0 1.125rem"
            variant="outline"
            w="7rem"
          >
            Back
          </Button>
          <Button
            fz="1rem"
            mb={{ base: '0.5rem', sm: 0 }}
            onClick={() => {
              setOpened(false);
            }}
            p="0 1.125rem"
            disabled={!selectedMethod}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ConfirmMethodModal;
