import React, { useMemo } from 'react';
import { Card, Container, Grid, Group, ScrollArea } from '@mantine/core';
import { Subscription } from 'store';
import { BasicHorizontalDivider, StatusCircleIcon } from '../../Generic';
import { CardInterface } from '../../../../types';
import classes from './MyLanguages.module.css';

type Props = CardInterface & {
  targets: TranslationKey[];
  subscriptions: Subscription[];
};

export const MyLanguages = ({
  children,
  targets,
  header,
  subscriptions,
}: Props) => {
  const { sku = '' } = subscriptions[0] || {};

  const isPaidPlan = useMemo(() => {
    return subscriptions.length > 0 && sku !== 'GLGO-FREE';
  }, [sku, subscriptions]);

  if (targets.length > 0) {
    const filteredTargets = targets.filter(
      ({ source_lang_code = '', target_lang_code = '', is_active = false }) => {
        return (
          is_active &&
          target_lang_code !== 'YY' &&
          target_lang_code !== source_lang_code
        );
      }
    );

    return (
      <Container className="glwebContainer">
        <Group>{header}</Group>
        <Card
          className={classes.myLanguagesContainer}
          pr={0}
          pb={0}
          pt={15}
          pl={30}
        >
          <ScrollArea
            h={!isPaidPlan ? 405 : 376}
            offsetScrollbars
            scrollbarSize={18}
            display="block"
            type="always"
            data-testid="pw-my-languages-container"
          >
            {filteredTargets?.map(
              ({
                target_lang_name = '',
                target_lang_code = '',
                is_live = false,
              }) => (
                <div key={target_lang_code}>
                  <Grid py={10} align="center">
                    <Grid.Col ml={5} span={{ base: 4, lg: 2 }}>
                      <StatusCircleIcon
                        status={is_live ? 'live' : 'private'}
                        float="right"
                      />
                    </Grid.Col>
                    <Grid.Col
                      fw={600}
                      fz={16}
                      span={{ base: 6, lg: 8 }}
                      className="pw-my-languages-target-lang-name"
                    >
                      <Container
                        className={classes.targetLangNameContainer}
                        p={0}
                      >
                        {target_lang_name}
                      </Container>
                    </Grid.Col>
                  </Grid>
                  <BasicHorizontalDivider mobileMargin="0" />
                </div>
              )
            )}
          </ScrollArea>
        </Card>
        {children !== undefined && children}
      </Container>
    );
  }

  return null;
};

export default MyLanguages;
