import { useEffect } from 'react';
import { notifications } from '@mantine/notifications';

import {
  Xapis,
  xapisEnv,
  setUserKey,
  useUserProvider,
  saveDesktopUser,
} from 'store';
import { useNavigate } from 'react-router-dom';
import { Flex, Loader } from '@mantine/core';
import { dashboardRC, getDefaultProjectKey, encodeProjectKey } from 'helpers';
import { useGLGOAuth } from './useGLGOAuth';

const PostLogin = () => {
  const navigate = useNavigate();
  const { setXapisUser } = useUserProvider();

  const auth = useGLGOAuth();
  const { isAuthenticated = false, isLoading = false, error, user } = auth;

  const redirectUrl = (auth?.user?.state as { path: string })?.path || '/';

  // TODO: Better error handling
  // 1. IF XAPIS user not found
  // 2. IF user has no projects

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      xapisEnv.setToken(user?.access_token || 'AccessTokenUndefinded');
      // Fetch user from Xapis
      if (user?.profile?.email) {
        Xapis.User.get(user.profile.email)
          .then(({ status, data }) => {
            if (status === 200 && data) {
              setXapisUser(data as User); // Update UserProvider
              setUserKey((data as User).user_key); // Save user key for xapis requests
              saveDesktopUser(data as User);
              const projects = (data as User)?.project_keys || [];
              if (projects.length > 0) {
                const defaultProjectKey = getDefaultProjectKey(projects);
                const path =
                  redirectUrl === dashboardRC.PAGE_ROOT
                    ? `/${encodeProjectKey(defaultProjectKey)}/${dashboardRC.PAGE_DASHBOARD}`
                    : redirectUrl;

                navigate(path, { replace: true });
              } else {
                navigate(`/${dashboardRC.PAGE_CREATE_PROJECT}`, {
                  replace: true,
                });
              }
            } else {
              notifications.show({
                message: 'Error fetching user data',
                color: 'red',
              });
              navigate(dashboardRC.PAGE_CREATE_ACCOUNT, { replace: true });
            }
          })
          .catch((error) => {
            console.error(error);
            notifications.show({
              message: 'Error fetching user data',
              color: 'red',
            });
          });
      }
    }
  }, [isAuthenticated, isLoading]);

  if (error) {
    const { message } = error;
    console.error('auth.error: ', error);
    return <div>Post Login: Oops... {message}</div>;
  }
  return (
    <Flex
      w="100%"
      h="100vh"
      align="center"
      justify="center"
      gap={10}
      color='"#0071DB"'
    >
      <Loader color="#0071DB" size="xl" />
    </Flex>
  );
};

export default PostLogin;
