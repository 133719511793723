import { useState } from 'react';
import { Button, Flex, Modal, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { FaTrash } from 'react-icons/fa';
import { updateNoTxRules } from './noTxRuleActions';
import { useGLGOParams } from 'hooks';
import classes from './DeleteNoTxRule.module.css';

type Props = {
  id: number;
  yyTarget: TranslationKey | undefined;
};

export const DeleteNoTxRule = ({ id, yyTarget }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { projectKey } = useGLGOParams();

  function handleDeleteRule(id: number) {
    setShowModal(false);
    if (!id || !yyTarget || !projectKey) return;
    updateNoTxRules({ id, projectKey, target: yyTarget })
      .then(() => {
        notifications.show({
          message: 'Successfully delete the no translate rule.',
        });
      })
      .catch(() => {
        notifications.show({
          message: 'Something went wrong with deleting the rule.',
        });
      });
  }

  return (
    <>
      <Flex className={classes.icon} onClick={() => setShowModal(true)}>
        <FaTrash />
      </Flex>
      <Modal
        centered
        classNames={classes}
        opened={showModal}
        onClose={() => setShowModal(false)}
        padding="2rem"
        styles={{ title: { paddingTop: 20 } }}
      >
        <Modal.Body p={0}>
          <Title order={3} py={30}>
            Are you sure you want to delete this rule?
          </Title>
          <Flex mt={40} justify={'space-between'}>
            <Button onClick={() => setShowModal(false)} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDeleteRule(id);
              }}
            >
              Confirm
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};
