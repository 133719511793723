// import { useRef } from 'react';
import { ThemeIcon, UnstyledButton } from '@mantine/core';
import { Link } from 'react-router-dom';
import { NavLinkItem } from './SideNav';
import { useDynamicLink } from './useDynamicLink';
import classes from './MobileLink.module.css';

export const MobilLink = ({ link }: { link: NavLinkItem }) => {
  const { icon, label } = link;
  const { isActive, tab } = useDynamicLink({ link });

  return (
    <Link
      className={`${classes.mobileLinkAnchor} ${isActive ? classes.activeLink : ''}`}
      to={tab}
      style={{
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'none',
        width: '100%',
        height: '100%',
        padding: '1rem',
        margin: 0,
      }}
    >
      <UnstyledButton my={10}>
        <ThemeIcon aria-label={label} className={classes.mobileLinkIcon}>
          {icon}
        </ThemeIcon>
      </UnstyledButton>
    </Link>
  );
};
