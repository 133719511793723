import React from 'react';
import { Button } from '@mantine/core';
import { dashboardRC } from 'helpers';
import { Link } from 'react-router-dom';
import { useGLGOParams } from 'hooks';
import { useRecommendedThresholdContext } from 'store';

type Props = {
  text?: string;
};

const SubscribeButton = ({ text = 'Subscribe' }: Props) => {
  const projectId = useGLGOParams()?.projectId;
  const threshold = useRecommendedThresholdContext().threshold;
  return (
    <Link
      to={`/${projectId}/${dashboardRC.PAGE_ACCOUNT_INFO}/billing-information/manage-subscription${threshold > 0 ? `?threshold=${threshold}` : ''}`}
    >
      <Button
        mih={30}
        px={20}
        style={(theme) => ({
          borderRadius: 30,
          backgroundColor: theme.colors.button[0],
        })}
      >
        {text}
      </Button>
    </Link>
  );
};

export default SubscribeButton;
