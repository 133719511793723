import { Button, Divider, Flex, Modal, Text } from '@mantine/core';
import { UpgradeParams, upgrade } from '../planActions';
import { ActionModalType } from '../../select-plans/SelectPlan';

type Props = {
  modalType: 'downgrade' | 'upgrade' | null;
  setActionModalType: React.Dispatch<React.SetStateAction<ActionModalType>>;
  params: UpgradeParams;
};

const UpgradeConfirmModal = ({
  modalType,
  setActionModalType,
  params,
}: Props) => (
  <Modal
    centered
    opened={modalType === 'upgrade'}
    onClose={() => setActionModalType(null)}
    padding="2rem"
    radius={20}
    size="lg"
    title="Enjoy your new plan right away!"
  >
    <Divider w="95%" m="1.5rem auto" />
    <Flex direction="column" gap={20} justify="center">
      <Text ta="center">
        Your upgrade is effective immediately. When you checkout, you will only
        be charged for the difference between your current plan and the new
        plan. Your renewal date stays the same and your saved payment method
        will be charged at the new rate at your next renewal.
      </Text>
      <Button
        mx="auto"
        onClick={async () => {
          setActionModalType(null);
          await upgrade({ ...params });
        }}
      >
        Upgrade
      </Button>
    </Flex>
  </Modal>
);

export default UpgradeConfirmModal;
