import { useCallback, useState, useEffect, useMemo } from 'react';
import { Editor, DiffEditor } from '@monaco-editor/react';
import {
  Button,
  Modal,
  Divider,
  Flex,
  Select,
  Loader,
  Tooltip,
} from '@mantine/core';

import { hexToAscii, renderLocaleDate } from 'helpers';
import { PiClockCounterClockwiseFill } from 'react-icons/pi';
import { BsFileEarmarkDiff } from 'react-icons/bs';
import { MdArrowDropDown } from 'react-icons/md';
import classes from './CSSHistoryModal.module.css';

const getRevisionCss = (revisions: RevisionItem[], hash: string) => {
  const revision = revisions.find((rev) => rev.revision_hash === hash);
  return revision ? hexToAscii(revision.config_encoded) : '';
};

export const CSSHistoryModal = ({
  revisions,
  unsavedCss,
}: {
  revisions: RevisionItem[];
  unsavedCss: string | null;
}) => {
  const hasCssHistory = revisions.length > 0;
  const hasUnsavedCss = unsavedCss !== null;
  const canCompare = revisions.length > 1 || (hasCssHistory && hasUnsavedCss);

  const [mode, setMode] = useState<'history' | 'compare' | 'closed'>('closed');
  const showModal = mode !== 'closed';
  const showCompare = mode === 'compare';

  const options = useMemo(
    () =>
      revisions
        .sort((a, b) => b.lastmod_utc.localeCompare(a.lastmod_utc))
        .map((revision) => ({
          value: revision.revision_hash,
          label: renderLocaleDate(revision.lastmod_utc),
        })),
    [revisions]
  );

  const defaultSelection = options?.[0]?.value || '';
  const compareOptions = hasUnsavedCss
    ? [{ value: 'unsaved', label: 'Unsaved Changes' }, ...options]
    : options;

  const defaultCompareSelection = hasUnsavedCss
    ? 'unsaved'
    : compareOptions?.[1]?.value || '';

  const [selectedRevision, setSelectedRevision] =
    useState<string>(defaultSelection);
  const [compareSelection, setCompareSelection] = useState<string>(
    defaultCompareSelection
  );

  const closeModal = useCallback(() => {
    setMode('closed');
  }, []);

  useEffect(() => {
    if (hasCssHistory) setSelectedRevision(defaultSelection);
    if (canCompare) setCompareSelection(defaultCompareSelection);
  }, [options, hasCssHistory, canCompare, unsavedCss]);

  const handleSelectRevision = (value: string | null) => {
    if (!value) return;
    setSelectedRevision(value);
  };

  const ShowMode = ({ mode }: { mode: 'compare' | 'history' }) => (
    <Tooltip
      label={`CSS ${mode === 'compare' ? 'Compare' : 'History'}`}
      disabled={!hasCssHistory}
    >
      <Button
        className={classes.icon}
        disabled={mode === 'compare' ? !canCompare : !hasCssHistory}
        variant="light"
        onClick={() => setMode(mode)}
      >
        {mode === 'history' ? (
          <PiClockCounterClockwiseFill size={24} />
        ) : (
          <BsFileEarmarkDiff size={24} />
        )}
      </Button>
    </Tooltip>
  );

  return (
    <>
      <Flex className="buttons" gap={5}>
        <ShowMode mode="history" />
        <ShowMode mode="compare" />
      </Flex>
      <Modal
        opened={showModal}
        onClose={closeModal}
        size="80vw"
        title={mode === 'history' ? 'CSS History' : 'CSS Compare'}
      >
        <Divider mt={5} />
        <Flex direction="column" mt={10}>
          <Flex className={classes.header}>
            <Select
              w="240px"
              p={0}
              data={options.map((o) => ({
                ...o,
                disabled: showCompare && o.value === compareSelection,
              }))}
              value={selectedRevision}
              rightSection={<MdArrowDropDown />}
              placeholder="Choose a Date"
              onChange={(value) => handleSelectRevision(value || '')}
            />
            {showCompare ? (
              <Flex align="center">
                <Select
                  w="240px"
                  data={compareOptions.map((o) => ({
                    ...o,
                    disabled: o.value === selectedRevision,
                  }))}
                  value={compareSelection}
                  rightSection={<MdArrowDropDown />}
                  placeholder="Choose a Date"
                  onChange={(value) =>
                    value && setCompareSelection(value || '')
                  }
                />
                <ShowMode mode="history" />
              </Flex>
            ) : (
              <ShowMode mode="compare" />
            )}
          </Flex>
          <Flex mt="8px" h="70vh">
            {showCompare ? (
              <DiffEditor
                options={{ readOnly: true }}
                original={getRevisionCss(revisions, selectedRevision)}
                modified={
                  compareSelection === 'unsaved'
                    ? (unsavedCss as string)
                    : getRevisionCss(revisions, compareSelection)
                }
                language="css"
                loading={<Loader />}
              />
            ) : (
              <Editor
                options={{ readOnly: true }}
                value={getRevisionCss(revisions, selectedRevision)}
                height="75vh"
                defaultLanguage="css"
                loading={<Loader />}
              />
            )}
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default CSSHistoryModal;
