import { useState } from 'react';
import {
  AuthStore,
  GlossaryStore,
  LanguageCodesStore,
  PaySourceStore,
  ProjectStore,
  ProjectTranslationStore,
  SpiderTreeStore,
  StateStore,
  ThemeStore,
  useAuthStore,
  useGlossaryStore,
  useLanguageCodesStore,
  usePaySourceStore,
  useProjectsStore,
  useProjectTranslationStore,
  UserStoreType,
  useSpiderTreeStore,
  useStateStore,
  useThemeStore,
  useUserStore,
  xapisEnv,
} from 'store';
import { Select } from '@mantine/core';

type ActiveStoreType = {
  title: string;
  content: unknown;
};

const StoreDebug = () => {
  const StateStore = useStateStore((state: StateStore) => state);
  const xapisHost = xapisEnv.getHost;
  const PaymentSourceStore = usePaySourceStore(
    (state: PaySourceStore) => state
  );
  const ProjectsStore = useProjectsStore((state: ProjectStore) => state);
  const ThemeStore = useThemeStore((state: ThemeStore) => state);
  const UserStore = useUserStore((state: UserStoreType) => state);
  const AuthStore = useAuthStore((state: AuthStore) => state);
  const ProjectTranslationStore = useProjectTranslationStore(
    (state: ProjectTranslationStore) => state
  );
  const SpiderTreeStore = useSpiderTreeStore((state: SpiderTreeStore) => state);
  const LanguageCodesStore = useLanguageCodesStore(
    (state: LanguageCodesStore) => state
  );
  const GlossaryStore = useGlossaryStore((state: GlossaryStore) => state);

  const [activeStore, setActiveStore] = useState<ActiveStoreType>();

  const stores = [
    {
      title: 'SpiderTreeStore',
      content: SpiderTreeStore,
    },
    {
      title: 'StateStore',
      content: StateStore,
    },
    {
      title: 'Xapis Host',
      content: xapisHost,
    },
    {
      title: 'PaymentSourceStore',
      content: PaymentSourceStore,
    },
    {
      title: 'ProjectsStore',
      content: ProjectsStore,
    },
    {
      title: 'ThemeStore',
      content: ThemeStore,
    },
    {
      title: 'ProjectTranslationStore',
      content: ProjectTranslationStore,
    },
    {
      title: 'UserStore',
      content: UserStore,
    },
    {
      title: 'AuthStore',
      content: AuthStore,
    },
    {
      title: 'LanguageCodesStore',
      content: LanguageCodesStore,
    },
    {
      title: 'GlossaryStore',
      content: GlossaryStore,
    },
  ];

  const storeViews = stores.map(({ title, content }) => (
    <div key={title} style={{ opacity: activeStore ? 0.3 : 1 }}>
      <p style={{ fontWeight: 'bold' }}>{title}</p>
      <pre>{JSON.stringify(content, null, 2)}</pre>
    </div>
  ));

  const selectedStore = activeStore ? (
    <div key={`${activeStore.title}`} style={{ fontSize: '1.2em' }}>
      <p style={{ fontWeight: 'bold', marginBottom: 0 }}>{activeStore.title}</p>
      <pre style={{ marginTop: 0 }}>
        {JSON.stringify(activeStore.content, null, 2)}
      </pre>
    </div>
  ) : (
    <p style={{ marginBottom: 30 }}>No store selected.</p>
  );

  return (
    <main style={{ marginLeft: 240, overflow: 'clip' }}>
      <h3 style={{ marginBottom: 30 }}>
        The purpose of this page is to assist in debugging store state.
      </h3>
      <Select
        onChange={(label) => {
          const clickedStore = stores.find((store) => store.title === label);
          setActiveStore(clickedStore);
        }}
        searchable
        clearable
        style={{ maxWidth: 400, marginTop: '40px 0' }}
        label="Store Selector"
        placeholder="Pick a store to see its current values"
        defaultValue="-- select a store --"
        data={stores.map(({ title }) => ({ value: title, label: title }))}
      />
      <div>
        {selectedStore}
        {storeViews}
      </div>
    </main>
  );
};
export default StoreDebug;
