import { NavLink } from 'react-router-dom';
import { Flex, ThemeIcon, Text, UnstyledButton } from '@mantine/core';
import { useGLGOParams } from 'hooks';
import { NavLinkItem } from '../gonavigation/SideNav';
import { MdContactSupport } from 'react-icons/md';
import { IoMdNotifications } from 'react-icons/io';
import { FaChevronRight } from 'react-icons/fa6';
import classes from './MobileMenu.module.css';

type Props = {
  navLinks: NavLinkItem[];
  setShowNotificationsMenu: (value: boolean) => void;
  setShowContactUsModal: (value: boolean) => void;
  closeMenu: () => void;
};
export const MobileMenu = ({
  navLinks,
  closeMenu,
  setShowNotificationsMenu,
  setShowContactUsModal,
}: Props) => {
  const { projectId } = useGLGOParams();

  return (
    <>
      <UnstyledButton
        onClick={() => setShowNotificationsMenu(true)}
        className={classes.linkAnchor}
        w="100%"
        h="100%"
      >
        <Flex justify="space-between" mr={10}>
          <Flex align="center">
            <ThemeIcon className={classes.linkIcon} mr={20}>
              <IoMdNotifications size={24} />
            </ThemeIcon>
            <Text>Notifications</Text>
          </Flex>
          <FaChevronRight color="white" size={20} />
        </Flex>
      </UnstyledButton>
      <UnstyledButton
        onClick={() => {
          setShowContactUsModal(true);
          closeMenu();
        }}
        className={classes.linkAnchor}
        w="100%"
        h="100%"
      >
        <Flex justify={'initial'} mr={10}>
          <ThemeIcon className={classes.linkIcon} mr={20}>
            <MdContactSupport size={24} />
          </ThemeIcon>
          <Text>Contact Support</Text>
        </Flex>
      </UnstyledButton>
      {navLinks.map((link) => (
        <NavLink
          key={link.path}
          className={classes.linkAnchor}
          to={`${projectId}/${link.path}`}
          style={({ isActive }) => ({
            fontSize: isActive ? 'lg' : 'sm',
            fontWeight: isActive ? 'bold' : 'normal',
          })}
          onClick={closeMenu}
        >
          <Flex>
            <ThemeIcon className={classes.linkIcon} mr={20}>
              {link.icon}
            </ThemeIcon>
            <Text>{link.label}</Text>
          </Flex>
        </NavLink>
      ))}
    </>
  );
};
