import React, { useState } from 'react';
import { useRevalidator } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Loader,
  Modal,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Xapis } from 'store';
import { isSuccessStatus } from 'helpers';
import {
  positionOptions,
  themeOptions,
  LanguageSelectorPosition,
  LanguageSelectorTheme,
  getPresetCssHex,
  getPresetCssPreferences,
  isPresetCss,
} from '../../gosetup/LanguageSelectorAppearances';
import classes from './SelectorDefaults.module.css';
import { BasicHorizontalDivider } from '../../../Generic';

const getNoteText = (isCurrentAPreset: boolean, isPresetSelected: boolean) => {
  if (!isCurrentAPreset && isPresetSelected) {
    return '* Saving a preset css will override your current custome CSS.';
  }
  if (!isPresetSelected) {
    return '* Select THEME and POSITION to save.';
  }
  return '';
};

const PreferenceButton = ({
  onClick,
  disabled,
  isActive,
  src,
  alt,
}: {
  onClick: () => void;
  disabled: boolean;
  isActive: boolean;
  src: string;
  alt: string;
}) => {
  const { colors } = useMantineTheme();

  return (
    <UnstyledButton
      disabled={disabled}
      className={classes.selectorOption}
      style={{
        outline: isActive ? `2px solid ${colors.border[8]}` : 'none',
      }}
      onClick={onClick}
    >
      <Image width={120} height="auto" src={src} alt={alt} />
    </UnstyledButton>
  );
};

const SelectorDefaults = ({
  yyTarget,
}: {
  yyTarget: TranslationKey | undefined;
}) => {
  const revalidator = useRevalidator();

  const { custom_css: hexedCustomCss = '' } = yyTarget || {};
  const currentPreset = getPresetCssPreferences(hexedCustomCss);
  const isCurrentAPreset = isPresetCss(hexedCustomCss);
  const [selectedPreset, setSelectedPreset] = useState(currentPreset);
  const [loading, setLoading] = useState(false);
  const [showOverrideCSSModal, setShowOverrideCSSModal] = useState(false);

  const isPresetSelected = Boolean(
    selectedPreset.position && selectedPreset.theme
  );
  const isUnsaved =
    selectedPreset.position !== currentPreset.position ||
    selectedPreset.theme !== currentPreset.theme;
  const disableSave = !(isUnsaved && isPresetSelected);
  const currentPresetExists = Boolean(
    currentPreset.theme && currentPreset.position
  );

  const updateAllAppearances = () => {
    setLoading(true);
    const { theme, position } = selectedPreset;
    const updatedCustomCSS = getPresetCssHex(position!, theme!);

    if (!yyTarget || !updatedCustomCSS) {
      notifications.show({
        message: 'Unable to update selector widget at this time.',
      });
      return;
    }

    const {
      translation_key = '',
      project_key = '',
      custom_css_hash = '',
      staging_custom_css_hash = '',
    } = yyTarget;

    Xapis.ProjectTranslation.put(project_key, translation_key, {
      custom_css: updatedCustomCSS,
      custom_css_hash,
      staging_custom_css: updatedCustomCSS,
      staging_custom_css_hash,
    })
      .then(({ status }) => {
        if (isSuccessStatus(status)) {
          notifications.show({
            message: 'Successfully updated your selector widget!',
          });
        } else {
          notifications.show({
            message: 'Oops, there was a problem with saving your edits.',
          });
        }
      })
      .catch(() => {
        notifications.show({
          message: 'Unable to update selector widget at this time.',
        });
      })
      .finally(() => {
        revalidator.revalidate();
        setShowOverrideCSSModal(false);
        setLoading(false);
      });
  };

  const handleThemeChange = (updatedTheme: LanguageSelectorTheme) => {
    setSelectedPreset((prev) => ({ ...prev, theme: updatedTheme }));
  };
  const handlePositionChange = (updatedPosition: LanguageSelectorPosition) => {
    setSelectedPreset((prev) => ({ ...prev, position: updatedPosition }));
  };

  return (
    <>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        gap={{ base: 15, lg: 30 }}
        mih={{ base: 300, lg: 170 }}
      >
        <Flex direction="column">
          <Text fw={500} fz="1rem" mt={15} tt="uppercase">
            Theme
          </Text>
          <Flex direction="row" columnGap={50} mt={30}>
            {themeOptions.map(({ theme, src, alt = '' }) => {
              const isThemeActive = theme === selectedPreset.theme;
              return (
                <PreferenceButton
                  key={theme}
                  disabled={loading}
                  isActive={isThemeActive}
                  onClick={() => handleThemeChange(theme)}
                  src={src}
                  alt={alt}
                />
              );
            })}
          </Flex>
        </Flex>
        <Divider orientation="vertical" mb={0} />
        <Flex direction="column">
          <Text fw={500} fz="1rem" mt={15} tt="uppercase">
            position
          </Text>
          <Flex mt={30} w="100%" columnGap={{ base: 50, lg: 30 }} wrap="wrap">
            {positionOptions.map(({ position, src, alt = '' }) => {
              const isPositionActive = position === selectedPreset.position;
              return (
                <PreferenceButton
                  key={position}
                  disabled={loading}
                  isActive={isPositionActive}
                  onClick={() => handlePositionChange(position)}
                  src={src}
                  alt={alt}
                />
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction={'column'}>
        <Flex mt={20} justify="end" gap={20}>
          <Flex gap={20}>
            <Button
              disabled={disableSave}
              variant="outline"
              className={classes.cancelButton}
              onClick={() => {
                setSelectedPreset(currentPreset);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={disableSave}
              variant="filled"
              className={classes.saveButton}
              onClick={() =>
                currentPresetExists
                  ? updateAllAppearances()
                  : setShowOverrideCSSModal(true)
              }
            >
              {loading ? <Loader size="sm" /> : 'Save'}
            </Button>
          </Flex>
        </Flex>
        <Text className={classes.note}>
          {getNoteText(isCurrentAPreset, isPresetSelected)}
        </Text>
      </Flex>
      <Modal
        opened={showOverrideCSSModal}
        onClose={() => setShowOverrideCSSModal(false)}
        title="Updating Language Selector Configuration"
        centered
      >
        <Box w="100%">
          <BasicHorizontalDivider mobileMargin="0 auto" />
          <Text mt={20}>
            Changing the Language Selector configuration to a default setting
            will override any custom CSS entered in the CSS editor. Are you sure
            you would like to continue?
          </Text>
        </Box>
        <Flex justify={'flex-end'} gap={10}>
          <Button
            variant="outline"
            onClick={() => setShowOverrideCSSModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateAllAppearances();
            }}
            className={classes.saveButton}
            disabled={loading}
          >
            {loading ? <Loader size="sm" /> : 'Save'}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default SelectorDefaults;
