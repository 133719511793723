import { useCallback, useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import cheet from 'cheet.js';

import { AppShell as MantineAppShell, Container } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { MainHeader, SideNav, MobileLinksBar, NavLinkItem } from 'ui';
import {
  useSideNavStore,
  SideNavStore,
  useDebugStore,
  DebugStore,
  xapisEnv,
} from 'store';
import {
  headerLinks,
  navLinks,
  fixedMobileLinks,
  quoteNavLink,
} from './navLinks';

import packageJson from '../package.json';
import logo from 'images/logos/glgo-logo-white.svg';
import { Outlet } from 'react-router-dom';

const PageWrapper = ({ children }: { children: JSX.Element }) => {
  const { isNavExpanded } = useSideNavStore((state: SideNavStore) => state);
  const isMobileSize = useMediaQuery('(max-width: 991px)');
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');

  return (
    <Container
      h="100%"
      maw="100%"
      pb={isMobileSize ? 112 : 0}
      pos="relative"
      pr={0}
      pl={!isMobileSize ? (isNavExpanded && isDesktopSize ? 231 : 89) : 0}
      style={{ transition: 'padding 0.1s' }}
    >
      {children}
      {isMobileSize && <MobileLinksBar links={fixedMobileLinks} />}
    </Container>
  );
};

export const NavigationLayout = () => {
  const [opened, { toggle, close }] = useDisclosure(false);
  const { debug, setDebug } = useDebugStore((state: DebugStore) => state);
  const isDesktopSize = useMediaQuery('(min-width: 1200px)');
  const { googleAnalyticsId } = xapisEnv.getHost;

  const getLinks = useCallback(
    (links: NavLinkItem[]) => {
      return !debug ? links.filter((l) => l.label !== 'Debug') : links;
    },
    [debug]
  );

  (cheet as unknown as (str: string, cb: () => void) => void)(
    'ctrl d b g',
    () => {
      setDebug(!debug);
    }
  );

  useEffect(() => {
    ReactGA4.initialize(googleAnalyticsId);
  }, [googleAnalyticsId]);

  return (
    <>
      <MantineAppShell pb={0}>
        <SideNav
          navLinks={getLinks(navLinks)}
          quoteNavLink={quoteNavLink}
          isDesktopSize={isDesktopSize || false}
        />
        <MainHeader
          isDesktopSize={isDesktopSize || false}
          navLinks={getLinks(headerLinks)}
          opened={opened}
          toggle={toggle}
          close={close}
          version={`Build ${packageJson.version}`}
          logo={logo}
        />
        <PageWrapper>
          <Outlet />
        </PageWrapper>
      </MantineAppShell>
    </>
  );
};
