import { RouteObject } from 'react-router-dom';
import { ErrorBoundary } from 'ui';
import { dashboardRC } from 'helpers';
import { QuoteList } from './AllQuotes';
import { QuotesProject } from './ProjectQuotes';

export const routes: RouteObject[] = [
  {
    path: 'project',
    element: <QuotesProject />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: 'all',
    element: <QuoteList />,
    errorElement: <ErrorBoundary />,
  },
];

export const settings = {
  path: dashboardRC.PAGE_QUOTES,
  defaultTab: routes[0].path,
};
