import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Flex,
  Loader,
  Radio,
  Text,
} from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import classes from './CancelPlan.module.css';
import { handleCancelSubscription } from '../plan-information/planActions';
import { useUserProvider, xapisEnv } from 'store';
import { useGLGOParams } from 'hooks';
import BackButton from '../shared/BackButton';

const CancelPlan = () => {
  const { xapisUser } = useUserProvider();
  const payKey = xapisEnv.getHost.payKey;
  const projectKey = useGLGOParams().projectKey as string;
  const navigate = useNavigate();

  const [reason, setReason] = useState('Prefer not to answer');
  const [loading, setLoading] = useState(false);
  const params = {
    payKey,
    projectKey,
    reason,
    xapisUser,
    navigate,
    cleanupOnError: () => setLoading(false),
  };

  const reasons = [
    'Too expensive',
    'Better product elsewhere',
    'Technical issues',
    'Not enough impact',
    "It's temporary, I'll be back",
    'Prefer not to answer',
  ];

  return (
    <Container pb="2rem" px={0} maw={1000} mx={0} mt={15} w="100%">
      <Flex direction="column" gap={15}>
        <BackButton text="Back to Manage Subscription" />
        <Card
          display="flex"
          p="2rem 1.5rem"
          shadow="sm"
          radius="md"
          style={{ gap: 15 }}
        >
          <Text fz={{ base: '1.5rem', md: '2rem' }} fw={700} c="text.2">
            Are you sure you want to cancel your subscription?
          </Text>
          <Divider color="divider.0" w="100%" my="1.5rem" />
          <Box w="100%">
            <Radio.Group
              label="If you have a moment, please let us know why?"
              name="cancelReason"
              onChange={setReason}
              classNames={{
                label: classes.radioGroupLabel,
              }}
              value={reason}
            >
              {reasons.map((r) => (
                <Radio
                  key={r}
                  value={r}
                  label={r}
                  my={{ base: '1rem', md: '1.5rem' }}
                  size="md"
                  classNames={{
                    label: classes.radioLabel,
                    radio: classes.radioRadio,
                  }}
                />
              ))}
            </Radio.Group>
          </Box>
          <Divider color="divider.0" w="100%" mb="1.5rem" />
          <Text pb="1rem" fz={14}>
            After clicking “Confirm Cancellation,” your account will be
            cancelled. Subscription fees are charged in advance and no credits
            or refunds will be issued for partial or prepaid months after
            cancellations. Your current subscription will remain active until
            the expiration date. If you change your mind, you can restart your
            subscription at any time.
          </Text>
          <Flex
            w="100%"
            align="center"
            justify="flex-end"
            columnGap="2rem"
            rowGap="1rem"
            direction={{ base: 'column-reverse', xs: 'row' }}
          >
            <Button
              bd="1px solid #05358C1A"
              bg="white"
              c="text.8"
              className={classes.nextButton}
              disabled={loading}
              onClick={() => handleCancelSubscription({ ...params })}
              variant="outline"
              w={{ base: '100%', xs: 190 }}
            >
              {loading ? (
                <Loader size="sm" color="text.8" />
              ) : (
                'Confirm Cancellation'
              )}
            </Button>
            <Link to="../..">
              <Button bg="text.8" color="text.8" w={{ base: '100%', xs: 140 }}>
                My Account
              </Button>
            </Link>
          </Flex>
        </Card>
      </Flex>
    </Container>
  );
};

export default CancelPlan;
