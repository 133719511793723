import { useAuth } from 'react-oidc-context';
import { dashboardRC } from 'helpers';
import { Navigate } from 'react-router-dom';

export const PostLogout = () => {
  const auth = useAuth();
  const { isAuthenticated = false, isLoading = false, error } = auth;

  if (isLoading) return null;

  if (isAuthenticated) return <Navigate to={dashboardRC.PAGE_ROOT} replace />;

  if (error) {
    const { message } = error;
    console.error('auth.error: ', error);
    return <div>Post Logout: Oops... {message}</div>;
  }

  return <Navigate to={dashboardRC.PAGE_ROOT} replace />;
};
