import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  TextInput,
  Text,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { NoTranslateXRule } from './NoTxEditor';
import { useForm } from 'react-hook-form';
import { HiPencil } from 'react-icons/hi';
import { updateNoTxRules } from './noTxRuleActions';
import { useGLGOParams } from 'hooks';
import { getNoTxRuleHash, selectorIsValid } from 'helpers';
import { previewNoTxRule, resetPreview } from './contextFunctions';
import classes from './NoTxRuleForm.module.css';

type Props = {
  rules: NoTranslateXRule[];
  id?: number;
  yyTarget: TranslationKey | undefined;
  defaults?: FormValues;
  icon?: React.ReactNode;
  cleanup?: () => void;
};

type FormValues = {
  X: string;
  comment: string;
};
const DefaultIcon = <HiPencil size={20} />;

export const NoTxRuleForm = ({
  id,
  rules,
  yyTarget,
  defaults = { X: '', comment: '' },
  icon,
  cleanup,
}: Props) => {
  const { projectKey } = useGLGOParams();
  const rule = rules?.find((r) => r.id === id);
  const allRulesIds = // All other rules IDs to check for duplicates
    rules?.reduce(
      (ids: number[], r) => (r.id !== id ? [...ids, r.id] : ids),
      []
    ) || [];

  const { selector } = rule || {};
  const [showForm, setShowForm] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isValidSelector, setIsValidSelector] = useState(true);
  const defaultValues = {
    X: selector?.X || defaults.X,
    comment: selector?.comment || defaults.comment,
  };
  const { register, handleSubmit, reset, getValues, formState } =
    useForm<FormValues>({
      defaultValues,
      shouldUseNativeValidation: true,
    });

  const { isDirty, isValid: isFormValid } = formState;
  const disabled =
    !isFormValid || !isValidSelector || !(isDirty || !id) || isDuplicate;
  const isNewRule = !rule;

  useEffect(() => {
    if (showForm && defaultValues.X) {
      previewNoTxRule(defaultValues);
    }
  }, [showForm]);

  const closeForm = () => {
    if (!rule) resetPreview();
    setShowForm(false);
    cleanup && cleanup();
    reset();
  };
  const openForm = () => {
    // Update form values on open to avoid stale data
    reset(defaultValues);
    if (isDuplicate) setIsDuplicate(false);
    setShowForm(true);
  };

  const validateSelector = () => {
    const ruleHash = getNoTxRuleHash(getValues());
    if (allRulesIds.includes(ruleHash)) {
      setIsDuplicate(true);
    } else if (isDuplicate) {
      setIsDuplicate(false);
    }
    const selector = getValues().X;
    setIsValidSelector(selectorIsValid(selector));
    previewNoTxRule(getValues());
  };

  function getErrorMessage() {
    const { X } = getValues();
    if (!X) return null;
    if (!isValidSelector) return 'Selector is not valid';
    if (isDuplicate) return 'This rule already exists';
    return null;
  }

  const handleSaveRule = (data: FormValues) => {
    if (!yyTarget || !projectKey) return;
    updateNoTxRules({ rule: data, id: rule?.id, projectKey, target: yyTarget })
      .then(() => {
        notifications.show({
          message: `Successfully saved no translate rule.`,
        });
      })
      .catch(() => {
        notifications.show({
          message: 'Something went wrong with saving the rule.',
        });
      })
      .finally(() => closeForm());
  };

  return (
    <>
      <Flex className={classes.icon} onClick={openForm}>
        {icon || DefaultIcon}
      </Flex>
      {showForm && (
        <Modal
          classNames={classes}
          centered
          opened={showForm}
          onClose={closeForm}
          padding="2rem"
          style={{ flex: 'unset' }}
          onClick={(ev) => ev.stopPropagation()} // Prevent re-rendering the table
        >
          <Modal.Body p={0}>
            <Title order={3} ta="center" pb="1rem">
              {rule ? 'Edit Rule' : 'Add New Rule'}
            </Title>
            <form onSubmit={handleSubmit(handleSaveRule)}>
              <Flex direction="column">
                <Flex direction="column" gap={5}>
                  <Box>
                    <TextInput
                      placeholder="e.g. i.material-icons"
                      label="SELECTOR"
                      {...register('X', {
                        required: 'Please enter a value for this field.',
                        onChange: validateSelector,
                      })}
                    />
                    <Text size="xs" c="red.7" mih={20} mt={4}>
                      {getErrorMessage()}
                    </Text>
                  </Box>
                  <TextInput
                    placeholder="Description"
                    label="DESCRIPTION (optional)"
                    {...register('comment')}
                  />
                </Flex>
                <Flex className="buttons" mt={40} justify={'space-between'}>
                  <Button onClick={closeForm} variant="outline">
                    Cancel
                  </Button>
                  <Button type="submit" disabled={disabled}>
                    {isNewRule ? 'Add' : 'Save'}
                  </Button>
                </Flex>
              </Flex>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
