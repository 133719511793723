import { AuthService } from './authService';
import {
  Xapis,
  saveDesktopUser,
  xapisEnv,
  setUserKey,
  glgoUser
} from "store";
import { getSavedProjectKey, LAST_PROJECT_KEY_COOKIE } from 'helpers';
export const auth = new AuthService();

async function fetchXapisUser(email?: string) {
  if (!email) return Promise.resolve(null);
  const data = await Xapis.User.get(email);
  return data.status === 200 ? data.data : null;
}

export async function initUser(): Promise<User | null> {
  const authUser = await auth.getUser();
  const lastProjectKey = await getSavedProjectKey();
  if (lastProjectKey) {
    sessionStorage.setItem(LAST_PROJECT_KEY_COOKIE, lastProjectKey);
  }
  xapisEnv.setToken(authUser?.access_token || 'InvalidToken');
  const xapisUser = await fetchXapisUser(authUser?.profile.email);
  glgoUser.set(xapisUser ?? { user_key: '' });
  setUserKey(xapisUser?.user_key || '');
  if (xapisUser) saveDesktopUser(xapisUser);
  return Promise.resolve(xapisUser);
}
